<style  scoped>

.exit-wrapper {
  position: absolute;
  left: 2rem;
  top: 2.3rem;
  width: 5.4rem;
  height: 5.4rem;
  z-index: 11;
}
.icon {
  width: 4rem;

  padding: 1rem;
}

.icon:active {
  filter: brightness(0.4)
}

&::after {
  content: '';
  position: absolute;
  top: 1.2rem;
  left: 4.8rem;
  width: 1px;
  height: 3rem;
  background-color: rgba(255, 255, 255, 0.3);
}
</style>

<template>
  <div class="exit-wrapper" @click="exit">
    <img class="icon" :src="require('@/assets/arrow_left.png')"/>
  </div>
</template>

<script>
import Context from '@/utils/context'

export default {
  props: {
    page: String
  },
  data () {
    return {
      isWebview: Context.isWebview(),
      hasClosePage: Context.hasClosePage()
    }
  },
  methods: {
    exit () {
      if (this.page == 'home') {
        // 'tc_home_back_002': ' '
        this.$log.info('tc_home_back_002')
      }
      if (this.page == 'withdraw detail') {
        this.$router.push('/withdraw')
        return
      }
      if (window.closePage) {
        window.closePage.closePage()
      } else if (this.hasClosePage) {
        Context.closePage()
      } else if (this.page == 'home' && this.isWebview) {
        Context.exitWebview()
      } else if (window.share) {
        // window.share.exit()
      } else {
        this.$router.push('/home')
      }
    }
  }
}
</script>
