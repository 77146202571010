class Timer {
  constructor (opts = { maxCounter: 300 }) {
    this.maxCounter = opts.maxCounter
    this.timer = null
    this.counter = -1
    this.isRunning = false
  }

  run (cb) {
    this.stop()
    this.isRunning = true
    this.counter = this.maxCounter - 1

    if (cb) {
      cb()
    }
    this.timer = setInterval(() => {
      if (this.counter === 1) {
        this.stop()
        if (cb) {
          cb()
        }
        return
      }
      this.counter--
      if (cb) {
        cb()
      }
    }, 1000)
  }

  stop () {
    clearInterval(this.timer)
    this.isRunning = false
    this.counter = 0
  }
}

export default Timer
