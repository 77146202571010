import { createRouter, createWebHashHistory } from 'vue-router'
import EarnCashView from '@/views/EarnCashView.vue'
import MyCashView from '@/views/MyCashView.vue'
import AppMain from '@/components/main/AppMain.vue'
import WebView from '@/components/h5/WebView.vue'
import LandWebView from '@/components/h5/LandWebView.vue'

import store from '@/store'
import MyAffiliate from '@/components/my/MyAffiliate.vue'
import MyCommission from '@/components/my/MyCommission.vue'
import LandFullWebView from '@/components/h5/LandFullWebView.vue'
import PortraitFullWebView from '@/components/h5/PortraitFullWebView.vue'
import Withdraw from '@/components/withdraw/withdraw.vue'
import withdrawDetail from '@/components/withdraw/withdraw-detail.vue'
import withdrawAdd from '@/components/withdraw/withdraw-add.vue'
import withdrawModify from '@/components/withdraw/withdraw-modify.vue'

const routes = [

  {
    path: '/main',
    component: AppMain,
    mate: {
      keepalive: true
    },
    children: [
      {
        path: '/main/earn_cash/:id',
        component: EarnCashView
      },
      {
        path: '/main/my_cash',
        component: MyCashView,
        meta: { requiresAuth: true }
      },
      {
        path: '/main/web',
        props: true,
        component: LandWebView
      },
      {
        path: '/:catchAll(.*)',
        redirect: '/main/earn_cash/0'
      },
      {
        path: '/',
        redirect: '/main/earn_cash/0'
      }
    ]
  },
  {
    path: '/landweb',
    component: LandFullWebView
  },
  {
    path: '/portraitweb',
    component: PortraitFullWebView
  }, {
    path: '/withdraw',
    component: Withdraw
  }, {
    path: '/withdrawDetail',
    component: withdrawDetail
  }, {
    path: '/withdrawAdd',
    component: withdrawAdd
  },{
    path: '/withdrawModify',
    component: withdrawModify
  },
  {
    path: '/web',
    component: WebView
  },
  {
    path: '/myAffiliate',
    component: MyAffiliate
  },
  {
    path: '/myCommission',
    component: MyCommission
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/main'
  },
  {
    path: '/',
    redirect: '/main'
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

router.beforeResolve((to, from) => {
  if (to.meta.requiresAuth && !store.state.token) {
    store.commit('showLogin', true)
    return false
  }
  if (to.path.startsWith('/main/earn_cash')) {
    store.commit('currentTabPage', 'Earn Money')
    if (to.path === '/main/earn_cash/1') {
      store.commit('shareTip', true)
    } else {
      store.commit('shareTip', false)
    }
  }
  return true
})

export default router
