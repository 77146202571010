<script>
export default {
  data () {
    return {
      levelPic: [
        require('@/assets/level_big_bronze.png'),
        require('@/assets/level_big_silver.png'), require('@/assets/level_big_gold.png'),
        require('@/assets/level_big_platinum.png'), require('@/assets/level_big_diamond.png'),
        require('@/assets/level_big_king.png')]
    }
  },
  computed: {
    levels () {
      console.log(this.$store.state.appConfig)
      return this.$store.state.appConfig.level ? this.$store.state.appConfig.level : []
    }
  },
  methods: {
    dissmiss () {
      this.$emit('dismiss')
    },
    nomal () {
    }
  }
}
</script>
<template>
  <div class="root">
    <img src="@/assets/close_circle_brown.png">
    <div class="table" @click.stop="nomal">
      <p>TAURUS LEVEL</p>
      <div class="tab">
        <div class="table_header_row">
          <div class="table_header_cell">Level</div>
          <div class="table_header_cell">Invotation</div>
          <div class="table_header_cell">Invotation Recharge</div>
          <div class="table_header_cell">Affiliate Earnings</div>
        </div>

        <div v-for="(item,index) in levels " :key="item"
             :class="index % 2 === 1 ? 'table_content_row cell-bg' : 'table_content_row'">
          <div class="table_content_cell">
            <div class="img-contain">
              <img class="img-cell" :style="{width: 2+(index*0.4)+'rem',height:2+(index*0.3)+'rem'}"
                   v-bind:src="levelPic[index]"/>
            </div>
          </div>
          <div class="table_content_cell">{{ item.invitationDoc }}</div>
          <div class="table_content_cell">{{ item.invitationRechargeDoc }}</div>
          <div class="table_content_cell no-border-right">{{ item.affiliateEarningsDoc }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.root {
  width: 84rem;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.root > img {
  width: 5rem;
  height: 5rem;
  align-self: end;
}

.root > img:active {
  opacity: 0.6;
}

.table {
  width: 76.7rem;
  border-radius: 0.6rem;
  border: 0.2rem solid #E8E8E8;
  background: linear-gradient(#932916, #5D1906);
  display: flex;
  flex-direction: column;
}

.table > p {
  font-size: 2.4rem;
  color: white;
  padding: 2.4rem 0 0 1.7rem;
  font-weight: bolder;
  font-style: italic;
}

.tab {
  display: table;
  table-layout: fixed;

  margin: 2rem 1.6rem 2.3rem 1.6rem;
}

.table_header_row {
  display: table-row;
  background: #EE80AD;
}

.table_header_cell {
  display: table-cell;
  color: #1E0E0E;
  height: 4.6rem;
  width: 25%;
  line-height: 4.6rem;
  border-right: 0.2rem solid #77271C;
  text-align: center;
}

.table_content_row {
  display: table-row;
}

.table_content_cell {
  display: table-cell;
  color: white;
  width: 25%;
  height: 4.6rem;
  align-self: center;
  line-height: 4.6rem;
  justify-self: center;
  border-right: 0.2rem solid #77271C;
  text-align: center;
  position: relative;

}

.no-border-right {
  border-right: 0 solid transparent;
}

.img-contain {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.img-cell {
  background-size: cover;
}

.cell-bg {
  background-color: #F3B25633;
}
</style>
