<script>

export default {
  methods: {
    dismiss () {
      this.$emit('dismiss')
    },
    logout () {
      this.$emit('logout')
    }
  }
}
</script>

<template>
  <div class="contain">
    <p class="title">Whether to log out ?</p>
    <p class="positive" @click="logout">Yes</p>
    <p class="negative" @click="dismiss">No</p>
  </div>
</template>

<style scoped>
.contain {
  width: 50rem;
  height: 18rem;
  align-self: center;
  border-radius: 1rem;
  display: flex;
  flex-flow: wrap;
  justify-content: space-around;
  background: linear-gradient(-135deg, #F27D1C, #FFC147);
}

.title {
  color: white;
  font-size: 2.6rem;
  width: 50rem;
  height: 8rem;
  text-align: center;
  line-height: 8rem;
}

.positive {
  color: white;
  font-size: 2.6rem;
  width: 20rem;
  height: 6rem;
  text-align: center;
  line-height: 6rem;
  background-image: url("@/assets/btn_pink.png");
  background-size: cover;
}

.positive:active {
  opacity: 0.6;
}

.negative {
  color: white;
  font-size: 2.6rem;
  width: 20rem;
  text-align: center;
  line-height: 6rem;
  height: 6rem;
  background-image: url("@/assets/btn_green-1.png");
  background-size: cover;
}

.negative:active {
  opacity: 0.6;
}
</style>
