<template>
  <div id="content">

    <div id="nav">
      <div
        :class="item.name===this.$store.state.currentTabPage ? 'nav_item block_center nav_item_bg' :'nav_item block_center' "
        v-for="item in tabData"
        :key="item" @click="handlerTabRoute(item)">
        <div class="tab_txt">{{ item.enName }}</div>
        <div v-if="item.footmarkPaperEn" class="nav_tip_contain">
          <p>{{ item.footmarkPaperEn }}</p>
        </div>
      </div>
    </div>
    <div id="home">
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import { NavigationFailureType, isNavigationFailure } from 'vue-router'
import bus from 'vue3-eventbus'

export default {
  props: {
    tabData: Array
  },
  data () {
    return {}
  },
  created () {
    bus.on('main_navi', (name) => {
      for (const index in this.tabData) {
        const item = this.tabData[index]
        if (item.name === name) {
          this.handlerTabRoute(item)
          break
        }
      }
    })
  },
  methods: {
    async handlerTabRoute (tabData) {
      if (tabData.name === 'Earn Money') {
        const failure = await this.$router.push('/main/earn_cash/0')
        if (!isNavigationFailure(failure, NavigationFailureType.aborted)) {
          this.$store.commit('currentTabPage', tabData.name)
        }
        bus.emit('refresh', true)
      } else if (tabData.name === 'My Cash') {
        const failure = await this.$router.push('/main/my_cash')
        if (!isNavigationFailure(failure, NavigationFailureType.aborted)) {
          this.$store.commit('currentTabPage', tabData.name)
        }
      } else if (tabData.name === 'Upgrade Reward') {
        await this.$router.push({
          path: '/main/web',
          query: { url: 'upgrade_reward.html' },
          param: { url: 'upgrade_reward.html' }
        })
        this.$store.commit('currentTabPage', tabData.name)
      } else if (tabData.name === 'T&C') {
        await this.$router.push({
          path: '/main/web',
          query: { url: 'tc/t_c.html' },
          param: { url: 'tc/t_c.html' }
        })
        this.$store.commit('currentTabPage', tabData.name)
      } else if (tabData.name === 'Ranking') {
        await this.$router.push({
          path: '/main/web',
          query: { url: 'ranking.html' },
          param: { url: 'ranking.html' }
        })
        this.$store.commit('currentTabPage', tabData.name)
      } else if (tabData.name === 'Gullak') {
        await this.$router.push({
          path: '/main/web',
          query: { url: 'gullak.html' },
          param: { url: 'gullak.html' }
        })
        this.$store.commit('currentTabPage', tabData.name)
      } else {
        const failure = await this.$router.push({
          path: '/main/web',
          query: { url: tabData.enUrl },
          param: { url: tabData.enUrl }
        })
        if (!isNavigationFailure(failure, NavigationFailureType.aborted)) {
          this.$store.commit('currentTabPage', tabData.name)
        }
      }
    }
  }
}

</script>

<style scoped>

a {
  text-decoration: none; /* 去除下划线 */
  color: inherit; /* 继承父元素的文字颜色 */
}

#content {
  display: flex;
  width: 100%;
  height: 90%;
}

#nav {
  width: 13%;
  height: 100%;
  background: linear-gradient(#44130E 50%, transparent);
  overflow: scroll;
}

::-webkit-scrollbar {
  display: none;
}

#home {
  flex: 1;
  margin-left: 2rem;
}

.nav_item {
  width: 100%;
  height: 10.7rem;
  text-align: center;
  color: white;
  position: relative;
  font-size: 2.6rem;
}

.block_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav_item_bg {
  background-image: url("@/assets/nav_item_bg.png");
  background-size: 100% 100%;
}
.tab_txt{
  padding-left: 1rem;
  padding-right: 1rem;
}
.nav_tip_contain {
  background-image: url("@/assets/nav_item_tip.png");
  background-size: 100% 100%;
  width: 5rem;
  right: 0;
  top: 0;
  position: absolute;
  height: 2.5rem;
  display: flex;
  align-content: center;
  justify-content: center;
}

.nav_tip_contain > p {
  color: #B03900;
  font-size: 1.6rem;
  line-height: 2.5rem;
  font-weight: bolder;
  padding-left: 0.8rem;
}

</style>
