<template>
  <div id="app-main">
    <router-view></router-view>
  </div>
</template>

<script>

// document.documentElement.style.fontSize = (document.documentElement.clientHeight / 1280) * 10 + 'px'

import { mapActions } from 'vuex'

export default {
  data () {
    return {}
  },
  methods: {
    ...mapActions(['appConfig']),
    handleResize () {
      if (window.matchMedia('(orientation: portrait)').matches) {
        // 设备处于竖屏状态
        document.documentElement.style.fontSize = (document.documentElement.clientWidth / 720) * 10 + 'px'
      } else if (window.matchMedia('(orientation: landscape)').matches) {
        // 设备处于横屏状态
        document.documentElement.style.fontSize = (document.documentElement.clientHeight / 720) * 10 + 'px'
      }
    }
  },
  created () {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
    setTimeout(() => {
      this.appConfig()
    }, 100)

    window.addEventListener('message', (event) => {
      try {
        const data = JSON.parse(event.data)
        if (data.action === 'share_tip') {
          this.$router.push('/main/earn_cash/1')
        }
      } catch (e) {
        console.log(e)
      }
    }, false)
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.handleResize)
  }
}

</script>
<style>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  font-family: Arial, serif;
}

#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  background-image: url("@/assets/app_bg.png");
  background-size: cover;
  align-content: center;
}

@media screen and (orientation: portrait) {
  app-main {
    height: 100vw;
    width: 169vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    overflow: hidden;
  }
}

@media screen and (orientation: landscape) {
  app-main {
    width: 169vh;
    height: 95vh;
    display: flex;
    position: relative;
    align-self: center;
    justify-content: center;
    overflow: hidden;
  }
}
</style>
