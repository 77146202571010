<script>
export default {

  data () {
    return {
      url: this.$route.query.url
    }
  },
  mounted () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.url = this.$route.query.url
      },
      { immediate: true }
    )

    window.addEventListener('message', (event) => {
      console.log(event.data)
      if (event.data === 'closePage') {
        this.$router.push('/main/my_cash')
      } else {
        const data = JSON.parse(event.data)
        console.log('wv card sdk receive message', data)
        if (data.type === 'exitWithdrawIframe') {
          this.$router.push('/')
        }
      }
    })
  }
}
</script>

<template>
  <iframe class="web" ref="iframe" :src="url" id="sdk-iframe"></iframe>
</template>

<style scoped>

.web {
  width: 100vw;
  height: 100vh;
  border: none;
}
</style>
