<template>
  <div class="withdraw-root">
    <div class="withdraw-title">
      <exit-button-home :page="'withdraw'" style="margin-left:15vw"></exit-button-home>
      <div class="head">
        <div class="withdraw-dateil" @click="toDetail">{{ 'Withdraw Details' }}</div>
      </div>
    </div>
    <div class="page">
      <loading :loading="loading"/>
      <canvas id="canvas"></canvas>

      <div class="amount">
        <div class="amount-top">
          <div class="amount-top-left">
            <div>{{ 'Withdrawable Amount' }}</div>
            <div>₹ <span>{{ withdrawAmount }}</span></div>
          </div>
          <div class="amount-top-right">
            <div>
              <img src="@/assets/withdraw/money.png"/>
              <div class="amount-withdraw" @click="withdrawModel()">
                {{ 'Withdrawal' }}
              </div>
            </div>

          </div>
        </div>
        <div class="amount-bottom">
          <div class="amount-bottom-content">
            <div class="amount-bottom-content-tip">{{ 'Total Amount' }}</div>
            <div class="amount-bottom-content-amount">₹
              <span>{{ showAmount }}</span>
            </div>
          </div>
          <div class="amount-bottom-line"></div>
          <div class="amount-bottom-content">
            <div class="amount-bottom-content-tip">{{ 'Converting into Withdrawable Amount' }}</div>
            <div class="amount-bottom-content-amount">₹ <span>{{ (showAmount - withdrawAmount).toFixed(2) }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="content">
        <div class="withdraw-method">
          <div class="withdraw-method-top">{{ 'Withdraw Method' }}</div>
          <div class="withdraw-method-bottom" @click="openDrawer">
            <img class="img1" src="@/assets/withdraw/card.png"/>
            <div class="second-content-card-text">

              <div class="second-content-card-upi">{{ 'BANK' }}</div>

              <div class="second-content-card-upi-id" v-if="
                        this.upiBankInformation.bankAccountNumber !== null && this.upiBankInformation.bankAccountNumber !== undefined && this.upiBankInformation.bankAccountNumber !== ''
                            "
              >
                {{ this.upiBankInformation.bankAccountNumber }}
              </div>
              <div class="second-content-card-upi-id" v-else>
                {{ 'Requires your Bank Account' }}
              </div>
            </div>
            <img class="img2" src="@/assets/withdraw-before-2.png"/>
          </div>
        </div>

        <div class="total">
          <div class="total-text">
            <p>{{ 'Use only bank account to withdraw due to bank system maintenance.' }}</p>
            <p>{{ 'Time period for bank to complete all procedures varies.' }}</p>
            <!-- <p> {{'Withdrawal may not supported in the late afternoon and night, due to payment gateway maintenance.'}} </p>
            <p> {{'This maintenance last till next Tuesday.'}} </p> -->
            <!-- <p> {{ 'Withdrawal is temporarily unavailable due to bank system maintence. Please kindly wait.' }} </p> -->
          </div>
        </div>

        <div class="guess">
          <div class="guess-top">
            {{ 'Guess you want to know' }}
          </div>
          <div class="guess-bottom">
            <div class="guess-bottom-content">
              <div class="collapse">
                <!-- 折叠显示区域 -->
                <div class="collapse-show" @click="showCollapse(1)">
                  <div class="collapse-show-text">
                    {{ 'How to increase withdrawable amount?' }}
                  </div>
                  <i class="el-icon">
                    <img src='@/assets/withdraw/mo.png' v-if="collapseIsActive1"/>
                    <img src="@/assets/withdraw/add.png" v-else/>
                  </i>
                </div>
                <!-- 折叠显示区域 -->

                <!-- 折叠隐藏区域 -->
                <div class="guess-bottom-content-text" v-if="collapseIsActive1">
                  {{
                    'Part of your commission has not been converted into withdrawable amount yet because of your inactive referrals. Once those referrals are active in game, your commission will be converted into withdrawable amount.'
                  }}
                </div>
                <!-- 折叠隐藏区域 -->
              </div>
              <div class="guess-line"></div>
              <div class="collapse">
                <!-- 折叠显示区域 -->
                <div class="collapse-show" @click="showCollapse(2)">
                  <div class="collapse-show-text">{{ 'How to make my referrals active?' }}</div>
                  <i class="el-icon">
                    <img src='@/assets/withdraw/mo.png' v-if="collapseIsActive2"/>
                    <img src="@/assets/withdraw/add.png" v-else/>
                  </i>
                </div>
                <!-- 折叠显示区域 -->

                <!-- 折叠隐藏区域 -->
                <div class="guess-bottom-content-text" v-if="collapseIsActive2">
                  <div>{{ '1.Play game daily' }}</div>
                  <div>{{ '2.Recharge in game' }}</div>
                  <div>{{ '3.Log into game for continuous days' }}</div>
                  <div>{{ '4.High game rank and rewards' }}</div>
                </div>
                <!-- 折叠隐藏区域 -->
              </div>
              <div class="guess-line"></div>
              <div class="collapse">
                <!-- 折叠显示区域 -->
                <div class="collapse-show" @click="showCollapse(3)">
                  <div class="collapse-show-text">
                    {{ 'Why is the minimum withdrawal amount different every time?' }}
                  </div>
                  <i class="el-icon">
                    <img src='@/assets/withdraw/mo.png' v-if="collapseIsActive3"/>
                    <img src="@/assets/withdraw/add.png" v-else/>
                  </i>
                </div>
                <!-- 折叠显示区域 -->

                <!-- 折叠隐藏区域 -->
                <div class="guess-bottom-content-text" v-if="collapseIsActive3">
                  {{
                    'In order to make withdrawal easy for new users, Taurus provides minimum withdrawal amount with an increasingly fixed value for five times, when your withdrawable commission is less than ₹1000.'
                  }}
                </div>
                <!-- 折叠隐藏区域 -->
              </div>
            </div>

          </div>
          <div class="footer"></div>
        </div>
      </div>

      <div class="withdraw-modal" v-show="showWithdrawModal">
        <div class="withdraw-pos">
          <div class="withdraw-content">
            <div class="close-img" @click="withdrawModelClose()">
              <!--              <img c src="@/assets/withdraw/close.png"/>-->
            </div>
            <div class="withdraw-amount">
              <div>{{ 'Withdraw Amount' }}</div>
              <div>
                ₹
                <span style="font-size: 10rem;font-weight: bolder">{{ canWithdrawAmount }}</span>
              </div>
            </div>
            <div class="withdraw-method" style="background: #F6F6F6">
              <div>{{ 'Withdraw Method' }}</div>

              <div
                v-if="this.upiBankInformation.upiAccountNumber !== null && this.upiBankInformation.upiAccountNumber !== undefined && this.upiBankInformation.upiAccountNumber !== ''">
                {{ 'UPI' }} {{ this.upiBankInformation.upiAccountNumber }}
              </div>
              <div style="color: #1B1B1B"
                   v-if="this.upiBankInformation.bankAccountNumber !== null && this.upiBankInformation.bankAccountNumber !== undefined && this.upiBankInformation.bankAccountNumber !== ''">
                {{ 'BANK' }}
                {{ this.upiBankInformation.bankAccountNumber }}
              </div>
            </div>
            <div class="withdraw-bottom" id="button">
              <div class="withdraw-money"/>
              <div class="amount-withdraw"
                   :disabled="isDisabled"
                   @click="cashfreeApiPayoutWithdraw2">
                {{ 'Withdrawal' }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="drawer-contain">

        <a-drawer title="" :placement="placement" :closable="true" :visible="visible" @close="onClose"
                  style="width: 100rem;margin-left: 18%"
                  height="80%">
          <div v-if="upiBankList.length===0" class="bank-empty">
            <img class="bank-empty-pic" src="@/assets/withdraw/empyt_icon.png">
            <p class="bank-empty-txt">
              There is currently no bank card information that can be added~
              Just add it.</p>
          </div>
          <div v-else v-for="(item, index) in upiBankList" :key="index">
            <div class="withdraw-before-method" @click="selectWithdrawMethod(item, item.id)">
              <div class="withdraw-before-upi" v-if="index < 10">
                <div
                  v-if="item.upiAccountNumber !== null && item.upiAccountNumber !== undefined && item.upiAccountNumber !== ''">
                  <img class="withdraw-before-upi-img" src="@/assets/withdraw/upi.png"/>
                </div>

                <div v-else>
                  <img class="withdraw-before-upi-img" src="@/assets/withdraw-before-1-1.png"/>
                </div>

                <div class="upi-row-container">
                  <div class="upi-second-container">
                    <div class="upi-detail-container">
                      <div class="upi-text"
                           v-if="item.upiAccountNumber !== null && item.upiAccountNumber !== undefined && item.upiAccountNumber !== ''">
                        {{ 'UPI' }}
                      </div>
                      <div class="upi-text" v-else>
                        {{ 'BANK' }}
                      </div>
                      <img class="withdraw-before-modify-img" src="@/assets/withdraw-before-modify.png"
                           @click.stop="toWithdrawModify(item)"/>
                    </div>
                    <div class="upi-number"
                         v-if="item.upiAccountNumber !== null && item.upiAccountNumber !== undefined && item.upiAccountNumber !== ''">
                      {{ item.upiAccountNumber }}
                    </div>
                    <div class="upi-number" v-else>
                      {{ item.bankAccountNumber }}
                    </div>
                  </div>
                  <img class="withdraw-access-img" src="@/assets/withdraw-access.png"
                       v-if="isWithdrawAccessImg == item.id"/>
                </div>
              </div>
              <div class="upi-row" v-if="index < 10"></div>
            </div>
          </div>

          <div @click="toWithdrawAdd"
               v-if="upiBankList.length < 10">
            <!--            <div class="withdraw-before-upi">-->
            <div class="bank-bottom add-bank">
              <div class="add-bank-txt">
                <div class="upi-detail-container adding-container">
                  <div class="upi-text">{{ 'Adding a bank account' }}</div>
                </div>
              </div>
              <img class="add-arrow" src="@/assets/withdraw-before-jump.png"/>
            </div>
            <!--            </div>-->
          </div>
          <!--          <div class="withdraw-before-detemine-line-2"></div>-->
        </a-drawer>
      </div>

      <div class="verify-modal" v-if="showMobileVerifyConfirmModal" @click="handlerParent">
        <div class="content OTP-content" @click="handler">
          <div class="OTP-container">
            <div class="OTP-title">{{ 'Verify OTP' }}</div>
            <img class="opt-close" @click="cancel" src="@/assets/withdraw/close.png"/>
          </div>
          <div class="form OTP-form">
            <div class="row flex flex-r flex-center">
              <div class="input-w input-w-mobile flex flex-r flex-a">
                <div class="input-label-icon input-label-icon-mobile"></div>
                <div class="input-label-text">{{ '+91' }}</div>
                <input class="input input-mobile" type="tel" v-model.trim="mobileToVerify" :readonly="!!mobileToVerify"
                       :placeholder="'Type your phone number'" maxlength="10"/>
              </div>
            </div>
            <div class="row flex flex-r flex-a">
              <div class="input-w input-w-verify flex flex-r flex-a ">
                <div class="input-label-icon input-label-icon-verify"></div>
                <input class="input input-verify" type="tel" v-on:input="change" v-model.trim="mobileVerifyCode"
                       maxlength="4" :placeholder="'Type your OTP'"/>
              </div>
              <div class="send-btn" @click="sendVerify">{{ msg }}</div>
            </div>
            <div class="new-button-container">
              <!--              <div class="cancel" @click="cancel">{{ 'Cancel' }}</div>-->
              <div :class="isClick ? 'confirm' : 'disabled'" @click="doVerifyMobile">{{ 'Confirm' }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="upi-bank-modal" v-if="showUPIBankConfirmModal">
        <div class="content">
          <div class="modal-title">{{ 'Notice' }}</div>
          <div class="modal-body">
            <div class="modal-content flex flex-r">
              {{
                'Please choose BANK ACCOUNT to withdraw. Because of policy from third party payment organization, currently UPI is not supported.'
              }}
            </div>
            <div class="modal-btn flex flex-r">
              <div class="confirm" @click="confirmUPIBank">{{ 'Got it' }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import User from '@/components/withdraw/user'
import message from 'ant-design-vue/lib/message'
import 'ant-design-vue/lib/message/style'

import Loading from '@/components/withdraw/Loading'
import ExitButtonHome from '@/components/withdraw/ExitButton-home.vue'
import Timer from '@/utils/timer'
import buriedpoint from '@/utils/buriedpoint'

export default {
  components: {
    Loading,
    ExitButtonHome
  },
  data() {
    return {
      showWithdrawModal: false,
      collapseIsActive1: false,
      collapseIsActive2: false,
      collapseIsActive3: false,
      bodyHeight: '',
      vungoId: '',
      vungoChannelid: '',
      vungoExtra: '',
      vungoPkg: '',
      upiString: 'UPI',
      bankString: 'BANK',
      mobileToVerify: '',
      timer: null,
      mobileVerifyCode: '',
      addOrModify: '',
      cardUpi: 'UPI',
      requires: 'Requires your UPI ID',
      vungoUid: '',
      vungoAid: '',
      msg: 'Request',
      showMobileVerifyConfirmModal: false,
      isDisabled: true,
      isWithdrawWarning: false,
      upiBankInformation: {},
      withdrawAmount: 0,
      showAmount: 0,
      isWithdrawAccessImg: -1,
      upiBankList: [],
      isWithdraw: true,
      visible: false,
      placement: 'bottom',

      showClose: true,
      drawer: false,
      loading: false,
      withdrawCheck: 0,
      canWithdrawAmount: 0,
      minWithdraw: 1000,
      currentWithdraw: 1000,
      dailyWithdraw: 1,
      showLang: false,
      isWithdrawing: false,
      showUpi: true,
      showBank: false,
      payoutMode: 'upi',
      showUpiForm: true,
      showBankForm: false,
      showToggle: false,
      upiMobile: '',
      upiId: '',
      bankMobile: '',
      bankAccountNumber: '',
      bankAccountNumberConfirm: '',
      bankAccountHolderName: '',
      bankAccountIfscCode: '',
      name: '',
      showUPIBankConfirmModal: false,
      isClick: false,
      pointCode: '',
      pointPkg: '',
      pointChannel: '',
      pointPn: '',
      pointPlatform: '',
      pointAid: '',
      pointGaid: '',
      pointTaurusStatUuid: '',
      pointUid: '',

      confettiCount: 20,
      sequinCount: 10,
      gravityConfetti: 0.3,
      gravitySequins: 0.55,
      dragConfetti: 0.075,
      dragSequins: 0.02,
      terminalVelocity: 3,
      button: {},
      disabled: false,
      canvas: {},
      ctx: {},
      cx: '',
      cy: '',
      confetti: [],
      sequins: [],
      colors: [
        {
          front: '#7b5cff',
          back: '#6245e0'
        }, // Purple
        {
          front: '#b3c7ff',
          back: '#8fa5e5'
        }, // Light Blue
        {
          front: '#5c86ff',
          back: '#345dd1'
        }, // Darker Blue
        {
          front: '#c64ef2',
          back: '#712c8a'
        },
        {
          front: '#af42d6',
          back: '#712c8a'
        }
      ]
    }
  },
  computed: {
    formatedVerifyMobile() {
      return this.mobileToVerify ? '91' + ('' + this.mobileToVerify) : ''
    }
  },
  watch: {
    upiMobile(newD, old) {
      if (newD > 9999999999) {
        this.upiMobile = old
      }
    },
    bankMobile(newD, old) {
      if (newD > 9999999999) {
        this.bankMobile = old
      }
    },
    mobileVerifyCode(value) {
      if (value.length > 0) {
        this.isClick = true
      } else {
        this.isClick = false
      }
    }
  },
  methods: {
    withdrawModelClose() {
      this.showWithdrawModal = false
    },
    withdrawModel() {
      if ((this.upiBankInformation.upiAccountNumber !== null && this.upiBankInformation.upiAccountNumber !== undefined && this.upiBankInformation.upiAccountNumber !== '') ||
        (this.upiBankInformation.bankAccountNumber !== null && this.upiBankInformation.bankAccountNumber !== undefined && this.upiBankInformation.bankAccountNumber !== '')
      ) {
        this.showWithdrawModal = true
      } else {
        message.error({
          content: 'Please select UPI or Bank account for your withdrawal'
        })
      }
    },
    showCollapse(value) {
      if (value === 1) {
        this.collapseIsActive1 = !this.collapseIsActive1
      } else if (value === 2) {
        this.collapseIsActive2 = !this.collapseIsActive2
      } else if (value === 3) {
        this.collapseIsActive3 = !this.collapseIsActive3
      }
    },
    resizeCanvas() {
      this.canvas.width = window.innerWidth
      this.canvas.height = window.innerHeight
      this.cx = this.ctx.canvas.width / 2
      this.cy = this.ctx.canvas.height / 2
    },

    randomRange(min, max) {
      return Math.random() * (max - min) + min
    },
    initConfettoVelocity(xRange, yRange) {
      const x = this.randomRange(xRange[0], xRange[1])
      const range = yRange[1] - yRange[0] + 1
      let y = yRange[1] - Math.abs(this.randomRange(0, range) + this.randomRange(0, range) - range)
      if (y >= yRange[1] - 1) {
        // Occasional confetto goes higher than the max
        y += (Math.random() < 0.25) ? this.randomRange(1, 3) : 0
      }
      return {
        x: x,
        y: -y
      }
    },
    initBurst() {
      const that = this
      for (let i = 0; i < this.confettiCount; i++) {
        this.confetti.push(new Confetto())
      }
      for (let i = 0; i < this.sequinCount; i++) {
        this.sequins.push(new Sequin())
      }
      console.log(this.confetti)

      // let { requestAnimationFrame } = this
      function Confetto() {
        this.randomModifier = that.randomRange(0, 99)
        this.color = that.colors[Math.floor(that.randomRange(0, that.colors.length))]
        this.dimensions = {
          x: that.randomRange(5, 9),
          y: that.randomRange(8, 15),
        }
        this.position = {
          x: that.randomRange(that.canvas.width / 2 - that.button.offsetWidth / 4, that.canvas.width / 2 + that.button.offsetWidth / 4),
          y: that.randomRange(that.canvas.height / 2 + that.button.offsetHeight / 2 + 8, that.canvas.height / 2 + (1.5 * that.button.offsetHeight) - 8),
        }
        this.rotation = that.randomRange(0, 2 * Math.PI)
        this.scale = {
          x: 1,
          y: 1,
        }
        this.velocity = that.initConfettoVelocity([-9, 9], [6, 11])
      }

      Confetto.prototype.update = function () {
        // apply forces to velocity
        this.velocity.x -= this.velocity.x * 0.075
        this.velocity.y = Math.min(this.velocity.y + that.gravityConfetti, that.terminalVelocity)
        this.velocity.x += Math.random() > 0.5 ? Math.random() : -Math.random()

        // set position
        this.position.x += this.velocity.x
        this.position.y += this.velocity.y

        // spin confetto by scaling y and set the color, .09 just slows cosine frequency
        this.scale.y = Math.cos((this.position.y + this.randomModifier) * 0.09)
      }

      // Sequin Class
      function Sequin() {
        this.color = that.colors[Math.floor(that.randomRange(0, that.colors.length))].back,
          this.radius = that.randomRange(1, 2),
          this.position = {
            x: that.randomRange(that.canvas.width / 2 - that.button.offsetWidth / 3, that.canvas.width / 2 + that.button.offsetWidth / 3),
            y: that.randomRange(that.canvas.height / 2 + that.button.offsetHeight / 2 + 8, that.canvas.height / 2 + (1.5 * that.button.offsetHeight) - 8),
          },
          this.velocity = {
            x: that.randomRange(-6, 6),
            y: that.randomRange(-8, -12)
          }
      }

      Sequin.prototype.update = function () {
        // apply forces to velocity
        this.velocity.x -= this.velocity.x * that.dragSequins
        this.velocity.y = this.velocity.y + that.gravitySequins

        // set position
        this.position.x += this.velocity.x
        this.position.y += this.velocity.y
      }
    },
    render() {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
      this.confetti.forEach((confetto) => {
        let width = confetto.dimensions.x * confetto.scale.x
        let height = confetto.dimensions.y * confetto.scale.y

        // move canvas to position and rotate
        this.ctx.translate(confetto.position.x, confetto.position.y)
        this.ctx.rotate(confetto.rotation)

        // update confetto "physics" values
        confetto.update()

        // get front or back fill color
        this.ctx.fillStyle = confetto.scale.y > 0 ? confetto.color.front : confetto.color.back

        // draw confetto
        this.ctx.fillRect(-width / 2, -height / 2, width, height)

        // reset transform matrix
        this.ctx.setTransform(1, 0, 0, 1, 0, 0)

        // clear rectangle where button cuts off
        if (confetto.velocity.y < 0) {
          this.ctx.clearRect(this.canvas.width / 2 - this.button.offsetWidth / 2, this.canvas.height / 2 + this.button.offsetHeight / 2, this.button.offsetWidth, this.button.offsetHeight)
        }
      })
      this.sequins.forEach((sequin) => {
        // move canvas to position
        this.ctx.translate(sequin.position.x, sequin.position.y)

        // update sequin "physics" values
        sequin.update()

        // set the color
        this.ctx.fillStyle = sequin.color

        // draw sequin
        this.ctx.beginPath()
        this.ctx.arc(0, 0, sequin.radius, 0, 2 * Math.PI)
        this.ctx.fill()

        // reset transform matrix
        this.ctx.setTransform(1, 0, 0, 1, 0, 0)

        // clear rectangle where button cuts off
        if (sequin.velocity.y < 0) {
          this.ctx.clearRect(this.canvas.width / 2 - this.button.offsetWidth / 2, this.canvas.height / 2 + this.button.offsetHeight / 2, this.button.offsetWidth, this.button.offsetHeight)
        }
      })

      // remove confetti and sequins that fall off the screen
      // must be done in seperate loops to avoid noticeable flickering
      this.confetti.forEach((confetto, index) => {
        if (confetto.position.y >= this.canvas.height) this.confetti.splice(index, 1)
      })
      this.sequins.forEach((sequin, index) => {
        if (sequin.position.y >= this.canvas.height) this.sequins.splice(index, 1)
      })

      window.requestAnimationFrame(this.render)
    },
    rebpoint(key, value) {
      let now = (new Date().getTime() / 1000).toFixed(0)
      buriedpoint
        .point(
          this.pointCode,
          new Date().getTime(),
          '',
          this.pointPkg,
          this.pointChannel,
          'hy',
          '',
          this.pointPlatform,
          this.pointAid,
          this.pointGaid,
          this.pointTaurusStatUuid,
          this.pointUid,
          'event',
          [
            {
              ts: now,
              eventKey: key,
              eventValue: value,
            },
          ]
        )
        .then((res) => {
          if (res.code === 0) {
            console.log('--tcpa_w/d_(n)_cfm', 'tcpa_w/d_(n)_cfm')
          }
        })
        .catch((err) => {
          console.log('point', err)
          // return Promise.reject(err);
        })
    },
    change() {
      this.mobileVerifyCode = this.mobileVerifyCode.replace(/[^\d]/g, '')
    },
    handlerParent() {
      this.showMobileVerifyConfirmModal = false
    },
    handler(event) {
      event.stopPropagation()
      event.cancelBubble = true
    },
    confirmUPIBank() {
      this.showUPIBankConfirmModal = false
    },
    unFormatedVerifyMobile(mobile) {
      return mobile.length > 2 && mobile.startsWith('91') ? mobile.substr(2) : mobile
    },
    formatMobile(mobile) {
      return '91' + mobile
    },
    sendVerify() {
      if (this.mobileToVerify) {
        if (this.timer.isRunning) {
          return
        }
        if (!this.hasSendVerifyCode) {
          // 'tc_home_display_018'
          // this.$log.info('tc_home_display_018');
        } else {
          //  'tc_home_display_019'
          // this.$log.info('tc_home_display_019');
        }
        this.hasSendVerifyCode = true
        User.verify(this.formatedVerifyMobile)
          .then((res) => {
            console.log(res)
          })
          .catch((err) => {
            console.log('err:', err)
          })

        this.timer.run(() => {
          if (this.timer.counter === 0) {
            this.msg = 'Send'
          } else {
            this.msg = this.timer.counter
          }
        })
      }
    },
    cancel() {
      this.showMobileVerifyConfirmModal = false
      this.mobileVerifyCode = ''
    },
    closeVerifyModal() {
      this.showMobileVerifyConfirmModal = false
      console.log('signup close', this.lastScrollTop)
      this.closeModal()
    },
    closeModal() {
      document.body.style.position = 'static'
      this.$nextTick(() => {
        document.body.scrollTo(0, this.lastScrollTop)
        document.documentElement.scrollTo(0, this.lastScrollTop)
      })
    },
    toVerifyMobile() {
      this.showMobileVerifyModal = false
      this.showMobileVerifyConfirmModal = true
      this.rebpoint('tcpa_w/d_listbox_popup_otp', '')
      // this.$log.info('tc_home_display_017');
    },
    doVerifyMobile() {
      if (!this.formatedVerifyMobile) {
        message.error({
          content: 'Phone number required',
        })
        return false
      }
      if (!this.mobileVerifyCode) {
        message.error({
          content: 'OTP required',
        })
        return false
      }
      User.doVerify(this.formatedVerifyMobile, this.mobileVerifyCode, this.vungoUid, this.vungoAid)
        .then((res) => {
          console.log(res)
          this.mobileVerifyCode = ''

          this.showMobileVerifyConfirmModal = false
          console.log('---current select ---' + this.addOrModify)
          if (this.addOrModify === 'add') {
            this.$router.push('/withdrawAdd')
          } else {
            this.$router.push({
              path: '/withdrawModify',
              query: this.upiBankInformation
            })
          }

        })
        .catch((err) => {
          console.log('err:', err)
        })
    },
    toWithdrawModify(item) {
      //'tcpa_w/d_listbox_click_update'
      // this.$log.info('tcpa_w/d_listbox_click_update')

      //'tcpa_w/d_listbox_popup_otp'
      // this.$log.info('tcpa_w/d_listbox_popup_otp')
      console.log('---toWithdrawModify---' + item)
      this.showMobileVerifyConfirmModal = true
      this.addOrModify = 'modify'
      this.rebpoint('tcpa_w/d_listbox_click_update', '')
      localStorage.setItem('modifyUpiBankInformation', JSON.stringify(item))
      this.onClose()
      // this.$router.push({ path: '/withdrawModify', query: item });
    },

    withdraw2() {
      if (this.withdrawCheck == 0) {
        this.isWithdrawWarning = true
        /* message.error({
                content: this.$t('At least {0} can be withdrawn', [this.minWithdraw]),
            }); */
        return -1
      } else if (this.withdrawCheck == 2) {
        message.error({
          content: 'Withdraw in progress, check and handle it first.',
        })
        return -1
      } else if (this.withdrawCheck == 3) {
        message.error({
          content: 'At most 1 withdraw in 24 hours'
        })
        //
        return -1
      }

      this.isWithdraw = false
      return 0
    },

    determine() {
      if (this.upiBankInformation.upiAccountNumber !== null && this.upiBankInformation.upiAccountNumber !== undefined && this.upiBankInformation.upiAccountNumber !== '') {
        this.cardUpi = 'UPI'
      } else {
        this.cardUpi = 'BANK'
      }
      this.requires = this.upiBankInformation.mobile
      localStorage.setItem('cardUpi', this.cardUpi)
      localStorage.setItem('requires', this.requires)
      this.isDisabled = true
      this.visible = false
      //提现前的校验
      const states = this.withdraw2()
      console.log('---this.withdraw2---' + states)
      if (states === -1) {
        this.isWithdraw = true
      } else {
        this.isWithdraw = false
        this.isDisabled = false
      }
    },
    cashfreeApiPayoutWithdraw2() {
      //'tcpa_w/d_(n)_cfm':' ',
      // this.$log.info('tcpa_w/d_(n)_cfm', { 金额: this.canWithdrawAmount })
      this.rebpoint('tcpa_w/d_(n)_cfm', this.canWithdrawAmount)

      if (this.upiBankInformation.upiAccountNumber !== null && this.upiBankInformation.upiAccountNumber !== undefined && this.upiBankInformation.upiAccountNumber !== '') {
        this.payoutMode = 'upi'
      } else {
        this.payoutMode = 'bank'
      }
      if (this.payoutMode == 'upi' && this.canWithdrawAmount >= 100) {
        this.showUPIBankConfirmModal = true
        return false
      }
      this.isDisabled = true
      this.isWithdraw = true
      if (this.isWithdrawAccessImg === -1) {
        message.error({
          content: 'Please select UPI or Bank account for your withdrawal',
        })
        return false
      }

      if (this.isWithdrawing) {
        message.error({
          content: 'Withdraw in progress, check and handle it first.',
        })
        return false
      }

      this.isWithdrawing = true

      let balance = this.canWithdrawAmount
      console.log('withdraw amount:', balance)
      let cashfreeApiParams = {
        name: this.name,
        mobile: this.formatMobile(this.upiBankInformation.mobile),
        amount: this.canWithdrawAmount,
        payoutMode: this.payoutMode,
        upiId: this.upiBankInformation.upiAccountNumber,
        bankAccountNumber: this.upiBankInformation.bankAccountNumber,
        bankAccountHolderName: this.upiBankInformation.bankAccountHolderName,
        bankAccountIfscCode: this.upiBankInformation.accountIfscCode ? this.upiBankInformation.accountIfscCode.trim() : '',
      }

      console.log('---withdraw param---' + JSON.stringify(cashfreeApiParams))
      User.withdrawCashfreeApi(cashfreeApiParams)
        .then((data) => {
          this.loading = false
          this.isWithdrawing = false
          this.isDisabled = false
          if (data.success) {
            // console.log("hyq" + data);
            this.refresh()
          } else {
            this.withdrawModelClose()
            console.log('hyq1' + data)
            this.initBurst()

            // message.error({
            //     content: data.message
            // });
            this.rebpoint('tcpa_w/d_(n)_suc', this.canWithdrawAmount)
          }
        })
        .catch((err) => {
          this.loading = false
          this.isWithdrawing = false
          this.isDisabled = false
          console.log(err)

          this.rebpoint('tcpa_w/d_(n)_fail', this.canWithdrawAmount)
        })
    },

    selectWithdrawMethod(item, id) {
      //'tcpa_w/d_listbox_click_select'
      // this.$log.info('tcpa_w/d_listbox_click_select')

      if (this.isWithdrawAccessImg == id) {
        this.isWithdrawAccessImg = -1
      } else {
        this.isWithdrawAccessImg = id
      }
      localStorage.setItem('isWithdrawAccessImg', this.isWithdrawAccessImg)
      this.upiBankInformation = item
      localStorage.setItem('upiBankInformation', JSON.stringify(item))
      console.log(id)
      console.log('---selected bank accunt---' + this.upiBankInformation)
      this.determine()
    },
    toWithdrawAdd() {
      this.showMobileVerifyConfirmModal = true
      this.addOrModify = 'add'
      // this.$router.push('/withdrawAdd');
      //'tcpa_w/d_listbox_click_add'
      // this.$log.info('tcpa_w/d_listbox_click_add')
      this.rebpoint('tcpa_w/d_listbox_click_add', '')
      this.onClose()
      //'tcpa_w/d_listbox_popup_otp'
      // this.$log.info('tcpa_w/d_listbox_popup_otp')
    },
    onClose() {
      this.visible = false
    },
    openDrawer() {
      // if (this.upiBankInformation.upiAccountNumber !== null && this.upiBankInformation.upiAccountNumber !== undefined && this.upiBankInformation.upiAccountNumber !== '') {
      //   //'tcpa_w/d_click_select'
      //   // this.$log.info('tcpa_w/d_click_select')
      // } else if (this.upiBankInformation.bankAccountNumber !== null && this.upiBankInformation.bankAccountNumber !== undefined && this.upiBankInformation.bankAccountNumber !== '') {
      //   //'tcpa_w/d_click_select'
      //   // this.$log.info('tcpa_w/d_click_select')
      // } else {
      //   //'tcpa_w/d_click_add'
      //   // this.$log.info('tcpa_w/d_click_add')
      // }
      //
      // //'tcpa_w/d_popup_listbox'
      // // this.$log.info('tcpa_w/d_popup_listbox')
      this.rebpoint('tcpa_w/d_click_select', '')
      this.visible = true
    },
    handleClose(done) {
      done()
    },
    back() {
      this.$router.go(-1)
    },
    changePayoutMode(payoutMode) {
      this.payoutMode = payoutMode
    },
    formatBankAccount(e) {
      e.target.value = e.target.value.replace(/\D+/g, '')
      this.bankAccountNumber = e.target.value.toString().replace(/\s/g, '')
      let value = this.bankAccountNumber.toString().replace(/(\d{4})(?=\d)/g, '$1 ')
      console.log(value, this.bankAccountNumber)
      e.target.value = value
    },
    formatBankAccountConfirm(e) {
      e.target.value = e.target.value.replace(/\D+/g, '')
      this.bankAccountNumberConfirm = e.target.value.toString().replace(/\s/g, '')
      let value = this.bankAccountNumberConfirm.toString().replace(/(\d{4})(?=\d)/g, '$1 ')
      console.log(value, this.bankAccountNumberConfirm)
      e.target.value = value
    },
    formatUpiMobile(e) {
      e.target.value = e.target.value.replace(/\D+/g, '')
      if (e.target.value > 9999999999) {
        e.target.value = this.upiMobile
      }
      this.upiMobile = e.target.value.toString().replace(/\s/g, '')
      let value = this.upiMobile.toString().replace(/(\d{4})(?=\d)/g, '$1 ')
      console.log(value, this.upiMobile)
      e.target.value = value
    },
    formatBankMobile(e) {
      e.target.value = e.target.value.replace(/\D+/g, '')
      if (e.target.value > 9999999999) {
        e.target.value = this.bankMobile
      }
      this.bankMobile = e.target.value.toString().replace(/\s/g, '')
      let value = this.bankMobile.toString().replace(/(\d{4})(?=\d)/g, '$1 ')
      console.log(value, this.bankMobile)
      e.target.value = value
    },
    cashfreeApiPayoutWithdraw() {
      if (!this.showUpi && !this.showBank) {
        message.error({
          content: 'Please select UPI or Bank account for your withdrawal',
        })
        return false
      }
      if (this.showUpi) {
        if (!this.name) {
          message.error({
            content: 'Name required',
          })
          return false
        }
        if (!this.upiMobile) {
          message.error({
            content: 'Phone number required',
          })
          return false
        } else if (this.upiMobile < 1000000000 || this.upiMobile > 9999999999) {
          message.error({
            content: 'Invalid mobile phone number',
          })
          return false
        }
        if (!this.upiId) {
          message.error({
            content: 'UPI ID required',
          })
          return false
        }
      }
      if (this.showBank) {
        if (!this.name) {
          message.error({
            content: 'Name required',
          })
          return false
        }
        if (!this.bankMobile) {
          message.error({
            content: 'Phone number required',
          })
          return false
        } else if (this.bankMobile < 1000000000 || this.bankMobile > 9999999999) {
          message.error({
            content: 'Invalid mobile phone number',
          })
          return false
        }
        if (!this.bankAccountNumber) {
          message.error({
            content: 'Bank account number required',
          })
          return false
        }
        if (this.bankAccountNumber != this.bankAccountNumberConfirm) {
          message.error({
            content: 'Bank account number confirmation does not match',
          })
          return false
        }
        if (!this.bankAccountHolderName) {
          message.error({
            content: 'Bank account holder name required',
          })
          return false
        }
        if (!this.bankAccountIfscCode) {
          message.error({
            content: 'Bank account IFSC code required',
          })
          return false
        }
      }
      if (this.isWithdrawing) {
        message.error({
          content: 'Withdraw in progress, check and handle it first.',
        })
        return false
      }
      this.isWithdrawing = true

      let balance = this.canWithdrawAmount
      console.log('withdraw amount:', balance)
      this.loading = true
      let cashfreeApiParams = {
        name: this.name,
        mobile: '91' + (this.showUpi ? this.upiMobile : this.bankMobile),
        amount: this.canWithdrawAmount,
        payoutMode: this.payoutMode,
        upiId: this.upiId,
        bankAccountNumber: this.bankAccountNumber,
        bankAccountHolderName: this.bankAccountHolderName,
        bankAccountIfscCode: this.bankAccountIfscCode ? this.bankAccountIfscCode.trim() : '',
      }

      User.withdrawCashfreeApi(cashfreeApiParams)
        .then((data) => {
          this.loading = false
          this.isWithdrawing = false
          if (data.success) {
            //'tcpa_w/d_(n)_suc'
            // this.$log.info('tcpa_w/d_(n)_suc', { amount: this.canWithdrawAmount })
            this.userInfo()
          } else {
            //'tcpa_w/d_(n)_fail'
            // this.$log.info('tcpa_w/d_(n)_fail', { amount: this.canWithdrawAmount })

            console.log(data)
            // message.error({
            //     content: data.message
            // });
          }
        })
        .catch((err) => {
          this.loading = false
          this.isWithdrawing = false
          console.log(err)
        })
    },
    refresh() {
      setTimeout(() => {
        this.back()
      }, 2000)
    },
    userInfo() {
      User.info(this.vungoId, this.vungoUid, this.vungoAid, this.vungoChannelid, this.vungoPkg, this.vungoExtra)
        .then((data) => {
          let res = data.data
          console.log('---refresh user---' + JSON.stringify(res))
          console.log('hyq')
          this.withdrawAmount = res.realBalance
          this.showAmount = res.showBalance

          this.mobileToVerify = this.unFormatedVerifyMobile(res.mobile)
        })
        .catch((err) => {
          console.log('login error:', err)
        })
      User.withdrawCheck()
        .then((data) => {
          console.log('withdrawCheck::', data)
          this.withdrawCheck = data.status
          this.canWithdrawAmount = data.allow_amount
          this.currentWithdraw = data.withdraw_status.currentWithdraw

          if (data.mobile && data.mobile.length == 12) {
            let mobile = data.mobile.substr(2)
            let e = document.createEvent('HTMLEvents')
            e.initEvent('input', true, true)
            let elemUpi = document.getElementById('upiMobile')
            let elemBank = document.getElementById('bankMobile')
            if (elemUpi) {
              elemUpi.value = mobile
              elemUpi.dispatchEvent(e)
              this.formatUpiMobile(e)
            }
            if (elemBank) {
              elemBank.value = mobile
              elemBank.dispatchEvent(e)
              this.formatBankMobile(e)
            }
          }

          this.determine()
        })
        .catch((err) => {
          console.log(err)
        })
    },
    fun() {
      // this.$router.pus h('/home');
      if (window.closePage) {
        window.closePage.closePage()
      } else if (window.share) {
        // window.share.exit()
      } else {
        this.$router.replace({path: '/home'})
      }
    },
    toDetail() {
      this.rebpoint('tcpa_w/d_Historical', '')
      // this.$log.info('tcpa_w/d_Historical');
      this.$router.push('/withdrawDetail')
    },
  },

  mounted() {

    let cachedMobile = localStorage.getItem('vg_mobile')
    if (cachedMobile) {
      this.mobileToVerify = this.unFormatedVerifyMobile(cachedMobile)
    }
    let isWithdrawAccessImg = localStorage.getItem('isWithdrawAccessImg')
    if (isWithdrawAccessImg) {
      this.isWithdrawAccessImg = isWithdrawAccessImg
    }
    User.queryUpiAndBank().then((data) => {
      if (data) {
        // console.log('---queryUpiAndBank---', data.data);
        this.upiBankList = data.data
        console.log('---upiBankList---' + data.data)
        // console.log('---this.upiBankList[0].userId---' + this.upiBankList[0].userId)
        // console.log('---this.upiBankList[0].upiAccountNumber---' + this.upiBankList[0].upiAccountNumber)
        // console.log('---this.upiBankList.length---' + this.upiBankList.length)
      }
    })

    this.bodyHeight = document.documentElement.clientHeight
    //'tcpa_w/d_disp'
    // this.$log.info('tcpa_w/d_disp')

    this.vungoId = localStorage.getItem('vungoId')
    this.vungoUid = this.$store.state.uid
    this.vungoAid = this.$store.state.aid
    this.vungoChannelid = this.$store.state.channel
    this.vungoPkg = this.$store.state.pkg
    this.vungoExtra = localStorage.getItem('vungoExtra')
    if (localStorage.getItem('cardUpi') != null && localStorage.getItem('requires') != null) {
      this.cardUpi = localStorage.getItem('cardUpi')
      this.requires = localStorage.getItem('requires')
    }

    if (this.upiBankList.length > 0) {
      if (this.upiBankList[0].upiAccountNumber !== null && this.upiBankList[0].upiAccountNumber !== undefined && this.upiBankList[0].upiAccountNumber !== '') {
        this.cardUpi = this.upiString
      } else {
        this.cardUpi = this.bankString
      }
      this.requires = this.upiBankList[0].mobile
    }

    this.upiBankInformation = this.$route.query

    if (localStorage.getItem('upiBankInformation') != null) {
      console.log('---upiBankInformation---' + typeof localStorage.getItem('upiBankInformation'))
      this.upiBankInformation = JSON.parse(localStorage.getItem('upiBankInformation'))
      console.log('---upiBankInformation---' + localStorage.getItem('upiBankInformation'))
    }

    this.mobileToVerify = localStorage.getItem('mobileToVerify')
    this.timer = new Timer({
      maxCounter: 60,
    })
    this.vungoAid = localStorage.getItem('vg_aid')
    this.vungoUid = localStorage.getItem('vg_uid')
    this.isDisabled = true
    User.withdrawCheck()
      .then((data) => {
        console.log('withdrawCheck::', data)
        this.withdrawCheck = data.status
        this.canWithdrawAmount = data.allow_amount
        this.currentWithdraw = data.withdraw_status.currentWithdraw

        if (data.mobile && data.mobile.length == 12) {
          let mobile = data.mobile.substr(2)
          let e = document.createEvent('HTMLEvents')
          e.initEvent('input', true, true)
          let elemUpi = document.getElementById('upiMobile')
          let elemBank = document.getElementById('bankMobile')
          if (elemUpi) {
            elemUpi.value = mobile
            elemUpi.dispatchEvent(e)
            this.formatUpiMobile(e)
          }
          if (elemBank) {
            elemBank.value = mobile
            elemBank.dispatchEvent(e)
            this.formatBankMobile(e)
          }
        }

        this.determine()
      })
      .catch((err) => {
        console.log(err)
      })

    console.log('---balance in vuex---' + this.$store.state.balance)
    // this.withdrawAmount = this.$store.state.balance;
    // this.withdrawAmount = localStorage.getItem('balance');
    User.queryUpiAndBank().then((data) => {
      if (data) {
        console.log('---queryUpiAndBank---', data.data)
        this.upiBankList = data.data
        // console.log('---type---' + typeof data.data)
        // console.log('---this.upiBankList[0].userId---' + this.upiBankList[0].userId)
        // console.log('---this.upiBankList[0].upiAccountNumber---' + this.upiBankList[0].upiAccountNumber)
        // console.log('---this.upiBankList.length---' + this.upiBankList.length)
      }
    })

    User.info(this.vungoId, this.vungoUid, this.vungoAid, this.vungoChannelid, this.vungoPkg, this.vungoExtra)
      .then((data) => {
        let res = data.data
        console.log('---refresh user ---' + JSON.stringify(res))
        localStorage.setItem('userId', res.userId)
        this.withdrawAmount = res.realBalance
        this.showAmount = res.showBalance
        this.mobileToVerify = this.unFormatedVerifyMobile(res.mobile)
        this.pointCode = res.code
        this.pointPkg = res.pkg
        this.pointChannel = res.channelid
        this.pointPn = 'hy'
        this.pointPlatform = 'vungo'
        this.pointAid = res.aid
        this.pointGaid = res.gaid
        this.pointTaurusStatUuid = localStorage.getItem('taurus_stat_uuid')
        this.pointUid = res.uid
        this.rebpoint('tcpa_w/d_disp', '')
      })
      .catch((err) => {
        console.log('login error:', err)
      })

    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL)
      window.addEventListener('popstate', this.fun, false)
    }
    this.button = document.getElementById('button')
    this.canvas = document.getElementById('canvas')
    this.ctx = this.canvas.getContext('2d')
    this.canvas.width = window.innerWidth
    this.canvas.height = window.innerHeight
    this.cx = this.ctx.canvas.width / 2
    this.cy = this.ctx.canvas.height / 2

    window.addEventListener('resize', () => {
      this.resizeCanvas()
    })
    // this.initBurst()
    this.render()

  },
  deactivated() {
    this.timer.stop()
    window.removeEventListener('popstate', this.fun, true)
  },
}
</script>

<style scoped>
/* Non-nested CSS styles */

.withdraw-root {
  width: 100vw;
  height: 100%;
  padding: 0 15vw;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  background: #EDEDED;
}

.withdraw-title {
  border-bottom: #1890ff 1rem solid;
}

canvas {
  height: 100vh;
  pointer-events: none;
  position: fixed;
  width: 100%;
  z-index: 100;
}

.bank-empty {
  width: 100rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.bank-empty-pic {
  width: 26.8rem;
  height: 15.4rem;
}

.bank-empty-txt {
  width: 60rem;
  color: #6A6B6D;
  text-align: center;
  margin-top: 4rem;
  font-size: 2.8rem;
}

.page {
  background: #fff;
  min-height: 100%;
  height: 100%;
  width: 100%;
  font-family: 'PingFang SC';
  background-color: #EDEDED;
  background-size: 100%;
}

.flex-center {
  justify-content: center;
  align-self: center;
}

.flex {
  display: flex;
}

.flex-r {
  flex-direction: row;
}

.flex-c {
  flex-direction: column;
}

.flex-jc-sa {
  justify-content: space-around;
}

.flex-jc-sb {
  justify-content: space-between;
}

.flex-ac {
  align-items: center;
}

.head {
  background: #EDEDED;
  color: #fff;
  position: fixed;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 2.6rem;
  height: 10rem;
  z-index: 9;
  text-align: center;
  border-bottom: #eaeaea 0.5rem solid;
}

.head .title {
  margin-left: 6.0rem;
  line-height: 6.0rem;
  height: 6.0rem;
  font-size: 3.6rem;
  text-align: center;
  display: flex;
  align-items: center;
  color: #fff;
  position: absolute;
  left: 2.4rem;
}

.head .withdraw-dateil {
  position: absolute;
  display: flex;
  height: 10rem;
  line-height: 10.0rem;
  right: 15%;
  margin-right: 15%;
  color: #6A6B6D;
  font-size: 3.2rem;
  align-items: center;
  justify-content: center;
}

.head .withdraw-dateil:active {
  filter: brightness(0.5);
}

.head img {
  width: 4.2rem;
  height: 4.2rem;
}

.amount {
  display: flex;
  flex-direction: column;
  padding-bottom: 2.0rem;
  border-radius: 0.6rem;
  margin-top: 10rem;
  background: #367BE4 url('@/assets/withdraw/withdraw-bg.png') no-repeat;
  background-size: 100%;
}

.amount-top {
  position: relative;
  display: flex;
  margin-bottom: 2.0rem;
}

.amount-top-left {
  font-family: PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  margin-left: 5.0rem;
}

.amount-top-left div:nth-child(1) {
  font-size: 2.4rem;
  margin-top: 4.0rem;
  margin-bottom: 0rem;
}

.amount-top-left div:nth-child(2) {
  font-size: 4.2rem;
  font-family: PingFang SC;
  color: #FFFFFF;
}

.amount-top-left div:nth-child(2) span {
  font-weight: 600;
}

.amount-top-right {
  position: absolute;
  top: 2rem;
  right: 4.0rem;
}

.amount-top-right div:nth-child(1) {
  position: relative;
}

.amount-top-right div:nth-child(1) img {
  position: absolute;
  top: -2rem;
  right: 1.2rem;
  width: 18.0rem;
}

.amount-top-right div:nth-child(1) .amount-withdraw {
  position: absolute;
  top: 7.0rem;
  right: 0;
  width: 20.0rem;
  height: 5rem;
  background: #FFFFFF;
  border-radius: 1.2rem;
  font-size: 2.6rem;
  font-family: PingFang SC;
  font-weight: 600;
  color: #367BE4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.amount-top-right div:nth-child(1) .amount-withdraw:active {
  filter: grayscale(0.9);
}

.amount-bottom {
  width: 96%;
  height: 14.1rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 1.2rem;
  margin-right: 3.0rem;
  margin-left: 3rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
}

.amount-bottom .amount-bottom-line {
  width: 0.1rem;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  margin: 0 auto;
}

.amount-bottom .amount-bottom-content {
  position: relative;
  color: #FFFFFF;
  flex-basis: 50%;
  display: flex;
  padding: 7rem 4rem;
  flex-direction: column;
  justify-content: center;
  height: 8rem;
}

.amount-bottom .amount-bottom-content-tip {
  font-size: 2.2rem;
  color: white;
  font-family: PingFang SC;
  font-weight: 300;
}

.amount-bottom .amount-bottom-content-amount {
  align-items: center;
  font-size: 4.2rem;
  font-weight: 600;
  margin-top: 1rem;
}

.amount-bottom .amount-bottom-content-amount span {
  font-weight: 600;
}

.content .withdraw-method {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.withdraw-method-top {
  margin-left: 2rem;
  margin-bottom: 2rem;
  font-size: 2.8rem;
  font-family: PingFang SC;
  color: #777;
}

.withdraw-method-bottom {
  width: 100%;
  height: 14.0rem;
  background: #fff;
  box-shadow: 0px 0px 1.0rem .1rem rgba(0, 0, 0, 0.1);
  border-radius: 1.2rem;
  display: flex;
  align-items: center;
  padding-left: 2rem;
}

.content .total {
  padding: 0.1rem 0.2rem 0rem 0.1rem;
  font-family: PingFang SC;
  color: rgb(232, 156, 99);
  background-color: rgba(255, 114, 0, 0.1);
  border-radius: 1.2rem;
  margin-bottom: 2.5rem;
}

.content .total .total-text {
  padding: 1.0rem .0rem .3rem 1.0rem;
  font-size: 2.4rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #FF7200;
}

.content .total .total-text p {
  margin-bottom: 2.0rem;
}

.content .footer {
  height: 6rem;
  width: 1rem;
}

.content .guess .guess-top {
  margin-left: 2.0rem;
  margin-bottom: 2.0rem;
  font-size: 2.6rem;
  font-family: PingFang SC;
  color: #777;
}

.content .guess .guess-bottom {
  box-shadow: 0px 0px 1.0rem .1rem rgba(0, 0, 0, 0.1);
  background: #FFFFFF;
  border-radius: 1.2rem;
  display: flex;
  flex-direction: column;
}

.content .guess .guess-bottom .guess-line {
  width: 100%;
  height: .1rem;
  background: #CCCCCC;
  margin: 0 auto;
}

.content .guess .guess-bottom .collapse .collapse-show {
  font-size: 2.6rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #111111;
  position: relative;
  display: flex;
  padding: 3.0rem 0;
  line-height: 3.6rem;
}

.content .guess .guess-bottom .collapse .collapse-show .collapse-show-text {
  width: 56.0rem;
  margin-left: 3.0rem;
  font-size: 2.8rem;
  display: flex;
  align-items: center;
}

.content .guess .guess-bottom .collapse .collapse-show .el-icon {
  position: absolute;
  right: 2rem;
}

.content .guess .guess-bottom .collapse .collapse-hide {
  display: none;
}

.content .guess .guess-bottom .collapse .collapse-hide-text {
  height: 36.0rem;
  padding: 4.0rem;
  font-size: 2.6rem;
  font-family: PingFang SC;
  color: #666666;
}

.content .guess .guess-bottom .collapse .collapse-hide-text p {
  margin-top: 2rem;
}

.content .guess .guess-bottom .collapse .collapse-hide-text p span {
  font-size: 2.6rem;
  color: #111111;
  font-weight: 400;
}

.withdraw-method-bottom div:nth-child(1) {
  width: 7.2rem;
  height: 7.2rem;
}

.withdraw-method-bottom div:nth-child(2) {
  flex-basis: 88%;
  margin-left: 1.0rem;
  font-size: 2.8rem;
  font-family: PingFang SC;
  color: #6A6B6D;
}

.withdraw-method-bottom div:nth-child(2) span {
  font-weight: 600;
  font-size: 2.8rem;
  font-family: PingFang SC;
  color: #3C3C3C;
}

.withdraw-method-bottom div:nth-child(3) {
  flex-basis: 10%;
  margin-left: 2rem;
}

.withdraw-method-bottom div:nth-child(3) img {
  width: 2.4rem;
  height: 2.4rem;
}

.withdraw-method-bottom:active {
  filter: brightness(0.5);
}

.total {
  padding: 0.1rem 0.2rem 0rem 0.1rem;
  font-family: PingFang SC;
  color: rgb(232, 156, 99);
  background-color: rgba(255, 114, 0, 0.1);
  border-radius: 1.2rem;
  margin-bottom: 2.5rem;
}

.total .total-text {
  padding: 1.0rem 0rem 0.3rem 1.0rem;
  font-size: 2.4rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #FF7200;
}

.total .total-text p {
  margin-bottom: 2.0rem;
}

.footer {
  height: 6rem;
  width: 1rem;
}

.guess .guess-top {
  margin-left: 2.0rem;
  margin-bottom: 2.0rem;
  font-size: 2.6rem;
  font-family: PingFang SC;
  color: #777;
}

.guess .guess-bottom {
  box-shadow: 0px 0px 1.0rem 0.1rem rgba(0, 0, 0, 0.1);
  background: #FFFFFF;
  border-radius: 1.2rem;
  display: flex;
  flex-direction: column;
}

.guess .guess-bottom .guess-line {
  width: 100%;
  height: 0.1rem;
  background: #CCCCCC;
  margin: 0 auto;
}

.guess .guess-bottom .collapse .collapse-show {
  font-size: 2.6rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #111111;
  position: relative;
  display: flex;
  padding: 3.0rem 0;
  line-height: 3.6rem;
}

.guess .guess-bottom .collapse .collapse-show .collapse-show-text {
  width: 56.0rem;
  margin-left: 3.0rem;
  font-size: 2.8rem;
  display: flex;
  align-items: center;
}

.guess .guess-bottom .collapse .collapse-show .el-icon {
  position: absolute;
  right: 4.0rem;
  top: 3.6rem;
}

.guess .guess-bottom .collapse .collapse-show .el-icon img {
  width: 2.9rem;
  height: 2.9rem;
}

.guess .guess-bottom .collapse .guess-bottom-content-text {
  background: #F5F5F5;
  font-size: 2.6rem;
  margin: 0 1.0rem;
  padding: 2.0rem;
  line-height: 4.1rem;
}

.guess .guess-bottom .collapse:last-child .el-icon {
  top: 5.4rem;
}

.withdraw-modal {
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.35);
  backdrop-filter: blur(4px);
  box-shadow: 0px 3px 13px 1px rgba(51, 6, 82, 0.2);
  border-radius: 0.6rem;
}

.withdraw-modal .withdraw-pos {
  position: fixed;
  bottom: 0;
  width: 100rem;
  height: 58rem;
  background: #FFFFFF;
  border-radius: 2.4rem 2.4rem 0px 0px;
}

.withdraw-modal .withdraw-pos .withdraw-content {
  position: relative;
  display: block;
}

.withdraw-modal .withdraw-pos .withdraw-content .close-img {
  position: absolute;
  width: 4rem;
  height: 4rem;
  padding: 1.5rem;
  top: 3.0rem;
  right: 5.0rem;
  background-image: url("@/assets/withdraw/close.png");
  background-size: 100% 100%;
}

.withdraw-modal .withdraw-pos .withdraw-content .close-img img {
  width: 3.0rem;
}

.withdraw-modal .withdraw-pos .withdraw-content .withdraw-amount {
  padding-top: 4.0rem;
  text-align: center;
  padding-bottom: 1.0rem;
}

.withdraw-modal .withdraw-pos .withdraw-content .withdraw-amount div:nth-child(1) {
  font-size: 2.5rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #111111;
  padding-bottom: 1.0rem;
}

.withdraw-modal .withdraw-pos .withdraw-content .withdraw-amount div:nth-child(2) {
  font-size: 5.0rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #1B1B1B;
}

.withdraw-modal .withdraw-pos .withdraw-content .withdraw-amount div:nth-child(2) span {
  font-size: 10.0rem;
  font-weight: 600;
}

.withdraw-modal .withdraw-pos .withdraw-content .withdraw-method {
  border-top: 0.1rem solid #ccc;
  border-bottom: 0.1rem solid #ccc;
  padding: 1.0rem 0;
  position: relative;
  height: 8.0rem;
  font-size: 2.6rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #777777;
}

.withdraw-modal .withdraw-pos .withdraw-content .withdraw-method div:nth-child(1) {
  position: absolute;
  left: 4.0rem;
  line-height: 6.0rem;
}

.withdraw-modal .withdraw-pos .withdraw-content .withdraw-method div:nth-child(2) {
  position: absolute;
  right: 4.0rem;
  line-height: 6.0rem;
}

.withdraw-modal .withdraw-pos .withdraw-content .withdraw-bottom {
  padding-top: 1.5rem;
  position: relative;
  display: flex;
}

.withdraw-modal .withdraw-pos .withdraw-content .withdraw-bottom .withdraw-money {
  width: 54.7rem;
  height: 22.8rem;
  position: relative;
  margin-left: 22rem;
  background-image: url("@/assets/withdraw/withdraw-money.png");
  background-size: cover;
}

.withdraw-modal .withdraw-pos .withdraw-content .withdraw-bottom .amount-withdraw {
  position: absolute;
  width: 92rem;
  height: 9.4rem;
  margin-left: 2rem;
  background: #2675F6;
  color: white;
  font-size: 3.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.6rem;
  bottom: 0.3rem;
}

.withdraw-modal .withdraw-pos .withdraw-content .withdraw-bottom .amount-withdraw:active {
  filter: grayscale(0.5);
}

.drawer-contain {
  width: 100rem;
  position: relative;
  display: flex;
  justify-items: center;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.drawer {
  width: 100rem;
}

.first-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 17.8rem;
  margin-right: 1.5rem;
  margin-left: 1.5rem;
}


.second-content-card {
  height: 8.4rem;
  padding: 0.1rem 0.1rem;
  font-family: PingFang SC;
  width: 100vw;
  border-radius: 1.2rem;
  margin-bottom: 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

}

.second-content-card-upi {
  font-size: 3.8rem;
  font-family: PingFangSC;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: #000000;
}

.second-content-card-upi-id {
  font-size: 2.6rem;
  font-family: PingFangSC;
  font-weight: 400;
  color: #8b8b8b;
  max-width: 48.0rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}


.img1 {
  margin-top: 2rem;
  width: 8rem;
  height: 6rem;
}

.img2 {
  width: 4.0rem;
  height: 4.0rem;
  margin-right: 3rem;
  margin-left: auto;
}

.second-content-card-text {

  display: flex;
  padding-left: 3rem;
  flex-direction: column;
  align-items: flex-start; /* 文本对齐方式 */
  justify-content: center; /* 上下居中对齐 */
}


.withdraw-before-upi {
  width: 100%;
  height: 10rem;
  display: flex;
  flex-direction: row;
}

.withdraw-before-upi-img {
  width: 9.5rem;
  height: 9.5rem;
  padding: 2.2rem 1.5rem;
}

.upi-second-container {
  margin-left: 2rem;
  height: 15.6rem;
}

.upi-second-container-another {
  margin: .0rem;
  margin-right: 10.5rem;
}

.upi-detail-container {
  display: flex;
  flex-direction: row;
  width: 48.0rem;
  margin-top: 1.0rem;
}

.adding-container {
  height: 100%;
  display: flex;
  align-items: center;

  justify-content: space-between;
  margin-top: 0;

}

.upi-text {
  font-size: 3rem;
  font-family: PingFangSC;
  font-weight: 400;
  color: #1a1a1a;
  margin-right: 1.4rem;
}

.withdraw-before-modify-img {
  width: 3rem;
  height: 3rem;
  margin-left: 16rem;
  margin-top: 2rem;
}

.upi-number {
  font-size: 2.4rem;
  font-family: PingFangSC;
  font-weight: 400;
  color: #8b8b8b;
  margin-top: 0.2rem;
}

.add-bank {
  display: flex;
  flex-direction: row;
  width: 99rem;
  margin-left: -4rem;
  justify-content: center;
  height: 10rem;
  background: white;
}

.add-bank-txt {
  display: flex;
  justify-content: center;
}

.add-arrow {
  width: 4rem;
  height: 4rem;
  margin-left: -15rem;
}

.upi-row-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  height: 10rem;
}


.upi-row {
  width: 100rem;
  height: .1rem;
  background: #e6e6e6;
}

.withdraw-access-img {
  width: 4.8rem;
  height: 4.8rem;
  margin-top: 1.0rem;
}

.withdraw-before-jump {
  margin-top: 6.0rem;
  margin-left: 3.0rem;
}

.withdraw-before-detemine {
  position: fixed;
  bottom: 0;
  z-index: 99;
  height: 2.1rem;
  display: flex;
  align-items: center;

}

.withdraw-before-detemine-line {
  position: absolute;
  top: .0rem;
  width: 65.0rem;
  height: .1rem;
  background: #e6e6e6;
  margin-left: 5.0rem;
}

.withdraw-before-detemine-line-2 {
  position: absolute;
  bottom: 2.0rem;
  width: 65.0rem;
  height: .1rem;
  background: #e6e6e6;

}

.withdraw-before-detemine-button-container {
  height: 100%;
  width: 75.0rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.withdraw-before-detemine-button {
  width: 36.4rem;
  height: 8.8rem;
  background: linear-gradient(90deg, #256dd0, #378ded);
  box-shadow: 0px .3rem .4rem 0px rgba(74, 5, 0, 0.22);
  border-radius: 4.4rem;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 3.6rem;
  font-family: PingFangSC;
  font-weight: 600;
  color: #ffffff;
}

.withdrawAccessImg {
  display: none !important;
}

.verify-modal {
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  padding: 3.0rem 0;
  background: rgba(0, 0, 0, 0.35);
  overflow: auto;
  backdrop-filter: blur(4px);
  box-shadow: 0px 3px 13px 1px rgba(51, 6, 82, 0.2);
  border-radius: .6rem;
}

.verify-modal .content {
  width: 90%;
  background-color: #fff;
}

.verify-modal .modal-title {
  width: 62.0rem;
  height: 10.4rem;
  background-image: url('~@/assets/goods_header.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.verify-modal .title {
  margin-left: 3.0rem;
  font-size: 3.6rem;
  font-family: PingFang SC;
  font-weight: 600;
  color: #222222;
  background: linear-gradient(0deg, #256dd0 0%, #378ded 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.verify-modal .close-icon {
  width: 5.0rem;
  height: 5.0rem;
  margin-right: 1.6rem;
}

.verify-modal .close-icon img {
  width: 100%;
  height: 100%;
}

.verify-modal .form {
  padding: 1.0rem 1.4rem;
}

.verify-modal .row {
  margin-top: 4.0rem;
}

.verify-modal .text {
  padding: 0 1.2rem;
  color: #222;
}

.verify-modal .input-w {
  box-sizing: border-box;
  height: 8.6rem;
  font-size: 3.6rem;
  padding: 1.8rem 2.2rem;
  border-radius: .6rem;
  border: 1px solid #ccc;
  align-self: center;
}

.verify-modal .input-w-mobile {
  width: 58.0rem;
}

.verify-modal .input-w-verify {
  width: 42.5rem;
}

.verify-modal .input-label-icon {
  margin-right: 2.0rem;
  width: 5.0rem;
  height: 5.0rem;
  background-size: contain;
  background-repeat: no-repeat;
}

.verify-modal .input-label-icon-mobile {
  background-image: url('~@/assets/mobile-icon.png');
}

.verify-modal .input-label-icon-verify {
  background-image: url('~@/assets/verify-icon.png');
}

.verify-modal .input-label-text {
  margin-right: 1.0rem;
}

.verify-modal .input {
  padding-left: 1.0rem;
  font-size: 3.4rem;
  font-weight: bold;
  border: none;
  outline: none;
}

.verify-modal .input::placeholder {
  font-size: 2.6rem;
  color: #ccc;
}

.verify-modal .input-mobile {
  width: 36.0rem;
}

.verify-modal .input-verify {
  width: 6.0rem;
}

.verify-modal .send-btn:active {
  background: rgba(71, 135, 237, 0.5);
}

.verify-modal .btn-w {
  padding: 5.0rem 0 3.0rem;
}

.verify-modal .btn-transfer {
  width: 53.0rem;
  height: 8.4rem;
  line-height: 8.4rem;
  padding: 0;
  background: linear-gradient(45deg, #256dd0, #378ded);
  border-radius: 4.2rem;
  text-align: center;
  font-size: 3.6rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
}

.OTP-content {
  width: 90rem;
  height: 76%;
  max-width: 99rem;
  background-color: #fff;
  border-radius: 2rem;
}

.OTP-content .OTP-container {
  height: 8rem;
  display: flex;
  padding: 0 4rem;
  justify-content: space-between;
  align-items: center;
}

.OTP-content .OTP-title {
  font-size: 2.4rem;
  font-weight: bolder;
  font-family: PingFang SC;
  color: #1B1B1B;
}

.OTP-content .opt-close {
  width: 4rem;
  height: 4rem;
  padding: 1rem;
}

.OTP-content .OTP-form {
  padding: .0rem;
  max-width: 99rem;
}

.OTP-content .new-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 6.0rem;
  margin-left: 5%;
  margin-right: 5%;
}

.OTP-content .disabled {
  pointer-events: none;
  cursor: default;
  width: 94rem;
  height: 9.4rem;
  border-radius: 1.0rem;
  border: .2rem solid #EDEDED;
  align-items: center;
  justify-content: center;
  line-height: 7.1rem;
  font-size: 3.2rem;
  display: flex;
  background-color: #ccc;
  color: #fff;
}

.OTP-content .cancel {
  width: 27.9rem;
  height: 7.5rem;
  border: .2rem solid #cccccc;
  border-radius: 1.0rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3.3rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #787878;
  line-height: 7.1rem;
}

.OTP-content .cancel:active {
  background: rgba(71, 135, 237, 0.5);
}

.OTP-content .confirm {
  width: 94rem;
  height: 9.4rem;
  border: .2rem solid #cccccc;
  border-radius: 1.0rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #256dd0, #378ded);
  border-radius: 1.0rem;
  font-size: 3.2rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffffff;
  line-height: 7.1rem;
}

.OTP-content .confirm:active {
  background: rgba(71, 135, 237, 0.5);
}

.OTP-content .row {
  margin-top: 0px !important;
  margin-bottom: 2.0rem !important;
}

.OTP-content .text {
  padding: 0 1.2rem;
  color: #222;
}

.OTP-content .input-w {
  box-sizing: border-box;
  height: 8.6rem;
  font-size: 3.6rem;
  padding: 1.8rem 2.2rem;
  border-radius: .6rem;
  border: 1px solid #ccc;
}

.OTP-content .input-w-mobile {
  width: 90%;
}

.OTP-content .input-w-verify {
  margin-left: 5%;
  margin-top: 2rem;
  width: 63rem;
}

.OTP-content .input-label-icon {
  margin-right: 2.0rem;
  width: 5.0rem;
  height: 5.0rem;
  background-size: contain;
  background-repeat: no-repeat;
}

.OTP-content .input-label-icon-mobile {
  background-image: url('@/assets/mobile-icon.png');
}

.OTP-content .input-label-icon-verify {
  background-image: url('@/assets/verify-icon.png');
}

.OTP-content .input-label-text {
  margin-right: 1.0rem;
}

.OTP-content .input {
  padding-left: 1.0rem;
  font-size: 3.6rem;
  font-weight: bold;
  border: none;
  outline: none;
}

.OTP-content .input::placeholder {
  font-size: 2.6rem;
  color: #ccc;
}

.OTP-content .input-mobile {
  width: 36.0rem;
}

.OTP-content .input-verify {
  width: 30.0rem;
}

.OTP-content .send-btn {
  width: 24rem;
  height: 8.6rem;
  margin-top: 2rem;
  text-align: center;
  line-height: 8.6rem;
  margin-left: 1.5rem;
  margin-right: 4rem;
  border-radius: 0.6rem;
  background-color: rgba(255, 146, 1, 0.2);
  font-size: 3.2rem;
  color: #ff8401;
}

.OTP-content .btn-w {
  padding: 0;
}

.OTP-content .btn-transfer {
  width: 53.0rem;
  height: 8.4rem;
  line-height: 8.4rem;
  padding: 0;
  background: linear-gradient(45deg, #256dd0, #378ded);
  border-radius: 4.2rem;
  text-align: center;
  font-size: 3.6rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
}

.upi-bank-modal {
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  padding: 3.0rem 0;
  background: rgba(0, 0, 0, 0.35);
  overflow: auto;
  backdrop-filter: blur(4px);
  box-shadow: 0px 3px 13px 1px rgba(51, 6, 82, 0.2);
  border-radius: .6rem;
}

.upi-bank-modal .content {
  width: 62.0rem;
  background-color: #fff;
  padding: 0;
}

.upi-bank-modal .modal-title {
  width: 62.0rem;
  height: 10.4rem;
  background-image: url('~@/assets/goods_header.png');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3.6rem;
  font-family: PingFang SC;
  font-weight: 600;
  color: #222222;
}

.upi-bank-modal .title {
  margin-left: 3.0rem;
  font-size: 3.6rem;
  font-family: PingFang SC;
  font-weight: 600;
  color: #222222;
  background: linear-gradient(0deg, #256dd0 0%, #378ded 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.upi-bank-modal .close-icon {
  width: 5.0rem;
  height: 5.0rem;
  margin-right: 1.6rem;
}

.upi-bank-modal .close-icon img {
  width: 100%;
  height: 100%;
}

.upi-bank-modal .modal-body {
  padding: 4.0rem 2.0rem 2.0rem;
  font-size: 3.2rem;
  line-height: 4.0rem;
  font-weight: 400;
  color: #333;
}

.upi-bank-modal .modal-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 10.0rem;
  margin: 5.0rem 0;
}

.bank-bottom {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  border-top: #eaeaea 0.2rem solid;
}

.withdraw-before-method {
  margin-top: 0rem;
}

.withdraw-before-method-another {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.withdraw-before-method-another:active {
  background: rgba(71, 135, 237, 0.5);
}

.withdraw-before-method:active {
  background: rgba(71, 135, 237, 0.5);
}
</style>
