<template>
  <div id="header">
    <img id="back" src="../../assets/back_white.png" @click="back">
    <span style="flex: 1"></span>
    <p class="btn_login" @click="login" v-if="!this.$store.state.token">Sign in/ Sign up</p>
    <div class="btn_user" v-if="this.$store.state.token">
      <img src="@/assets/header_default.png"/>
      <p>{{ indexData ? indexData.data.username : '' }}</p>
    </div>

    <div id="info_cash">
      <img src="../../assets/coins.png">
      <p id="btn_amount">₹ {{ indexData ? indexData.data.showBalance.toFixed(2) : '' }}</p>
      <div id="btn_withdraw_contain" @click="withdraw">
        <p id="btn_withdraw">Withdraw</p>
      </div>
    </div>
    <img id="btn_level_icon" src="../../assets/level_rule_icon.png" @click="showLevelDialog">
    <img id="btn_service" src="../../assets/servicers.png" @click="goServiceWeb">
  </div>
</template>
<script>

import http from '@/utils/http'
import { mapState } from 'vuex'
import bus from 'vue3-eventbus'

export default {
  props: {
    indexData: Object
  },
  computed: {
    ...mapState(['pn', 'channel', 'pkg']),
    showBalance () {
      if (!this.$props.indexData) {
        return ''
      } else {
        const jsonObj = JSON.parse(this.$props.indexData)
        return jsonObj.data.showBalance.toFixed(2)
      }
    }
  },
  methods: {
    back () {
      window.parent.postMessage(JSON.stringify({
        action: 'close',
        data: {
          title: 'close',
          iframeId: 'share-iframe'
        }
      }), '*')
    },
    login () {
      this.$store.commit('showLogin', true)
    },
    goServiceWeb () {
      this.$router.push({
        path: '/web',
        query: { url: http.baseH5Url + '/customer_h5?pn=' + this.pn + '&channel=' + this.channel + '&pkg=' + this.pkg },
        param: { url: http.baseH5Url + '/customer_h5?pn=' + this.pn + '&channel=' + this.channel + '&pkg=' + this.pkg }
      })
    },
    showLevelDialog () {
      bus.emit('showLevelDialog', true)
    },
    withdraw () {
      if (!this.$store.state.token) {
        this.$router.push('/main/my_cash')
        return
      }
      if (window.matchMedia('(orientation: portrait)').matches) {
        this.$router.push({
          path: '/web',
          query: { url: http.baseH5Url + '/withdraw?pn=' + this.pn + '&channel=' + this.channel + '&pkg=' + this.pkg + '&token=' + this.$store.state.token },
          param: { url: http.baseH5Url + '/withdraw?pn=' + this.pn + '&channel=' + this.channel + '&pkg=' + this.pkg + '&token=' + this.$store.state.token }
        })
      } else if (window.matchMedia('(orientation: landscape)').matches) {
        this.$router.push({
          path: '/withdraw'
        })
      }
    }
  }
}
</script>

<style scoped>

#header {
  width: 100%;
  height: 8.3rem;
  display: flex;
  align-content: center;
  justify-content: center;
}

#header > :first-child {
  width: 5.7rem;
  height: 5.1rem;
  align-self: center;
}

#btn_level_icon {
  width: 5.3rem;
  height: 5.3rem;
  align-self: center;
  margin-left: 3rem;
  margin-right: 2rem;
}
#btn_level_icon:active {
  opacity: 0.6;
}
#btn_service {
  width: 5.3rem;
  height: 5.3rem;
  align-self: center;
  margin-right: 3rem;
}

#btn_service:active {
  opacity: 0.6;
}

.btn_login {
  display: flex;
  justify-self: center;
  justify-content: center;
  align-self: center;
  height: 4.7rem;
  line-height: 4.2rem;
  width: 22rem;
  border-radius: 0.25rem;
  background: linear-gradient(0deg, #D59A3A 0, #FBEB6E 30%, #FBEB6E 100%);
  color: #823519;
  font-size: 2.2rem;
  font-weight: 700;
}

.btn_login:active {
  opacity: 0.6;
}

.btn_user {
  width: 23.9rem;
  height: 4.8rem;
  display: flex;
  justify-self: center;
  align-content: center;
  justify-content: center;
  align-self: center;
  position: relative;
  background: #1C0F0E;
  border-radius: 2.4rem;
  border: 0.1rem solid #D59A3A;
}

.btn_user > img {
  width: 6.7rem;
  height: 6.7rem;
  align-self: center;
  left: -1rem;
  top: auto;
  position: absolute;
}

.btn_user > p {
  font-size: 2.5rem;
  color: white;
  align-self: center
}

#info_cash {
  align-self: center;
  height: 4.8rem;
  display: flex;
  background: #1C0F0E;
  margin: 0 1.25rem 0 5rem;
  box-sizing: border-box;
  border-radius: 2rem;
  border: 0.1rem solid #D59A3A;
  align-content: center;
  justify-content: center;
}

#info_cash > :first-child {
  margin-left: -4.2rem;
  height: 8.3rem;
  width: 8.4rem;
  align-self: center;
}

#btn_amount {
  color: #FBEB6E;
  font-size: 2.2rem;
  line-height: 4.6rem;
  margin-right: 1.6rem;
  font-weight: bolder;

}

#btn_withdraw_contain {
  width: 15.8rem;
  height: 5rem;
  align-self: center;
  display: flex;
  align-content: center;
  justify-content: center;
  background-image: url("../../assets/btn_withdraw_bg.png");
  background-position: right;
  background-size: cover;

}

#btn_withdraw_contain:active {
  opacity: 0.6;
}

#btn_withdraw_contain > p {
  color: white;
  align-self: center;
  line-height: 4.6rem;
  font-weight: bolder;
  font-size: 2.2rem;
}

</style>
