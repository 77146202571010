import axios from 'axios'
import store from '@/store'

const bsaeUrl = 'https://h5-api.agent61.com/awclient/landscape'
// const bsaeUrl = 'https://test-api.agent61.com/awclient/landscape'
const baseH5Url = 'https://h5-landscape.agent61.com'

const withdrawUrl = ''
const http = axios.create({
  baseURL: bsaeUrl,
  timeout: 20_000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

function postHttp (url, data) {
  return http.post(url, data).then((data) => {
    return data.data
  }).catch((err) => {
    console.log('error:' + err)
  })
}

function getHttp (url) {
  return http.get(url).then((data) => {
    return data.data
  }).catch((err) => {
    console.log('error:' + err)
  })
}

http.interceptors.request.use(function (config) {
  config.headers.token = store.state.token
  return config
})


export default {
  postHttp,
  getHttp,
  baseH5Url
}
