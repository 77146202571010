<script>
import http from '@/utils/http'
import toast from '@/utils/toast'
import { mapState } from 'vuex'

export default {
  props: {
    title: String
  },
  computed: {
    ...mapState(['pn', 'aid', 'pkg', 'uid', 'channel', 'code', 'token', 'appConfig'])
  },
  methods: {
    back () {
      this.$router.go(-1)
    },
    goServiceWeb () {
      this.$router.push({
        path: '/web',
        query: { url: http.baseH5Url + '/customer?pn=' + this.pn + '&channel=' + this.channel + '&pkg=' + this.pkg },
        param: { url: http.baseH5Url + '/customer?pn=' + this.pn + '&channel=' + this.channel + '&pkg=' + this.pkg }
      })
    },
    requestUserData () {
      const requestBody = {
        uid: this.uid,
        aid: this.aid,
        channel: this.channel,
        pkg: this.pkg,
        pn: this.pn,
        code: this.code,
        token: this.token
      }
      http.postHttp('customizedCarousel/queryUserInfo', requestBody).then((resp) => {
        if (resp.code !== 0) {
          toast.show(resp.msg)
          return
        }
        this.user = resp.data
      })
    }
  },
  mounted () {
    // console.log(this.$store.state.user)
    if (this.$store.state.user) {
      this.user = this.$store.state.user
    } else {
      this.requestUserData()
    }
  },
  data () {
    return {
      user: {},
      levelIcon: [
        require('@/assets/Bronze_border.png'), require('@/assets/Bronze_border.png'),
        require('@/assets/Silver_border.png'), require('@/assets/Gold_border.png'),
        require('@/assets/Platinum_border.png'), require('@/assets/Diamond_border.png'),
        require('@/assets/King_border.png')],
      levelName: ['Bronze', 'Bronze', 'Silver', 'Gold', 'Platinum_', 'Diamond', 'King']
    }
  }
}
</script>

<template>

  <div class="title">
    <div class="title-left">
      <div class="title-back" @click="back"></div>
      <p>{{ title }}</p>
    </div>

    <div class="title-right">
      <div class="level-img" :style="{ 'background-image': 'url(' + levelIcon[this.user.levels] + ')' }">
        <p>{{ user.levelEnDesc ? user.levelEnDesc : levelName[user.levels] }}</p>
      </div>
      <div id="info_cash">
        <img src="@/assets/coins.png">
        <p id="btn_amount">₹ {{ user.showBalance ? user.showBalance.toFixed(2) : '' }}</p>
      </div>

      <img id="btn_service" src="@/assets/servicers.png" @click="goServiceWeb">
    </div>

  </div>
</template>

<style scoped>
.title {
  width: 128rem;
  height: 8.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-left {
  display: flex;
  height: 100%;
  align-items: center;
}

.title-back {
  width: 5.7rem;
  height: 5.1rem;
  background-image: url("@/assets/back_white.png");
  background-size: 100% 100%;
}

.title-back:active {
  opacity: 0.6;
}

.title-left > p {
  font-size: 3.4rem;
  color: white;
  margin-left: 2rem;
  font-weight: 400;
}

.title-right {
  height: 100%;
  display: flex;
  align-items: center;
}

.level-img {
  width: 21.1rem;
  height: 7.4rem;
  background-image: url("@/assets/Gold_border.png");
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.level-img > p {
  color: white;
  font-size: 2.2rem;
  font-weight: 400;
  padding-left: 3rem;
}

#info_cash {
  align-self: center;
  height: 4rem;
  display: flex;
  background: #1C0F0E;
  margin: 0 1.25rem 0 5rem;
  box-sizing: border-box;
  border-radius: 2.4rem;
  border: 0.1rem solid #D59A3A;
  align-content: center;
  justify-content: center;
}

#info_cash > :first-child {
  margin-left: -4.2rem;
  height: 8.3rem;
  width: 8.4rem;
  align-self: center;
}

#btn_amount {
  color: #FBEB6E;
  font-size: 2.2rem;
  line-height: 4rem;
  margin-right: 1.6rem;
  font-weight: bolder;
}

#btn_service {
  width: 5.3rem;
  height: 5.3rem;
  align-self: center;
}

#btn_service:active {
  opacity: 0.6;
}
</style>
