import axios from 'axios'
import Cookie from '@/utils/cookie'
import store from '@/store'

// create an axios instance
const http = axios.create({
  // baseURL: production ? 'http://2.server:18201/awclient' : 'http://2.server:18201/awclient', // api base_url
  // baseURL: 'http://2.server:18201/awclient',
  baseURL: 'https://landscape-api.taurus.cash/awclient',
  // baseURL: 'https://test-api.agent61.com/awclient',
  // baseURL: 'https://landscape-api.agent61.com/awclient',
  // baseURL: production ? 'http://192.168.1.209:18201/awclient' : 'http://192.168.1.209:18201/awclient',
  timeout: 30000, // request timeout
  responseType: 'json',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
    // "Host": "api.tcvg.pw",
    // "Origin": "api.tcvg.pw",
  }
})

http.interceptors.request.use(config => {
  let token = store.state.token
  if (token) {
    config.headers['token'] = token
  }

  return config
})

export default http
