<template>
  <div class="landscape-mode">
    <div id="main_contain">

      <div id="main_content">
        <MainHeader :index-data="indexData"/>
        <MainContent :tab-data="tabData"/>
        <div v-if="$store.state.showLogin" class="dialog_contain">
          <LoginDialog @event="dismissLogin"/>
        </div>
        <div v-if="taskDialog" class="dialog_contain">
          <TaskDialog @task="dismissTask"/>
        </div>
        <div v-if="levelDialog" class="dialog_contain">
          <LevelDialog @event="dismissTask"/>
        </div>
        <div class="pre_web">
          <iframe ref="iframe" :src="serviceurl"></iframe>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import MainHeader from '@/components/main/MainHeader.vue'
import MainContent from '@/components/main/MainContent.vue'

import http from '@/utils/http'
import LoginDialog from '@/components/login/LoginDialog.vue'
import TaskDialog from '@/components/dialog/TaskDialog.vue'
import bus from 'vue3-eventbus'
import { mapState } from 'vuex'
import LevelDialog from '@/components/dialog/LevelDialog.vue'

export default {
  components: {
    LevelDialog,
    TaskDialog,
    LoginDialog,
    MainHeader,
    MainContent
  },
  data () {
    return {
      tabData: {},
      indexData: '',
      serviceurl: '',
      taskDialog: false,
      levelDialog: false
    }
  },
  computed: {
    ...mapState(['pn', 'aid', 'pkg', 'uid', 'channel', 'code'])
  },
  created () {
    this.$store.commit('code', localStorage.getItem('code'), '')
    bus.on('main_task', (show) => {
      this.taskDialog = show
    })
    bus.on('login_action', () => {
      this.requestIndexData()
    })
    bus.on('refresh', () => {
      this.requestIndexData()
    })
    bus.on('showLevelDialog', (show) => {
      try {
        this.levelDialog = show
      } catch (e) {
        console.log(e)
      }
    })
  },
  methods: {

    dismissLogin () {
      this.showLogin = false
    },
    dismissTask () {
      this.taskDialog = false
    },
    requestIndexData () {
      http.getHttp(`/h5/vgi/index?uid=${this.uid}&aid=${this.aid}&channelid=${this.channel}&pkg=${this.pkg}&pn=${this.pn}`)
        .then((resp) => {
          if (resp.code !== 0) {
            return
          }
          this.indexData = resp
          this.$store.commit('user', resp.data)
          this.$store.commit('finish_task_count', resp.finish_task_count)
          this.$store.commit('linkUrl', resp.data.url)
          this.$store.commit('mobile', resp.data.mobile)
          this.$store.commit('code', resp.data.code)
          if (resp.token) {
            this.$store.commit('token', resp.token)
          }
          this.requestMenuData()
          this.requestShareData()

          if (resp.data.balance > 20 && !resp.token) {
            this.$store.commit('showLogin', true)
            this.$store.commit('forceLogin', true)
          } else {
            this.$store.commit('forceLogin', false)
          }
        })
    },
    requestMenuData () {
      const requestBody = {
        pn: this.pn,
        aid: this.aid,
        pkg: this.pkg,
        uid: this.uid,
        code: this.code,
        channel: this.channel
      }
      http.postHttp('/h5/customizedcustommenu/queryMenuList', JSON.stringify(requestBody))
        .then((resp) => {
          this.tabData = resp
          this.$store.commit('tabData', resp)
        })
    },
    requestShareData () {
      const code = this.code
      const pn = this.pn
      http.getHttp('/promotion/distribute/a/' + code + '?pn=' + pn)
        .then((resp) => {
          if (resp.code !== 0) {
            return
          }
          this.$store.commit('shareData', resp.data)
        })
    }
  },
  mounted () {
    this.serviceurl=http.baseH5Url + '/customer?pn=' + this.pn + '&channel=' + this.channel + '&pkg=' + this.pkg
    const tabData = this.$store.state.tabData
    if (tabData) {
      this.tabData = tabData
    }
    setTimeout(() => {
      this.requestIndexData()
    }, 200)
  }

}

</script>
<style>
.dialog_contain {
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  left: 0;
  z-index: 998;
  top: 0;
  justify-content: center;
}

@media screen and (orientation: portrait) {
  .landscape-mode {
    height: 100vw;
    width: 94vh;
    transform: rotate(90deg);
    transform-origin: 0 0;
    top: 0;
    left: 100%;
    position: absolute;
    display: flex;
    align-content: center;
    justify-content: center;
  }

  #main_contain {
    width: 168vw;
    height: 94vw;
    display: flex;
    align-items: center;
    align-self: center;
    justify-items: center;
    align-content: center;
    justify-content: center;
  }

  #main_content {
    height: 100%;
    width: 100%;
    align-self: center;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (orientation: landscape) {
  .landscape-mode {
    width: 100%;
    height: 100%;
  }
}
.pre_web{
  width: 1px;
  height: 1px;
  overflow: hidden;
}

</style>
