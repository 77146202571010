<template>
  <div class="withdraw-detail">
    <!-- <loading :loading="loading" /> -->
    <div class="head-contain">
      <div class="head">
        <exit-button-home :page="'withdraw detail'"></exit-button-home>
        <div class="title">{{ 'Back' }}</div>
      </div>
    </div>
    <vue-loadmore :on-refresh="onRefresh" :on-loadmore="onLoad" :finished="finished" finished-text="No More"
                  pulling-text="Pulling" loosing-text="Loosing" loading-text="Loading" success-text="Loading Success"
                  error-text="Error" class="content">
      <div>
        <div v-if="!isNull">
          <div class="all">
            {{ 'All' }}: <span class="allvalue">{{ all }}</span>
          </div>

          <div class="card" v-for="(item, index) in dataList" :key="index">
            <div class="top">
              <div class="left" :class="getStatus(item.status).c">{{ getStatus(item.status).text }}</div>
              <div class="right">{{ item.created }}</div>
            </div>
            <div class="bottom">
              <div class="lf" v-if="item.ifsc && item.ifsc!='null'">Bank</div>
              <div class="lf" v-else>UPI</div>
              <div class="rg">
                <div>- ₹ {{ toThousandFilter(item.amount) }}</div>
                <div class="showBalance" v-if="tiemJude(item.created,item.showBalance)">Balance&nbsp;&nbsp;₹
                  {{ toThousandFilter(item.showBalance) }}
                </div>
              </div>
            </div>
            <hr class="divder"/>
            <div class="text">
              <div class="value">
                <div class="t">Withdraw ID：</div>
                <div class="v">
                  <span>{{ item.withdrawId }}</span>
                  <span v-if="item.withdrawId" class="step-1-copy" @click="onCopy(item.withdrawId)"></span>
                </div>
              </div>

              <div class="value">
                <div class="t">UTR：</div>
                <div class="v">{{ item.utr }}</div>
              </div>
              <div class="value">
                <div class="t">Account：</div>
                <div class="v" v-if="item.ifsc && item.ifsc!='null'">{{ item.bankAccount }}</div>
                <div class="v" v-else>{{ item.mobile }}</div>
              </div>
              <div class="value">
                <div class="t" v-if="item.ifsc && item.ifsc!='null'">IFSC：</div>
                <div class="t" v-else>UPI：</div>
                <div class="v" v-if="item.ifsc && item.ifsc!='null'">{{ item.ifsc }}</div>
                <div class="v" v-else>{{ item.upi }}</div>
              </div>

              <div v-if="getStatus(item.status).c==='failure'" class="fail-tip">* Request failed, please contact
                customer service
              </div>
            </div>
          </div>
        </div>
        <div v-else class="null">
          <div class="nullimg"></div>
          <div class="nulltext">
            <p>No withdrawal Details</p>
            <p>Invite friends to earn commission to withdraw cash！</p>
          </div>
          <div class="nullbutton" @click="exit">Go Invite</div>
        </div>
      </div>
    </vue-loadmore>
  </div>
</template>
<script>
import ExitButtonHome from '@/components/withdraw/ExitButton-home.vue'
// import { copyText } from 'vue3-clipboard';
import Locale from '@/utils/locale'
// import Loading from '@/components/Loading';
import User from '@/components/withdraw/user'
import message from 'ant-design-vue/lib/message'
import toast from '@/utils/toast'

export default {
  name: 'withdraw-detail',
  components: {
    // Loading,
    ExitButtonHome,
  },
  // setup() {
  //   const doCopy = (text) => {
  //     copyText(text, undefined, (error, event) => {
  //       if (error) {
  //         console.log(event, error)
  //       } else {
  //         let lang = Locale.getLocale();
  //         var msg = lang == 'hi' ? 'सफलतापूर्वक कॉपी करें' : 'Copy successfully';
  //         var duration = 3000;
  //         setTimeout(()=>{
  //             duration = isNaN(duration) ? 3000 : duration;
  //             var m = document.createElement("div");
  //             m.innerHTML = msg;
  //             m.style.cssText =
  //                 "width:2.5rem; padding:0.1rem 0; background: #fff; font-size: 0.2rem;text-align:center; border-radius:0.1rem; position:fixed; bottom:10%; left:38%; z-index:999999;";
  //             document.body.appendChild(m);
  //             setTimeout(function () {
  //                 var d = 0.5;
  //                 m.style.webkitTransition = "-webkit-transform " + d + "s ease-in, opacity " + d + "s ease-in";
  //                 m.style.opacity = "0";
  //                 setTimeout(function () {
  //                     document.body.removeChild(m);
  //                 }, d * 1000);
  //             }, 3000);
  //         }, 500);
  //       }
  //     })
  //   }

  //   return { doCopy }
  // },
  data() {
    return {
      page: 1,
      size: 15,
      dataList: [],
      all: '',
      finished: false,
      count: '',
      loading: false,
      isNull: false,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    tiemJude(date, amount) {
      return Date.parse(date) > Date.parse('2022-09-22 00:00:00') && Number(amount) != 0
    },
    init() {
    },
    exit() {
      // window.closePage.closePage();
      this.$router.push('/main/earn_cash/1')
      // this.$store.commit('shareTip', true)
    },
    onRefresh(done) {
      this.dataList = []
      this.page = 1
      this.size = 15
      this.finished = false
      this.getData()
      done()
    },
    onLoad(done) {
      if (this.dataList.length < this.count) {
        this.page++
        this.getData()
      } else {
        this.finished = true
      }
      done()
    },
    getData() {
      let params = {
        page: this.page,
        size: this.size,
      }
      this.loading = true
      User.withdrawDetail(params)
        .then((data) => {
          // console.log(data);
          this.loading = false
          let list = data.data.records
          this.dataList = this.dataList.concat(list)
          this.all = data.all
          this.count = data.data.total
          if (this.dataList.length == 0) {
            this.isNull = true
          } else {
            this.isNull = false
          }
          // this.isNull = true;
        })
        .catch((err) => {
          this.loading = false
          this.isWithdrawing = false
          this.isDisabled = false
          console.log(err)
        })
    },
    getStatus(status) {
      let c = '',
        text = 'Processing'
      if (status == 1 || status == 2) {
        c = 'process'
        text = 'Processing'
      } else if (status == 0 || status == 3) {
        c = 'failure'
        text = 'Failed'
      } else if (status == 4) {
        c = 'success'
        text = 'Succeeded'
      }
      return {
        c: c,
        text: text
      }
    },
    toThousandFilter(num) {
      return (+num || 0).toString().replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,')
      // return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
    },
    // copy(value) {
    //     var text = this.lang == 'hi' ? 'सफलतापूर्वक कॉपी करें' : 'Copy successfully';

    //     setTimeout(()=>{
    //         this.copyValue(value)
    //         this.copyToast(text,3000)
    //     }, 1000);
    // },

    // copyValue(value){
    //     window.copyToClipboard.copyToClipboard(value);
    //     // if (navigator.clipboard !=undefined && navigator.clipboard && window.isSecureContext) {
    //     //     return navigator.clipboard.writeText(value);
    //     // } else {
    //     //     return this.copy2(value);
    //     // }
    // },

    // copy2(value){
    //     const textArea = document.createElement('textarea');
    //     textArea.value = value;
    //     document.body.appendChild(textArea);
    //     textArea.focus();
    //     textArea.select();

    //     return new Promise((res, rej) => {
    //         document.execCommand('copy') ? res() : rej();
    //         textArea.remove();
    //     });
    // },
    onCopy(s) {
      console.log(s)
      if (navigator.clipboard !== undefined && navigator.clipboard && window.isSecureContext) {
        message.info('Copy successfully')
        navigator.clipboard.writeText(s)
      } else {
        this.copy2(s)
      }
    },

    copy2(value) {
      const textArea = document.createElement('textarea')
      textArea.value = value
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()
      /* eslint-disable no-new */
      new Promise(() => {
        message.info('Copy successfully')
        document.execCommand('copy')
        textArea.remove()
      })
    },
    copyToast(msg, duration) {
      duration = isNaN(duration) ? 3000 : duration
      var m = document.createElement('div')
      m.innerHTML = msg
      m.style.cssText =
        'width: 5rem; left: calc(50% - 2.5rem); padding :0.2rem 0; background: #eee; font-size: 0.3rem; text-align:center; border-radius :0.1rem; position:fixed; top:20%; z-index:999999;'
      document.body.appendChild(m)
      setTimeout(function () {
        var d = 0.5
        m.style.webkitTransition = '-webkit-transform ' + d + 's ease-in, opacity ' + d + 's ease-in'
        m.style.opacity = '0'
        setTimeout(function () {
          document.body.removeChild(m)
        }, d * 1000)
      }, duration)
    }
  },
}
</script>
<style scoped>

.fail-tip {
  background: #FF4D4F1A;
  color: #FF4D4F;
  width: 100%;
  height: 6rem;
  line-height: 6rem;
  margin: 2rem 2rem 0 2rem;
  font-size: 2.8rem;
  display: flex;

  font-weight: bolder;
  padding-left: 2rem;
  border-radius: 1rem;
}

.withdraw-detail {
  background: #eeeeee;
  height: 100%;
  width: 100vw;
  font-family: 'PingFang SC';
}

.head-contain {
  display: flex;
  justify-content: center;
}

.head {
  position: fixed;
  width: 112rem;
  background: #EDEDED;
  color: #6A6B6D;
  top: 0;
  align-items: center;
  justify-content: center;
  font-size: 2.6rem;
  height: 10.0rem;
  text-align: center;
  z-index: 10;

}

.title {
  margin-left: 6.0rem;
  line-height: 6.0rem;
  height: 6.0rem;
  margin-top: 2rem;
  font-size: 3.6rem;
  text-align: center;
  display: flex;
  align-items: center;
  color: #6A6B6D;
  position: absolute;
  left: 2.4rem;
}

.head img {
  width: 4.2rem;
  height: 4.2rem;
}

.content {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 10.0rem;
  padding: 0 3.0rem 0 3.0rem;
  width: 100%;
  background: #eeeeee;
}

.all {
  display: flex;
  color: #000;
  font-size: 3.2rem;
  line-height: 5.0rem;
  font-weight: 500;


}

.allvalue {
  margin-left: 4.9rem;
  color: #52c41a;
  font-size: 4.6rem;
  font-weight: 600;
}

.card {
  width: 106rem;
  background: #ffffff;
  border-radius: 2.0rem;
  margin: 0 0 3rem 0;
  padding-bottom: 2rem;

}

.card .top {
  width: 100%;
  height: 5.0rem;
  display: flex;
  justify-content: space-between;
}

.failure {
  background: #ff4d4f;
}

.process {
  background: #1890ff;
}

.success {
  background: #53c41a;
}

.card .top .left {
  width: 37.0rem;
  height: 5.0rem;
  font-size: 2.2rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  margin-right: 2rem;
  line-height: 3.0rem;
  background: #ff4d4f;
  border-radius: 2.0rem 0 10.0rem 0;
  text-align: left;
  padding: 1.1rem 0 1.1rem 3.0rem;
}

.card .top .right {
  width: 29.6rem;
  height: 5.0rem;
  font-size: 2.2rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 2.8rem;
  padding: 1.4rem 4rem 0 2.9rem;
  text-align: right;
}

.card .bottom {
  width: 100%;
  height: 9.5rem;
  font-size: 2.2rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.45);
  line-height: 5.3rem;
  display: flex;
  justify-content: space-between;
  margin: 3.0rem 0 3.0rem 0;
  text-align: right;
}

.card .bottom .lf {

  width: 39.0rem;
  height: 6.5rem;
  font-size: 4.6rem;
  font-weight: 600;
  line-height: 6.5rem;
  padding: 0 3.0rem;
  padding-right: 36.0rem;
}

.card .bottom .rg {
  width: 27.6rem;
  height: 6.5rem;
  font-size: 4.6rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 6.5rem;
  padding-right: 4rem;
}

.card .bottom .rg .showBalance {
  font-size: 2.0rem;
  font-weight: 400;
  color: #8C8C8C;
}

.card .divder {
  height: 0;
  border: none;
  border-top: .1rem solid #eeeeee;
  margin: 0 1.5rem 0 3.0rem;
  padding: 0 0 1.5rem 0;
}

.card .text {
  margin: 0 3.0rem 0 3.0rem;
}

.card .text .value {

  padding: .7rem 0;
  display: flex;
}

.card .text .value .t {
  width: 18.0rem;
  height: 3.0rem;
  font-size: 2.4rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.45);
  line-height: 3.0rem;
  text-align: right;
}

.card .text .value .v {
  width: 42.6rem;
  height: 3.0rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  line-height: 3.0rem;
  font-size: 2.2rem;
  overflow: hidden;
  word-break: break-all;
  display: flex;
  justify-content: space-between;

}

.card .text .value .v span {
  max-width: 39.6rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.card .text .value .v .step-1-copy {
  width: 3.0rem;
  height: 3.0rem;
  background: url('@/assets/copy-text.png') center no-repeat;
  background-size: 100% 100%;
  position: relative;
}

.null {
  display: flex;
  justify-items: center;
  flex-direction: column;
  align-items: center;
}


.nullimg {
  margin: 2rem 0;
  background: url('@/assets/withdraw-detail-1.png') center no-repeat;
  width: 26.2rem;
  height: 20.0rem;
  background-size: 100% 100%;
  text-align: center;
}

.nulltext {
  margin: 4rem 0;
  font-size: 2.2rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(131, 131, 131, 0.85);
  line-height: 3.3rem;
  text-align: center;
}

.nullbutton {
  margin: 3rem 0;
  width: 62.6rem;
  height: 8.7rem;
  background: #597ef7;
  border-radius: 4.4rem;
  color: #ffffff;
  text-align: center;
  font-size: 2.4rem;
  line-height: 8.7rem;
}
</style>
