<script>
import http from '@/utils/http'
import { mapState } from 'vuex'
import toast from '@/utils/toast'
import LogoutDialog from '@/components/login/LogoutDialog.vue'
import bus from 'vue3-eventbus'
import MyCashLevelDialog from '@/components/dialog/MyCashLevelDialog.vue'

export default {
  components: {
    MyCashLevelDialog,
    LogoutDialog
  },
  computed: {
    ...mapState(['pn', 'aid', 'pkg', 'uid', 'channel', 'code', 'token', 'appConfig']),
    nextLevelName () {
      if (!this.appConfig.level || !this.userData) {
        return
      }
      for (let i = 0; i < this.appConfig.level.length; i++) {
        const item = this.appConfig.level[i]
        if (item.level === this.userData.levels + 1) {
          return item.levelEnDesc
        }
      }
      return ''
    },
    currentInvatationValue () {
      if (!this.appConfig.level || !this.userData) {
        return
      }
      for (let i = 0; i < this.appConfig.level.length; i++) {
        const item = this.appConfig.level[i]
        if (item.level === this.userData.levels) {
          return item.invatationValue
        }
      }
      return ''
    },
    currentInvatationRate () {
      if (!this.appConfig.level || !this.userData) {
        return
      }
      for (let i = 0; i < this.appConfig.level.length; i++) {
        const item = this.appConfig.level[i]
        if (item.level === this.userData.levels) {
          return item.affiliateEarningsDoc
        }
      }
      return ''
    },
    totalShowCommision () {
      if (!this.userData) {
        return
      }
      return '₹ ' + this.userData.totalShowCommision
    },
    showBalance () {
      if (!this.userData) {
        return
      }
      return '₹ ' + this.userData.showBalance
    }
  },
  mounted () {
    const user = this.$store.state.user
    if (user) {
      this.userData = user
    }
    this.requestUserInfo()
  },
  methods: {
    logout () {
      this.$store.commit('token', '')
      this.dismissLogout()
      bus.emit('main_navi', 'Earn Money')
    },
    dismissLogout () {
      this.showLogout = false
    },
    dismissLevel () {
      this.showLevel = false
    },
    withdraw () {
      if (window.matchMedia('(orientation: portrait)').matches) {
        this.$router.push({
          path: '/web',
          query: { url: http.baseH5Url + '/withdraw?pn=' + this.pn + '&channel=' + this.channel + '&pkg=' + this.pkg + '&token=' + this.$store.state.token },
          param: { url: http.baseH5Url + '/withdraw?pn=' + this.pn + '&channel=' + this.channel + '&pkg=' + this.pkg + '&token=' + this.$store.state.token }
        })
      } else if (window.matchMedia('(orientation: landscape)').matches) {
        this.$router.push({
          path: '/withdraw',
        })
      }

    },
    routerMyAffiliate () {
      this.$router.push('/myAffiliate')
    },
    routerMyCommission () {
      this.$router.push('/myCommission')
    },
    routerMyReferrals () {
      this.$router.push({
        path: '/landweb',
        query: { url: 'myReferrals.html' },
        param: { url: 'myReferrals.html' }
      })
    },
    requestUserInfo () {
      const requestBody = {
        uid: this.uid,
        aid: this.aid,
        channel: this.channel,
        pkg: this.pkg,
        pn: this.pn,
        code: this.code,
        token: this.token
      }
      http.postHttp('customizedCarousel/queryUserInfo', requestBody).then((resp) => {
        if (resp.code !== 0) {
          toast.show(resp.msg)
          return
        }
        this.userData = resp.data
        this.$refs.progress.style.width = resp.data.currentLevels / resp.data.currentNextLevelNum * 100 + '%'

        this.$store.commit('user', resp.data)
      })
    },
    showLevelDialog () {
      this.showLevel = true
    }
  },
  data () {
    return {
      userData: {},
      levelPic: [
        require('@/assets/level_big_bronze.png'), require('@/assets/level_big_bronze.png'),
        require('@/assets/level_big_silver.png'), require('@/assets/level_big_gold.png'),
        require('@/assets/level_big_platinum.png'), require('@/assets/level_big_diamond.png'),
        require('@/assets/level_big_king.png')],
      levelTxtBg: [
        require('@/assets/bronze_txt_bg.png'), require('@/assets/bronze_txt_bg.png'),
        require('@/assets/silver_txt_bg.png'), require('@/assets/gold_txt_bg.png'),
        require('@/assets/platinum_txt_bg.png'), require('@/assets/diamond_txt_bg.png'),
        require('@/assets/king_txt_bg.png')],
      showLogout: false,
      showLevel: false
    }
  }
}
</script>

<template>
  <div id="my_cash_page">
    <div id="level_info">
      <img src="@/assets/cash_level_bg.png">
      <img class="level-img" v-bind:src=levelPic[userData.levels] @click="showLevelDialog">
      <p :style="{ 'background-image': 'url(' + levelTxtBg[userData.levels] + ')' }">{{ userData.levelEnDesc }}</p>
      <div id="level_award">
        <div class="award_info">

          <p>Invitation Commission</p>
          <div>
            <p>{{ currentInvatationValue }}/person</p>
          </div>

        </div>

        <div class="recharge_info">

          <p>Recharge Commission</p>
          <div>
            <p>{{ currentInvatationRate }}/cash</p>
          </div>
        </div>
      </div>
      <div id="level_info_name">
        <p>{{ userData.levelEnDesc }}</p>
        <p>{{ nextLevelName }}</p>
      </div>
      <div id="level_progress_container">
        <div id="level_progress" ref="progress"></div>
        <p>{{ userData.currentLevels }}/{{ userData.currentNextLevelNum }}</p>
      </div>
      <p>You can earn 1 experience point if your friends recharge ₹1.</p>
    </div>
    <div id="user_info">
      <div id="user_info_header">
        <div/>
        <div>
          <div>
            <span style>{{ userData.username }}</span> <span>( code:{{ userData.code }} )</span>
          </div>
          <div>
            <span>Yesterday’s Data: </span><span>₹ {{ userData.yesterdayShowBalance }}</span>
          </div>
        </div>
        <div>
          <img src="@/assets/cash_info_exit.png" id="cash_info_exit" @click="showLogout=true">
          <span id="version_info">Version - 1.0</span>
        </div>
      </div>

      <div>
        <div class="cash_info_account">
          <span class="cash_info_key">Total Commission: </span>
          <span class="cash_info_value">{{ totalShowCommision }}</span>
        </div>
        <div class="line"></div>
        <div class="cash_info_account">
          <span class="cash_info_key">Total Referrals: </span>
          <span class="cash_info_value">{{ userData.inviteCount }}</span>
        </div>
        <div class="line"></div>
        <div class="cash_info_account">
          <span class="cash_info_key">Total Withdrawals: </span>
          <span class="cash_info_value">{{ userData.withdrawCount }}</span>
        </div>
      </div>
      <div id="btn_actions">
        <div>
          <p>Current Balance</p>
        </div>
        <div class="tv-balance">
          <p>{{ showBalance }}</p>
        </div>
        <div class="withdraw" @click="withdraw">
          <p>Withdraw</p>
        </div>
        <div class="my-commission" @click="routerMyCommission">
          <p>My Commission</p>
        </div>
        <div class="my-affiliate" @click="routerMyAffiliate">
          <p>My Affiliate</p>
        </div>
        <div class="my-invite" @click="routerMyReferrals">
          <p>My Invite</p>
        </div>
      </div>
    </div>
  </div>

  <div v-if="showLogout" class="dialog_contain">
    <LogoutDialog @dismiss='dismissLogout' @logout="logout"/>
  </div>
  <div v-if="showLevel" class="dialog_contain" @click="dismissLevel">
    <MyCashLevelDialog @dismiss='dismissLevel'/>
  </div>
</template>

<style scoped>

#my_cash_page {
  width: 104.8rem;
  //height: 100%; display: flex;
}

#level_info {
  width: 62rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  /*align-content: center;*/

}

#level_info > :first-child {
  position: absolute;
  left: 0;
  top: -5%;
  width: 140%;
  height: 100%;
  margin-left: -20%;
  margin-top: -10%;
  pointer-events: none;
}

@media screen and (orientation: portrait) {
  .level-img {
    z-index: 0;
    height: 30vw;
    margin-top: 15%;

  }
}

@media screen and (orientation: landscape) {
  .level-img {
    z-index: 0;
    height: 30vh;
    margin-top: 15%;

  }
}

#level_info > :nth-child(3) {
  width: 34.7rem;
  height: 5rem;
  line-height: 5rem;
  font-size: 2.8rem;
  text-align: center;
  background-image: url("@/assets/diamond_txt_bg.png");
  background-size: 100% 100%;
  color: white;
  font-weight: bolder;
  z-index: 0;
}

#level_award {
  width: 100%;
  height: 10.6rem;
  display: flex;
  align-content: center;
  z-index: 0;
}

.award_info {
  background-image: url("@/assets/my_cash_person.png");
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  flex: 1;
}

.award_info > :first-child {
  color: white;
  font-size: 1.6rem;
}

.award_info > :nth-child(2) {
  color: white;
  height: 40%;
  display: flex;
  align-items: center;
  font-size: 2.2rem;
}

.recharge_info {
  background-image: url("@/assets/my_cash_gift.png");
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  flex: 1;
}

.recharge_info > :first-child {
  color: white;
  font-size: 1.6rem;
}

.recharge_info > :nth-child(2) {
  color: white;
  height: 40%;
  display: flex;
  align-items: center;
  font-size: 2.2rem;
}

#level_info_name {
  display: flex;
  justify-content: space-between;
  width: 90%;
  /*align-content: space-between;*/
}

#level_info_name > :first-child {
  color: #FFECC2;
  font-size: 1.8rem;
}

#level_info_name > :last-child {
  color: #FFECC2;
  font-size: 1.8rem;
}

#level_info :nth-child(6) {
  width: 90%;
}

#level_progress_container {
  background: rgba(177, 93, 29, 1);
  width: 100%;
  height: 2.6rem;
  padding: 0 0.1rem;
  border-radius: 1.3rem;
  display: flex;
  justify-content: center;
  align-content: center;
  position: relative;
}

#level_progress {
  background: linear-gradient(rgba(239, 161, 73, 1), rgba(241, 171, 80, 1), rgba(255, 255, 176, 1));
  width: 50%;
  height: inherit;
  border-radius: 1.3rem;
  position: absolute;
  left: 0;
}

#level_progress_container > p {
  z-index: 1;
  color: rgba(28, 15, 14, 1);
  font-size: 1.8rem;
  align-self: center;
}

#level_info :nth-child(7) {
  color: #FFECC2;
  font-size: 1.4rem;
}

* {
  box-sizing: border-box;
}

#user_info {
  border-radius: 0.6rem;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background: #44130E66;
}

#user_info_header {
  height: 6.8rem;
  display: flex;

  /*align-content: center;*/

}

#user_info_header > :first-child {
  background: #B03900;
  border: 2px solid white;
  box-sizing: border-box;
  align-self: center;
  border-radius: 100%;
  aspect-ratio: 1 / 1;
  height: 100%;
}

#user_info_header > :nth-child(2) {
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-self: center;
  flex-direction: column;
  margin-left: 1.4rem;
  flex: 1;
}

#user_info_header > :nth-child(2) > :first-child > :first-child {
  color: white;
  font-size: 2.8rem;
  font-weight: bolder;
}

#user_info_header > :nth-child(2) > :first-child > :nth-child(2) {
  color: white;
  font-size: 2.2rem;
  margin-left: 1rem
}

#user_info_header > :nth-child(2) > :nth-child(2) > :first-child {
  color: rgba(255, 255, 255, 0.5);
  font-size: 2.2rem
}

#user_info_header > :nth-child(2) > :nth-child(2) > :nth-child(2) {
  color: #FBEB6E;
  font-size: 2.2rem;
  margin-left: 1rem
}

#user_info_header > :nth-child(3) {

  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

}

#cash_info_exit {
  width: 2.8rem;
  height: 2.8rem;
  align-self: end;
}

#version_info {
  color: rgba(255, 255, 255, 0.5);
  margin-top: 0.2rem;
  font-size: 2.2rem;
}

#user_info > :nth-child(2) {
  background: linear-gradient(rgba(130, 45, 0, 0.5), rgba(182, 77, 31, 0.5));
  border-radius: 0.5rem;
  height: 25%;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.cash_info_account {
  display: flex;
  width: 100%;
  padding: 1rem 2rem;
  box-sizing: border-box;
  justify-content: space-between;
}

.cash_info_key {
  color: rgba(255, 255, 255, 0.7);
  font-style: italic;
  font-weight: 400;
  font-size: 2.2rem;

}

.cash_info_value {
  color: white;
  font-style: italic;
  font-weight: 600;
  font-size: 2.2rem;
  text-shadow: 0.2rem 0.2rem 0 #000, 1px 0.2rem 0 #000, 0.2rem 1px 0 #000, 1px 1px 0 #000;

}

.line {
  height: 0.1rem;
  background: rgba(255, 255, 255, 0.15);
}

#btn_actions {
  background: white;
  flex: 1;
  background: linear-gradient(rgba(130, 45, 0, 1), rgba(177, 93, 29, 1), rgba(182, 77, 31, 1));
  border-radius: 1rem;
  margin-top: 1rem;
  display: flex;
  flex-flow: wrap;
  align-content: start;
  padding: 2rem;
}

#btn_actions > :first-child {
  width: 100%;
  padding-bottom: 1rem;
  display: flex;
  align-content: center;
  justify-content: center;

}

#btn_actions > :first-child > p {
  width: 100%;
  justify-content: center;

  font-size: 2.2rem;
  color: white;
}

.tv-balance {
  width: 64%;
  background: rgba(30, 14, 14, 0.5);
  border-radius: 0.4rem 0 0 0.4rem;
  box-sizing: border-box;
  height: 8rem;
  display: flex;
  align-content: center;
  justify-content: start;
  padding-left: 2rem;
}

#btn_actions > :nth-child(2) > p {
  color: rgba(255, 252, 216, 1);
  font-weight: bolder;
  font-size: 4.2rem;
  align-self: center;
}

.withdraw {
  width: 35%;
  background-image: url("@/assets/btn_withdraw_bg.png");
  background-size: 120% 120%;
  height: 8rem;
  display: flex;
  align-content: center;
  justify-content: center;
  border-radius: 0 0.25rem 0.25rem 0;
}

.withdraw:active {
  opacity: 0.6;
}

.withdraw > p {
  color: white;
  align-self: center;
  font-weight: bolder;
  font-size: 2.8rem;
}

.my-commission {
  background-image: url("@/assets/btn_my_commission.png");
  background-size: 100% 100%;
  margin-top: 1.7rem;
  width: 49%;
  height: 8rem;
  display: flex;
  align-content: center;
  justify-content: center;
}

.my-commission:active {
  opacity: 0.6;
}

.my-commission > p {
  color: white;
  align-self: center;
  font-size: 2.8rem;
  text-shadow: 0.2rem 0.2rem 0 #003289, 1px 0.2rem 0 #003289, 0.2rem 1px 0 #003289, 1px 1px 0 #003289;
}

.my-affiliate {
  background-image: url("@/assets/btn_my_affiliate.png");
  background-size: 100% 100%;
  margin-top: 1.7rem;
  width: 49%;
  height: 8rem;
  margin-left: 0.8rem;
  display: flex;
  align-content: center;
  justify-content: center;
}

.my-affiliate:active {
  opacity: 0.6;
}

.my-affiliate > p {
  color: white;
  align-self: center;
  font-size: 2.8rem;
  text-shadow: 0.2rem 0.2rem 0 #7252F7, 1px 0.2rem 0 #7252F7, 0.2rem 1px 0 #7252F7, 1px 1px 0 #7252F7;
}

.my-invite {
  background-image: url("@/assets/btn_my_invite.png");
  background-size: 100% 100%;
  margin-top: 1.7rem;
  width: 100%;
  height: 8rem;

  display: flex;
  align-content: center;
  justify-content: center;
}

.my-invite:active {
  opacity: 0.6;
}

.my-invite > p {
  color: white;
  align-self: center;
  font-size: 2.8rem;
  text-shadow: 0.2rem 0.2rem 0 #C73506, 1px 0.2rem 0 #C73506, 0.2rem 1px 0 #C73506, 1px 1px 0 #C73506;
}
</style>
