<template>

  <div>
    <img id="login_bg" src="@/assets/login_bg.png">
  </div>

  <div id="content_contain">
    <div id="banner">
      <LoginBanner/>
    </div>

    <div id="login_actions">
      <div id="phone_contain">
        <p>+91</p>
        <div></div>
        <div></div>
        <input type="tel" id="phone" name="phone" maxlength="10" placeholder="Type mobile" v-model="mobile" required>
        <img src="@/assets/login_right_icon.png">
      </div>
      <div id="otp_contain">
        <input type="tel" id="otp" name="otp" maxlength="4" placeholder="Type your OTP" v-model="otp" required>
        <button id="btn_otp" v-bind:disabled="otpDisable" @click="sendOtp">
          <p>{{ countdownTxt }}</p>
        </button>
      </div>
      <!-- <div id="private_contain">
        <input type="checkbox" id="cb_privacy" name="cb_privacy" v-model="checkbox">
        <p>I agree to <span class="span_privacy">T&C</span> and <span class="span_privacy">Privacy Policy.</span>
        </p>
      </div> -->

      <div class="btn_login" @click="login">
        <p>Sign in / Sign up</p>
      </div>

    </div>

    <img src="@/assets/close_circle.png" v-show="!this.$store.state.forceLogin" @click="dissmiss" id="close">
  </div>

</template>
<script>
import http from '@/utils/http'
import LoginBanner from '@/views/LoginBanner.vue'
import toast from '@/utils/toast'
import bus from 'vue3-eventbus'

export default {
  components: { LoginBanner },
  data () {
    return {
      mobile: '',
      otp: '',
      countdownTxt: 'Request',
      otpDisable: false,
      checkbox: false
    }
  },
  methods: {
    dissmiss () {
      this.$store.commit('showLogin', false)
    },
    login () {
      // if (!this.checkbox) {
      //   toast.show('Need to agree to privacy policy')
      //   return
      // }
      if (this.mobile.length !== 10) {
        toast.show('please input you mobile')
        return
      }
      if (!this.otp.length) {
        toast.show('please input otp')
        return
      }

      const requestData = {
        mobile: '91' + this.mobile,
        verifyCode: this.otp,
        aid: this.$store.state.aid,
        pn: this.$store.state.pn,
        uid: this.$store.state.uid
      }
      http.postHttp('/h5/user/verify', requestData).then((rep) => {
        if (rep.code === 0) {
          this.$store.commit('user', rep.user)
          this.$store.commit('finish_task_count', rep.finish_task_count)
          this.$store.commit('linkUrl', rep.user.url)
          this.$store.commit('mobile', rep.user.mobile)
          this.$store.commit('code', rep.user.code)
          if (rep.token) {
            this.$store.commit('token', rep.token)
          }
          bus.emit('login_action', true)
          this.dissmiss()
        } else {
          toast.show(rep.msg)
        }
      })
    },
    sendOtp () {
      if (this.mobile.length !== 10) {
        toast.show('please input you mobile')
        return
      }
      const requestData = {
        mobile: '91' + this.mobile,
        pn: this.$store.state.pn,
        uid: this.$store.state.uid
      }
      http.postHttp('h5/user/get_verify_code', requestData).then((rep) => {
        if (rep.code === 0) {
          this.startCountDown()
        } else {
          toast.show(rep.msg)
        }
      })
    },
    startCountDown () {
      let countdown = 60
      this.otpDisable = true
      const that = this
      const countdownInterval = setInterval(function () {
        if (countdown > 0) {
          that.countdownTxt = countdown + 'S'
          countdown--
        } else {
          that.countdownTxt = 'Request'
          that.otpDisable = false
          clearInterval(countdownInterval)
        }
      }, 1000)
    }
  },
  mounted () {
  }
}

</script>

<style scoped>
#login_bg {
  margin-top: 6.4rem;
  width: 105.7rem;
  height: 51.1rem;
}

#content_contain {
  width: 104rem;
  height: 36rem;
  display: flex;
  position: absolute;
  top: 19.5rem;
  left: auto;
  align-content: center;
  justify-content: center;
}

#banner {
  width: 52.6rem;
  align-self: center;
  border-radius: 0.6rem;
  height: 28.2rem;
}

#login_actions {
  width: 42rem;
  height: 28.2rem;
  align-self: center;
  margin-left: 2.5rem;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

#phone_contain {
  background: white;
  height: 5.8rem;
  width: 100%;
  border-radius: 0.4rem;
  display: flex;

  align-items: center;
}

#phone_contain > :first-child {
  font-weight: bolder;
  font-size: 2.8rem;
  color: #111111;
  line-height: 2.8rem;
  padding: 1.3rem;
}

#phone_contain > :nth-child(2) {
  width: 0;
  height: 0;
  border-top: 1rem solid #CCCCCC;
  border-right: 1rem solid transparent;
  border-bottom: 1rem solid transparent;
  border-left: 1rem solid transparent;
  margin-top: 1rem;
}

#phone_contain > :nth-child(3) {
  width: 0.1rem;
  height: 3rem;
  margin-left: 2rem;
  margin-right: 1rem;
  background: #CCCCCC;
}

#phone_contain > :nth-child(4) {
  width: 26rem;
  height: 80%;
  border: none;
  color: #111111;
  font-size: 2.8rem;
  max-lines: 1;
  font-weight: bolder;
  outline: transparent;
  background: transparent;
}

#phone_contain > :nth-child(5) {
  width: 2.9rem;
  height: 3.2rem;
}

#otp_contain {
  height: 5.8rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#otp_contain > :first-child {
  width: 26rem;
  height: 5.8rem;
  background: white;
  border-radius: 0.4rem;
  color: #111111;
  font-size: 2.8rem;
  max-lines: 1;
  border: none;
  font-weight: bolder;
  outline: transparent;
  padding-left: 2rem;

}

#btn_otp {
  width: 14.2rem;
  height: 6.1rem;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  background-image: url("@/assets/btn_my_commission.png");
  background-size: 100% 100%;
}

#btn_otp:active {
  opacity: 0.6;
}

#btn_otp > p {
  font-weight: bolder;
  align-self: center;
  text-shadow: 0.2rem 0.2rem 0 #0062AD, 1px 0.2rem 0 #0062AD, 0.2rem 1px 0 #0062AD, 1px 1px 0 #0062AD;
  font-size: 2.8rem;
  color: white;
}

#private_contain {
  display: flex;
  align-items: center;
  justify-items: center;
}

#cb_privacy {
  width: 2.6rem;
  height: 2.6rem;
  margin-right: 1.4rem;
  background-color: #15C457;
  border-radius: 0.4rem;
}

input[type=checkbox] {
  cursor: pointer;
  position: relative;
  width: 1.8rem;
  height: 1.8rem;
  font-size: 1.2rem;
}

input[type=checkbox]::after {
  position: absolute;
  top: 0;
  color: #000;
  width: 1.8rem;
  height: 1.8rem;
  display: inline-block;
  visibility: visible;
  padding-left: 0;
  text-align: center;
  content: ' ';
  border-radius: 0.3rem
}

input[type=checkbox]:checked::after {
  width: 2.6rem;
  height: 2.6rem;
  background-color: #15C457;
  background-image: url("@/assets/vector_right.png");
  background-size: 80% 80%;
  background-position: center;
  background-repeat: no-repeat;
}

#private_contain > :nth-child(2) {
  color: white;
  font-weight: bolder;
  font-size: 1.6rem;
}

.span_privacy {
  color: #00D8FF;
  font-weight: bolder;
  font-style: italic;
  font-size: 1.8rem;
}

.btn_login {
  background-image: url("@/assets/btn_green.png");
  background-size: 100% 100%;
  width: 100%;
  height: 7.7rem;
  display: flex;
  justify-content: center;
  align-content: center;
}

.btn_login:active {
  opacity: 0.6;
}

.btn_login > p {
  color: white;
  font-weight: bolder;
  font-size: 2.8rem;
  margin-top: 1.2rem;
}

#close {
  width: 5.4rem;
  height: 5.4rem;
  position: absolute;
  right: 0;
  top: -6rem;
  opacity: 1;
}

#close:active {
  opacity: 0.6;
}
</style>
