<script>
import MainContentBanner from '@/views/MainContentBanner.vue'
import bus from 'vue3-eventbus'
import Share from '@/views/Share.vue'
import ShareTipDialog from '@/components/dialog/ShareTipDialog.vue'

export default {
  components: {
    ShareTipDialog,
    Share,
    MainContentBanner
  },
  computed: {
    rotateStyle () {
      return {
        transform: `rotate(${this.rotation}deg)`,
        transformOrigin: 'center center',
        transition: this.enableTransition ? 'transform 2s linear' : 'none'
      }
    },
    scaleStyle () {
      return {
        transform: `scale(${this.scale})`,
        transformOrigin: 'center center'
      }
    }
  },
  data () {
    return {
      scale: 1,
      rotation: 0,
      enableTransition: true
    }
  },
  methods: {
    showTask () {
      bus.emit('main_task', true)
    },
    startAnimation () {
      // 1. div 从小到大缩放
      this.scale = 0.2
      setTimeout(() => {
        this.scale = 1
      }, 500) // 缩放动画完成的时间
      // 2. div 缩放完成后，开始旋转图片
      setTimeout(() => {
        this.enableTransition = true // 禁用旋转动画
        this.rotation = 1080 // 旋转3圈 (3 * 360 degrees)
      }, 2000) // 缩放动画完成的时间

      // 3. 旋转完成后，暂停 3 秒，然后重置动画
      setTimeout(() => {
        this.enableTransition = false // 禁用旋转动画
        this.rotation = 0 // 重置旋转

        setTimeout(this.startAnimation, 3000) // 暂停3秒后重新开始动画
      }, 4000)
    }
  },
  mounted () {
    this.$watch(
      () => this.$route.params,
      () => {
        if (this.$route.params === 1) {
          if (!this.$store.state.shareTip) {
            this.$store.commit('shareTip', true)
          }
        }
      },
      { immediate: true }
    )
    this.startAnimation()
  }
}

</script>

<template>
  <div id="earn_cash_page">
    <div id="banner">
      <MainContentBanner/>
    </div>
    <Share/>
  </div>
  <div class="task_actions">
    <div class="task_all" :style="scaleStyle">
      <img class="main-task-light" src="@/assets/task_float_light.png" :style="rotateStyle">
      <img class="main-task-icon" src="@/assets/main_task_icon.png" @click="showTask">
    </div>
  </div>
  <div v-if="this.$store.state.shareTip" class="dialog_contain">
    <ShareTipDialog/>
  </div>
</template>

<style scoped>

#earn_cash_page {
  width: 104.8rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}

#banner {
  width: 104.8rem;
  height: 35rem;
  overflow: hidden;
  border-radius: 1.6rem;
}

.task_actions {
  position: absolute;
  z-index: 1;
  right: 3rem;
  top: 22.8rem;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.task_all {
  width: 20rem;
  height: 20rem;
  transition: transform 0.5s ease; /* div 缩放动画 */
}

.main-task-light {
  width: 17rem;
  height: 16rem;
  position: absolute;
  right: 0;
  z-index: 1;
}

.main-task-icon {
  width: 9rem;
  height: 9rem;
  position: absolute;
  right: 3rem;
  z-index: 1;
  top: 3.2rem;
}

.main-task-icon:active {
  opacity: 0.6;
}
</style>
