<script>

import Swiper from '@/utils/verticalSwiper'

export default {
  computed: {
    banner () {
      return this.$store.state.appConfig.loginCarousel.loginCarousels
    }
  },
  mounted () {
    // eslint-disable-next-line no-new
    new Swiper('.banner-contain', {
      loop: true,
      autoplay: {
        delay: 2000,
        disableOnInteraction: false
      }
    })
  },
  methods: {}
}
</script>

<template>
  <div class="swiper-container banner-contain">
    <div class="swiper-wrapper">
      <img class="swiper-slide" v-for="item in banner" :key="item.length" :src="item"/>
    </div>
    <div class="swiper-pagination"></div>
  </div>
</template>

<style scoped>

.banner-contain {
  width: 100%;
  height: 100%;
  --swiper-pagination-color: #ffffff;
  overflow: hidden;
}

.swiper-slide {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: auto;
  border-radius: 0.4rem;
}
</style>
