<script>

import MyTitle from '@/components/my/MyTitle.vue'
import LandscapeMode from '@/utils/LandscapeMode.vue'
import http from '@/utils/http'
import toast from '@/utils/toast'

export default {
  components: {
    LandscapeMode,
    MyTitle
  },
  data () {
    return {
      affiliateList: [],
      page: 1,
      loading: false
    }
  },
  methods: {
    getAffiliatesList () {
      const keyword = ''
      const aid = this.$store.state.aid
      const uid = this.$store.state.uid
      const size = '15'
      const page = this.page
      const pn = this.$store.state.pn

      this.loading = true
      http.getHttp(`/customizedcustommenu/affiliates?keyword=${keyword}&aid=${aid}&uid=${uid}&size=${size}&page=${page}&pn=${pn}`).then((resp) => {
        if (resp.code !== 0) {
          toast.show(resp.msg)
          return
        }
        ++this.page
        this.affiliateList = this.affiliateList.concat(resp.page.list)
      }).finally(() => {
        this.loading = false
      })
    },
    handleScroll (e) {
      if (this.loading) {
        return
      }
      const {
        scrollTop,
        clientHeight,
        scrollHeight
      } = e.target
      if (scrollTop + clientHeight > scrollHeight - 100) {
        this.getAffiliatesList()
      }
    }
  },
  mounted () {
    this.getAffiliatesList()
  }
}
</script>

<template>
  <LandscapeMode>
    <div class="my-affiliate-contain">
      <MyTitle class="title" title="MyAffiliate"/>
      <div class="content" @scroll="handleScroll">
        <div class="tab-header">
          <div class="tab-header-row">
            <p class="tab-header-cell">ID</p>
            <p class="tab-header-cell">Name</p>
            <p class="tab-header-cell">Phone Number</p>
            <p class="tab-header-cell">Total Affiliates</p>
            <p class="tab-header-cell">Total Affiliates</p>
            <p class="tab-header-cell">Today Commission</p>
            <p class="tab-header-cell">Today Commission</p>
          </div>
        </div>
        <div class="tab-content">
          <div v-for="(item,index) in affiliateList" :key="item"
               :class=" index % 2 === 0 ?'tab-content-row tab-row-color':'tab-content-row'">
            <p class="tab-content-cell">{{ item.code }}</p>
            <p class="tab-content-cell">{{ item.username }}</p>
            <p class="tab-content-cell">{{ item.mobile }}</p>
            <p class="tab-content-cell">{{ item.todayAffiliate }}</p>
            <p class="tab-content-cell">{{ item.totalAffiliates }}</p>
            <p class="tab-content-cell tab-content-cell-green">{{ item.todayShowCommision }}</p>
            <p class="tab-content-cell tab-content-cell-gold">{{ item.totalShowCommision }}</p>
          </div>
          <div>
          </div>
        </div>
      </div>
    </div>
  </LandscapeMode>
</template>

<style scoped>

.my-affiliate-contain {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.title {
  padding: 1rem 3rem;
}

.content {
  width: 128rem;
  height: 60rem;
  background: rgba(119, 39, 28, 0.3);
  overflow: auto;
}

.tab-header {
  display: table;
  width: 100%;
  background: #EE80AD;
  table-layout: fixed;
  height: 4.6rem;
  position: sticky;
  top: 0;
}

.tab-header-row {
  display: table-row;
}

.tab-header-cell {
  display: table-cell;
  color: #1E0E0E;
  line-height: 4.6rem;
  text-align: center;
  font-size: 2rem;
  border-right: 0.2rem solid #77271C;
}

.tab-content {
  display: table;
  width: 100%;
  table-layout: fixed;
  height: 4.6rem;

}

.tab-content-row {
  display: table-row;
}

.tab-content-cell {
  display: table-cell;
  color: white;
  line-height: 4.6rem;
  text-align: center;
  font-size: 2rem;
  border-right: 0.2rem solid #77271C;
}

.tab-content-cell-green {
  color: #31FF61;
}

.tab-content-cell-gold {
  color: #FBEB6E;
}

.tab-row-color {
  background: rgba(243, 178, 86, 0.2);
}
</style>
