<script>
import LandBannerView from '@/views/LandBannerView.vue'
import bus from 'vue3-eventbus'
import Swiper from '@/utils/verticalSwiper'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      levelPic: [
        require('@/assets/level_big_bronze.png'), require('@/assets/level_big_bronze.png'),
        require('@/assets/level_big_silver.png'), require('@/assets/level_big_gold.png'),
        require('@/assets/level_big_platinum.png'), require('@/assets/level_big_diamond.png'),
        require('@/assets/level_big_king.png')],
      levelTxtBg: [
        require('@/assets/bronze_txt_bg.png'), require('@/assets/bronze_txt_bg.png'),
        require('@/assets/silver_txt_bg.png'), require('@/assets/gold_txt_bg.png'),
        require('@/assets/platinum_txt_bg.png'), require('@/assets/diamond_txt_bg.png'),
        require('@/assets/king_txt_bg.png')],
      levelBtnColor: ['linear-gradient(#FEA582, #AD4500)', 'linear-gradient(#FEA582, #AD4500)', 'linear-gradient(#C9D7E2, #3F5776)', 'linear-gradient(#FFF40F, #F1AC00) ', 'linear-gradient(#FF8073, #E02016)', 'linear-gradient(#82EDFE, #0062AD) ', 'linear-gradient(#E94352, #7F1B1C)'],
      currentLv: 1,
      count: 2,
      isReverse: false,
      spaceBetween: 220
    }
  },
  computed: {
    ...mapState(['appConfig', 'user'])
  },
  methods: {
    closeDialog () {
      bus.emit('showLevelDialog', false)
    },
    checkIsReverse () {
      if (window.matchMedia('(orientation: portrait)').matches) {
        this.isReverse = true
      } else if (window.matchMedia('(orientation: landscape)').matches) {
        this.isReverse = false
      }
    },
    inviteMethod (index) {
      bus.emit('showLevelDialog', false)
      const skipConfigDto = this.appConfig.level[index - 1].skipConfigDto
      if (skipConfigDto && skipConfigDto.url) {
        if (skipConfigDto.url.startsWith('http')) {
          this.$router.push({
            path: '/web',
            query: { url: skipConfigDto.url },
            param: { url: skipConfigDto.url }
          })
        }
      }
    }
  },
  mounted () {
    this.checkIsReverse()
    this.currentLv = this.user.levels
    if (this.currentLv >= this.appConfig.level.length) {
      this.currentLv = this.appConfig.level.length
      this.count = this.currentLv
    } else {
      this.count = this.currentLv + 1
    }
    this.$nextTick(() => {
      new Swiper('.banner-contain', {
        isReverse: this.isReverse,
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: this.spaceBetween,
        loop: false
      })
    })
  },
  components: {
    LandBannerView
  }
}

</script>

<template>
  <img class="close" src="@/assets/close_circle.png" @click="closeDialog">
  <LandBannerView>
    <div class="swiper-slide" v-for="item in this.count" :key="item">
      <div class="item-slide">
        <img :src="levelPic[item]" class="pic-level">
        <p class="level-txt" :style="{  backgroundImage: `url(${levelTxtBg[item]})`  }">
          {{ this.appConfig.level[item - 1].levelEnDesc }}</p>
        <div class="level-info-contain">
          <div class="level-info">
            <img src="@/assets/level-person.png" class="level-info-img">
            <p class="level-info-name">Invitation Commission</p>
            <div class="level-info-rate-contain">
              <img class="level-info-rate-bg" src="@/assets/txt_bg_1.png">
              <p class="level-info-rate">₹{{ this.appConfig.level[item - 1].invatationValue }}/person</p>
            </div>
            <p class="level-info-tips"> friends install and you earn</p>
          </div>
          <div class="level-info">
            <img src="@/assets/level-cash.png" class="level-info-img">
            <p class="level-info-name">Recharge Commission</p>
            <div class="level-info-rate-contain">
              <img class="level-info-rate-bg" src="@/assets/txt_bg_1.png">
              <p class="level-info-rate">{{ this.appConfig.level[item - 1].affiliateEarningsDoc }}cash</p>
            </div>
            <p class="level-info-tips"> It takes maximum 24 Hrs to calculate and update commission.</p>
          </div>
          <div class="level-info">
            <img src="@/assets/level-gifts.png" class="level-info-img">
            <p class="level-info-name">Commission from Affiliates</p>
            <div class="level-info-rate-contain">
              <img class="level-info-rate-bg" src="@/assets/txt_bg_1.png">
              <p class="level-info-rate">{{ this.appConfig.level[item - 1].relationship }}</p>
            </div>
            <p class="level-info-tips"> Earn more from affiliates when you are at higher division.</p>
          </div>
        </div>

        <p class="btn-invitation" @click="inviteMethod(item)" :style="{  background: `${levelBtnColor[item]}`  }">INVITATION METHODS</p>
        <p class="current-tag" v-if="this.currentLv === item">CURRENT</p>
      </div>
    </div>
  </LandBannerView>
</template>

<style scoped>

.swiper-slide {
  width: 60rem;
  height: 49.5rem;
  margin-left: 6rem;
  margin-right: 6rem;
  align-self: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item-slide {
  width: 91rem;
  height: 49.5rem;
  background-image: url("@/assets/level_dialog_bg.png");
  align-self: center;
  position: relative;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pic-level {
  width: 24.2rem;
  height: 23.9rem;
  margin-top: -10rem;
}

.level-txt {
  width: 61.9rem;
  height: 5.2rem;
  background-size: 100% 100%;
  text-align: center;
  line-height: 5.2rem;
  color: white;
  margin-top: -2rem;
  font-weight: bolder;
  font-size: 2.8rem;
}

.level-info-contain {
  width: 100%;
  margin-top: 2rem;
  display: flex;
}

.level-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33%;
}

.level-info-name {
  color: white;
  width: 14rem;
  height: 4rem;
  margin-top: -2.4rem;
  font-size: 2.2rem;
  text-align: center;
}

.level-info-rate-contain {
  width: 27rem;
  height: 5rem;
  margin-top: 2rem;
  background-size: 100% 100%;
  position: relative;
}

.level-info-rate-bg {
  width: 27rem;
  height: 5rem;
  background-size: 100% 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.3;
}

.level-info-rate {
  width: 27rem;
  height: 5rem;
  color: #FBEB6E;
  font-weight: bolder;
  text-align: center;
  line-height: 5rem;
  font-size: 2.8rem;
  position: absolute;
  left: 0;
  top: 0;

}

.level-info-img {
  width: 11.4rem;
  background-size: 100% 100%;
  height: 11.6rem;
}

.level-info-tips {
  width: 26rem;
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.4rem;
  margin-top: 1rem;
  color: #72329C;
}

.btn-invitation {
  width: 43.6rem;
  height: 8.2rem;
  border: 0.2rem solid #FBEB6E;
  color: white;
  line-height: 8.2rem;
  font-size: 3.4rem;
  text-align: center;
  background: linear-gradient(#E94352, #7F1B1C);
  margin-top: 2.6rem;
  border-radius: 4.1rem;
  font-weight: bolder;
}

.btn-invitation:active {
  opacity: 0.8;
}

.close {
  position: absolute;
  width: 5rem;
  height: 5rem;
  top: 4rem;
  z-index: 9;
  right: 18rem;
}

.close:active {
  opacity: 0.6;
}

.current-tag {
  width: 16.6rem;
  height: 4.7rem;
  background-image: url("@/assets/level_current_bg.png");
  background-size: 100% 100%;
  position: absolute;
  text-align: center;
  line-height: 4.7rem;
  font-size: 2.4rem;
  color: #7F1B1C;
  left: 1%;
  top: 12%;
}
</style>
