<script>

import LandscapeMode from '@/utils/LandscapeMode.vue'

export default {
  data () {
    return {
      url: this.$route.query.url
    }
  },
  components: {
    LandscapeMode
  },
  mounted () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.url = this.$route.query.url
      },
      { immediate: true }
    )
  }
}
</script>

<template>
  <LandscapeMode>
    <iframe class="web" :src="url"></iframe>
  </LandscapeMode>
</template>

<style scoped>

.web {
  width: 128rem;
  height: 72rem;
  border: none;
}
</style>
