import axios from 'axios'

class Buriedpoint {

  // static reportBaseUrl = 'http://192.168.0.6:10005';
  static reportBaseUrl = 'https://report.taurus.cash'

  // static reportBaseUrl = '';

  static point (code, ts, cts, pkg, channel, pn, ip, platform, aid, gaid, taurus_stat_uuid, uid, type, listJson) {
    // static point(aid,channel,code,data,gaid,pkg,platform,pn,taurusStatUuid,ts,type,uid) {
    return axios
      .post(`${Buriedpoint.reportBaseUrl}/reportclient/producerController/send`, {
        code: code,
        ts: ts,
        cts: cts,
        pkg: pkg,
        channel: channel,
        pn: pn,
        ip: ip,
        platform: platform,
        aid: aid,
        gaid: gaid,
        taurus_stat_uuid: taurus_stat_uuid,
        uid: uid,
        type: type,
        listJson: listJson
      })
      .then(res => {
        console.log('point', res.data)
        if (res.data.code === 0) {
          return Promise.resolve(res.data)
        }
      })
      .catch(err => {
        console.log('point', err)
        // return Promise.reject(err);
      })
  }
}

export default Buriedpoint
