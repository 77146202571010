<script>

import Swiper from '@/utils/verticalSwiper'
import '@/utils/verticalSwiper.css'
import http from '@/utils/http'
import { mapState } from 'vuex'

export default {

  data () {
    return {
      banner: [],
      isReverse: false,
      autoplay: false
    }
  },
  computed: {
    ...mapState(['pn', 'aid', 'pkg', 'uid', 'channel', 'code'])
  },
  mounted () {
    this.checkIsReverse()
    this.requestBannerData()
  },
  methods: {

    checkIsReverse () {
      if (window.matchMedia('(orientation: portrait)').matches) {
        this.isReverse = true
      } else if (window.matchMedia('(orientation: landscape)').matches) {
        this.isReverse = false
      }
    },
    requestBannerData () {
      const requestBody = {
        pn: this.pn,
        aid: this.aid,
        pkg: this.pkg,
        uid: this.uid,
        code: this.code,
        channel: this.channel
      }
      http.postHttp('/customizedCarousel/queryConfigure', JSON.stringify(requestBody)).then((reps) => {
        if (reps.code !== 0) {
          return
        }
        this.banner = reps.data.list
        this.autoplay = reps.autoplay
        this.$nextTick(() => {
          // eslint-disable-next-line no-new
          new Swiper('.banner-contain', {
            isReverse: this.isReverse,
            loop: true
          })
        })
      })
    }
  }
}
</script>

<template>
  <div class="swiper-contain banner-contain">
    <div class="swiper-wrapper">
      <img class="swiper-slide" v-for="item in banner" :key="item.length" :src="item.carouselEnUrl"/>
    </div>
  </div>
</template>

<style scoped>

.banner-contain {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: auto;
}
</style>
