<script>

import PortraitMode from '@/utils/PortraitMode.vue'

export default {
  data () {
    return {
      url: this.$route.query.url
    }
  },
  components: {
    PortraitMode
  },
  mounted () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.url = this.$route.query.url
      },
      { immediate: true }
    )
  }
}
</script>

<template>
<!--  <PortraitMode>-->
    <iframe class="web" :src="url"></iframe>
<!--  </PortraitMode>-->
</template>

<style scoped>

.web {
  width: 100vw;
  height: 100vh;
  border: none;
}
</style>
