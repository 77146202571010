<script>

import MyTitle from '@/components/my/MyTitle.vue'
import LandscapeMode from '@/utils/LandscapeMode.vue'
import http from '@/utils/http'
import toast from '@/utils/toast'

export default {
  components: {
    LandscapeMode,
    MyTitle
  },
  data () {
    return {
      commissionList: [],
      totalDealer: {},
      page: 1,
      loading: false
    }
  },
  methods: {
    getCommissionList () {
      const aid = this.$store.state.aid
      const uid = this.$store.state.uid
      const size = '15'
      const page = this.page
      const pn = this.$store.state.pn
      this.loading = true
      http.getHttp(`/customizedcustommenu/dailies?aid=${aid}&uid=${uid}&size=${size}&page=${page}&pn=${pn}`).then((resp) => {
        if (resp.code !== 0) {
          toast.show(resp.msg)
          return
        }
        ++this.page
        this.commissionList = this.commissionList.concat(resp.page.list)
        this.totalDealer = resp.totalDealer
      }).finally(() => {
        this.loading = false
      })
    },
    handleScroll (e) {
      if (this.loading) {
        return
      }
      const {
        scrollTop,
        clientHeight,
        scrollHeight
      } = e.target
      if (scrollTop + clientHeight > scrollHeight - 100) {
        this.getCommissionList()
      }
    }
  },
  mounted () {
    this.getCommissionList()
  }
}
</script>

<template>
  <LandscapeMode>
    <div class="my-affiliate-contain">
      <MyTitle class="title" title="MyCommission"/>

      <div class="calculate-title">
        <div class="tab-header-total">
          <div class="tab-header-row-total">
            <p class="tab-header-cell-total-title">Data</p>
            <p class="tab-header-cell-total-title">Invitation</p>
            <p class="tab-header-cell-total-title">Invitation Recharge</p>
            <p class="tab-header-cell-total-title">Affiliate Earnings</p>
            <p class="tab-header-cell-total-title">Other</p>
          </div>
        </div>
        <div class="calculate-tab">
          <div class="tab-header-row-total">
            <p class="tab-header-cell-total tab-header-cell-total-yellow">₹ {{ totalDealer.totalAmount }}</p>
            <p class="tab-header-cell-total">=</p>
            <p class="tab-header-cell-total">₹ {{ totalDealer.earninginstall }}</p>
            <p class="tab-header-cell-total">+</p>
            <p class="tab-header-cell-total">₹ {{ totalDealer.earningrebate }}</p>
            <p class="tab-header-cell-total">+</p>
            <p class="tab-header-cell-total">₹ {{ totalDealer.proxy }}</p>
            <p class="tab-header-cell-total">+</p>
            <p class="tab-header-cell-total">₹ {{ totalDealer.otherAmount }}</p>
          </div>
        </div>
      </div>

      <div class="content" @scroll="handleScroll">
        <div class="tab-header">
          <div class="tab-header-row">
            <p class="tab-header-cell">Data</p>
            <p class="tab-header-cell">Invitation</p>
            <p class="tab-header-cell">Invitation Recharge</p>
            <p class="tab-header-cell">Affiliate Earnings</p>
            <p class="tab-header-cell">Other</p>
          </div>
        </div>
        <div class="tab-content">
          <div v-for="(item,index) in commissionList" :key="item"
               :class=" index % 2 === 0 ?'tab-content-row tab-row-color':'tab-content-row'">
            <p class="tab-content-cell">{{ item.dates }}</p>
            <p class="tab-content-cell">{{ item.earninginstall }}</p>
            <p class="tab-content-cell">{{ item.earningrebate }}</p>
            <p class="tab-content-cell">{{ item.proxy }}</p>
            <p class="tab-content-cell">{{ item.otherAmount }}</p>
          </div>
          <div>
          </div>
        </div>
      </div>

    </div>
  </LandscapeMode>
</template>

<style scoped>

.my-affiliate-contain {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.title {
  padding: 1rem 3rem;
}

.calculate-tab {
  display: table;
  table-layout: fixed;
  padding: 0 5%;
}

.calculate-title {
  width: 128rem;
  position: sticky;
  background: linear-gradient(rgba(127, 83, 238, 1), rgba(211, 110, 66, 1));
  top: 0;
  border: 0.2rem solid rgba(251, 235, 110, 1);
  border-radius: 0.6rem;
  margin-bottom: 1rem;
}

.tab-header-total {
  display: table;
  width: 128rem;

  height: 3rem;

}

.tab-header-row-total {
  display: table-row;
  text-align: center;
}

.tab-header-cell-total-title {
  display: table-cell;
  width: 20%;
  color: rgba(255, 255, 255, 0.5);
  line-height: 4.6rem;
  text-align: center;
  font-size: 2rem;
}

.tab-header-cell-total {
  display: table-cell;
  width: 14rem;
  color: rgba(255, 255, 255, 1);
  font-weight: bolder;
  text-shadow: 0.2rem 0.2rem 0 #77271C, 1px 0.2rem 0 #77271C, 0.2rem 1px 0 #77271C, 1px 1px 0 #77271C;
  line-height: 4.6rem;
  text-align: center;
  font-size: 3.4rem;
}

.tab-header-cell-total-yellow {
  color: #FBEB6E;
  text-shadow: 0.2rem 0.2rem 0 #77271C, 1px 0.2rem 0 #77271C, 0.2rem 1px 0 #77271C, 1px 1px 0 #77271C;
}

.content {
  width: 128rem;
  height: 50rem;
  background: rgba(119, 39, 28, 0.3);
  overflow: auto;
}

.tab-header {
  display: table;
  width: 100%;
  background: #EE80AD;
  table-layout: fixed;
  height: 4.6rem;
  position: sticky;
  top: 0;
}

.tab-header-row {
  display: table-row;
}

.tab-header-cell {
  display: table-cell;
  color: #1E0E0E;
  line-height: 4.6rem;
  text-align: center;
  font-size: 2rem;
  border-right: 0.2rem solid #77271C;
}

.tab-content {
  display: table;
  width: 100%;
  table-layout: fixed;
  height: 4.6rem;

}

.tab-content-row {
  display: table-row;
}

.tab-content-cell {
  display: table-cell;
  color: white;
  line-height: 4.6rem;
  text-align: center;
  font-size: 2rem;
  border-right: 0.2rem solid #77271C;
}

.tab-content-cell-green {
  color: #31FF61;
}

.tab-content-cell-gold {
  color: #FBEB6E;
}

.tab-row-color {
  background: rgba(243, 178, 86, 0.2);
}
</style>
