<script>

</script>

<template>
  <div class="swiper-container banner-contain">
    <div class="swiper-wrapper">
      <slot></slot>
    </div>
    <div class="swiper-pagination"></div>
  </div>
</template>

<style scoped>

</style>
