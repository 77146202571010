<style scoped>
@import '@/components/withdraw/vars.scss';

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999999;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(2px);

  .loading-icon {
    position: absolute;
    top: 52rem;
    left: 33.6rem;
    margin: 0 auto;
    width: 7.6rem;
    height: 7.8rem;
    background-image: url('~@/assets/loading-icon.png');
  }

  .loading-loading {
    position: absolute;
    width: 15rem;
    height: 15.8rem;
    top: 48rem;
    left: 30rem;
    margin: 0 auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('~@/assets/loading.png');
    animation: loading 2s linear infinite;
  }

  .loading-text {
    position: absolute;
    top: 65.8rem;
    left: 29.6rem;
    font-size: 3.6rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: #FAE7C0;
  }
}
</style>

<template>
  <div class="loading" v-if="loading">
    <div class="loading-icon"></div>
    <div class="loading-loading"></div>
    <div class="loading-text">{{ 'Loading' + '...' }}</div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>
