import { createStore } from 'vuex'
import http from '@/utils/http'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  plugins: [createPersistedState({
    reducer: (state, paths) => {
      return {
        code: state.code,
        token: state.token
      }
    }
  })],
  state: {
    uid: '',
    channel: '',
    pn: '',
    aid: '',
    pkg: '',
    code: '',
    token: '',
    linkUrl: '',
    showLogin: false,
    forceLogin: false,
    appConfig: [],
    tabData: [],
    currentTabPage: '',
    shareData: [],
    user: null,
    finish_task_count: 0,
    shareTip: false,
    mobile: '',
    pnum: '',
    params: {},
  },
  getters: {},
  mutations: {
    appConfig (state, data) {
      state.appConfig = data
    },
    linkUrl (state, data) {
      state.linkUrl = data
    },
    user (state, data) {
      state.user = data
    },
    pn (state, data) {
      state.pn = data
    },
    pkg (state, data) {
      state.pkg = data
    },
    channel (state, data) {
      state.channel = data
    },
    gaid (state, data) {
      state.gaid = data
    },
    aid (state, data) {
      state.aid = data
    },
    uid (state, data) {
      state.uid = data
    },
    mobile (state, data) {
      state.mobile = data
    },
    code (state, data) {
      state.code = data
    },
    token (state, data) {
      state.token = data
    },
    showLogin (state, data) {
      state.showLogin = data
    },
    forceLogin (state, data) {
      state.forceLogin = data
    },
    finish_task_count (state, data) {
      state.finish_task_count = data
    },
    shareData (state, data) {
      state.shareData = data
    },
    shareTip (state, data) {
      state.shareTip = data
    },
    tabData (state, data) {
      state.tabData = data
    },
    currentTabPage (state, data) {
      state.currentTabPage = data
    },
    params (state, data) {
      state.params = data
    },
    pnum (state, data) {
      state.params = data
    }
  },
  actions: {
    appConfig (store) {
      const params = JSON.parse(localStorage.getItem('h5_sdk_params'))
      console.log('store params::', params)
      store.commit('params', params)
      store.commit('uid', params.uid)
      store.commit('pnum', params.pnum)
      store.commit('pn', params.pn)
      store.commit('pkg', params.pkg)
      store.commit('channel', params.channel)
      store.commit('gaid', params.gaid)
      store.commit('aid', params.aid)
      store.commit('mobile', params.pnum)
      http.getHttp('/config/app?type=0').then((resp) => {
        store.commit('appConfig', resp.data)
      })
    }
  },
  modules: {}
})
