<script>

import { mapState } from 'vuex'

export default {
  methods: {
    dismissDialog () {
      this.$emit('task')
    },
    showShareTips () {
      this.dismissDialog()
      this.$store.commit('shareTip', true)
    }
  },
  computed: {
    ...mapState(['finish_task_count'])
  },
  data () {
    return {
      taskPics: [require('@/assets/task_nomal.png'), require('@/assets/task_pass.png')],
      taskAward: [5, 5, 10, 15, 20]
    }
  }
}
</script>

<template>
  <div class="bg">
    <div class="title_contain block_center">
      <p>Task 1 - Share the game to friends</p>
    </div>
    <div class="task_all_contain">

      <div v-for="index in 5" :key="index" class="task_contain">
        <img src="@/assets/task_seal.png" class="task_seal" v-if="index <= this.finish_task_count">
        <img v-bind:src="(index-1 <= this.finish_task_count) ? taskPics[1] : taskPics[0]" class="task_icon">
        <p class="task_name"> Task {{ index }} </p>
        <p class="task_amount"> RS.{{ taskAward[index - 1] }} </p>
      </div>
    </div>
    <div class="task_invite block_center" @click="showShareTips">
      <p>INVITE NOW</p>
    </div>

    <img src="@/assets/close_circle.png" class="close" @click="dismissDialog">
  </div>
</template>

<style scoped>
.bg {
  width: 114.3rem;
  height: 55.2rem;
  align-self: start;
  background-image: url("@/assets/task_dialog_bg.png");
  background-size: 100% 100%;
  margin-top: 5.4rem;
  position: relative;
}

.title_contain {
  width: 69rem;
  height: 5.4rem;
  background-image: url("@/assets/task_title_bg.png");
  background-size: 100% 100%;
  position: absolute;
  right: 8.2rem;
  top: 17.5rem;
}

.block_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title_contain > p {
  font-size: 3.4rem;
  color: white;
  text-shadow: 0.1rem 0.1rem 0 #A540B7, 0.1rem 0.1rem 0 #A540B7, 0.1rem 0.1rem 0 #A540B7, 0.1rem 0.1rem 0 #A540B7;
  font-style: italic;
  font-family: Arial, serif;
}

.task_all_contain {
  width: 92rem;
  height: 20.9rem;
  position: absolute;
  right: 4.6rem;
  bottom: 9rem;
  display: flex;
  align-content: center;
  justify-content: center;
}

.task_contain {
  width: 25rem;
  height: 100%;
  position: relative;
}

.task_icon {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
}

.task_seal {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.task_name {
  color: white;
  position: absolute;
  left: 6rem;
  top: 1.2rem;
  font-size: 2.8rem;
}

.task_amount {
  color: #F9E072;
  position: absolute;
  left: 5.8rem;
  bottom: 1.7rem;
  font-size: 2.8rem;
}

.task_invite {
  position: absolute;
  left: 37rem;
  bottom: -4rem;
  background-image: url("@/assets/btn_green_round.png");
  background-size: 100% 100%;
  width: 39.7rem;
  height: 9.5rem;

}

.task_invite:active {
  opacity: 0.6;
}

.task_invite > p {
  font-size: 3.4rem;
  align-self: center;
  color: white;
  margin-bottom: 2rem;
  font-weight: bolder;
  text-shadow: 0.1rem 0.1rem 0 #199856, 0.1rem 0.1rem 0 #199856, 0.1rem 0.1rem 0 #199856, 0.1rem 0.1rem 0 #199856;
}

.close {
  width: 5rem;
  height: 5rem;
  margin-top: 5rem;
  background-size: 100% 100%;
  position: absolute;
  right: 0;
  top: 0;
}

</style>
