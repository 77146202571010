<script setup>

</script>

<template>
  <div class="portrait-mode">
    <div class="portrait-contain">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped>

@media screen and (orientation: landscape) {
  .portrait-mode {
    height: 100vw;
    width: 100vh;
    transform: rotate(90deg);
    transform-origin: 0 0;
    top: 0;
    left: 100%;
    position: absolute;
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .portrait-contain {
    width: 90vh;
    height: 160vw;
    display: flex;
    align-items: center;
    align-self: center;
    justify-items: center;
    align-content: center;
    justify-content: center;
  }
}

@media screen and (orientation: portrait) {
  .portrait-mode {
    width: 100%;
    height: 100%;
  }
  .portrait-contain {
    width: 100vw;
    height: 100vh;
  }
}

</style>
