<style scoped>
.exit-wrapper {
  position: absolute;
  top: 3.5rem;
  left: 3rem;

  z-index: 100;

  .icon {
    width: 100%;
  }
  .text {
    font-size: 3.3rem;
    font-family: PingFang SC;
    text-transform: capitalize;
    height: 5.5rem;
    line-height: 5.5rem;
    font-weight: bold;
    color: #FFFFFF;
  }
}
</style>

<template>
  <div class="exit-wrapper" @click="exit">

    <div class="text">cancel</div>
  </div>
</template>

<script>
import Context from '@/utils/context'

export default {
  props: {
    page: String
  },
  data () {
    return {
      isWebview: Context.isWebview(),
      hasClosePage: Context.hasClosePage()
    }
  },
  methods: {
    exit () {
      if (this.page == 'home') {
        // 'tc_home_back_002': ' '
        this.$log.info('tc_home_back_002')
      }
      if (this.hasClosePage) {
        Context.closePage()
      } else if (this.page == 'home' && this.isWebview) {
        Context.exitWebview()
      } else {
        this.$router.push('/withdraw')
      }
    }
  }
}
</script>
