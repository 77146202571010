<template>
  <div class="page">

    <div class="content">

      <div class="dialog-contain">
        <div class="bank-container" v-if="!isOrange">

          <div class="dialog-top">
            <p class="dialog-title">Modify Bank Card</p>
            <div class="dialog-close" @click="closeDialog"/>
          </div>


          <div class="upi-container-number">
            <img class="withdraw-add-1" src="@/assets/withdraw-add-1.png">
            <div class="withdraw-add-91">+91</div>
            <input type="tel" v-model.number="bankPhoneNumber" placeholder="Phone number"
                   class="upi-phone-number-input" v-on:input="formatBankMobile"
                   maxlength="10">
          </div>

          <div class="upi-container-id">
            <img class="withdraw-add-2" src="@/assets/withdraw-add-2.png">
            <input type="text" v-model.trim="bankAccountHolderName" placeholder="Account Holder Name"
                   class="upi-id-input" v-on:input="change"
                   maxlength="20">
          </div>

          <div class="upi-container-id">
            <img class="withdraw-add-2" src="@/assets/withdraw-add-3.png">
            <input type="tel" v-model.trim="bankAccountNumber" placeholder="Account Number" class="upi-id-input"
                   v-on:input="change" maxlength="20">
          </div>

          <div class="upi-container-id">
            <img class="withdraw-add-2" src="@/assets/withdraw-add-4.png">
            <input type="tel" v-model.trim="bankConfirrnAccountNumber" placeholder="Confirrn Account Number"
                   class="upi-id-input" v-on:input="change" maxlength="20">
          </div>

          <div class="upi-container-id">
            <img class="withdraw-add-2" src="@/assets/withdraw-add-5.png">
            <input type="text" v-model.trim="bankIFSCCode" placeholder="IFSC Code" class="upi-id-input"
                   v-on:input="change" maxlength="20">
          </div>

          <div class="delete-contain" @click="withdrawModifyDelete">
            <p class="delete-p">Delete</p>
          </div>
        </div>
        <div class="confirm" @click="submit">
          <p class="confirm-p">Confirm</p>
        </div>
      </div>
    </div>


    <div class="upi-mask" v-if="isUpiMask">
      <div class="upi-asure-container">
        <div class="upi-asure-header">
          {{ 'Confirm Account' }}
        </div>
        <div class="upi-asure-line">
        </div>
        <div class="upi-asure-phone-number">
          <div class="upi-asure-phone-number-1">
            {{ 'Phone number' }}
          </div>
          <div class="upi-asure-phone-number-2">
            {{ upiPhoneNumber }}
          </div>
        </div>
        <div class="upi-asure-phone-number">
          <div class="upi-asure-phone-number-1">
            {{ 'UPI ID' }}
          </div>
          <div class="upi-asure-phone-number-2">
            {{ upiId }}
          </div>
        </div>
        <div class="upi-asure-button">
          <button class="upi-asure-modify" @click="modify">
            {{ 'Modify' }}
          </button>
          <button class="upi-asure-correct" @click="correct">
            {{ 'Correct' }}
          </button>
        </div>
      </div>
    </div>

    <div class="bank-mask" v-if="isBankMask">
      <div class="bank-asure-container">
        <div class="upi-asure-header">
          {{ 'Confirm Account' }}
        </div>
        <div class="upi-asure-line">
        </div>
        <div class="upi-asure-phone-number">
          <div class="upi-asure-phone-number-1">
            {{ 'Phone number' }}
          </div>
          <div class="upi-asure-phone-number-2">
            {{ bankPhoneNumber }}
          </div>
        </div>
        <div class="upi-asure-phone-number">
          <div class="upi-asure-phone-number-1">
            {{ 'Name' }}
          </div>
          <div class="upi-asure-phone-number-2">
            {{ bankAccountHolderName }}
          </div>
        </div>
        <div class="upi-asure-phone-number">
          <div class="upi-asure-phone-number-1">
            {{ 'Account Number' }}
          </div>
          <div class="upi-asure-phone-number-2">
            {{ bankAccountNumber }}
          </div>
        </div>
        <div class="upi-asure-phone-number">
          <div class="upi-asure-phone-number-1">
            {{ 'IFSC Code' }}
          </div>
          <div class="upi-asure-phone-number-2">
            {{ bankIFSCCode }}
          </div>
        </div>
        <div class="upi-asure-button">
          <button class="upi-asure-modify" @click="modify">
            {{ 'Modify' }}
          </button>
          <button class="upi-asure-correct" @click="correct">
            {{ 'Correct' }}
          </button>
        </div>
      </div>
    </div>
    <!--    <div class="withdraw-modify-delete" @click="withdrawModifyDelete">-->
    <!--      <img src="@/assets/withdraw-modify-1.png">-->
    <!--    </div>-->
  </div>
</template>

<script>
import 'ant-design-vue/lib/message/style'
import CancelButton from '@/components/withdraw/Cancel'
import Context from '@/utils/context'
import User from '@/components/withdraw/user'
import buriedpoint from '@/utils/buriedpoint'
import toast from '@/utils/toast'

export default {
  components: {
    CancelButton
  },
  data () {
    return {
      tempUpiBankList: [],
      deleteFlag: false,
      cardUpi: 'UPI',
      requires: 'Requires your UPI ID',
      isUpiMask: false,
      isBankMask: false,
      upiBankInformation: {},
      isSubmit: false,
      isOrange: false,
      isWebview: Context.isWebview(),
      hasClosePage: Context.hasClosePage(),
      bankIFSCCode: '',
      bankConfirrnAccountNumber: '',
      bankAccountNumber: '',
      bankAccountHolderName: '',
      bankPhoneNumber: '',
      upiPhoneNumber: '',
      upiId: ''
    }
  },
  computed: {},
  watch: {},
  methods: {
    rebpoint (key, value) {
      let now = (new Date().getTime() / 1000).toFixed(0)
      buriedpoint
        .point(
          this.pointCode,
          new Date().getTime(),
          '',
          this.pointPkg,
          this.pointChannel,
          'hy',
          '',
          this.pointPlatform,
          this.pointAid,
          this.pointGaid,
          this.pointTaurusStatUuid,
          this.pointUid,
          'event',
          [
            {
              ts: now,
              eventKey: key,
              eventValue: value,
            },
          ]
        )
        .then((res) => {
          if (res.code === 0) {
            console.log('--tcpa_w/d_(n)_cfm', 'tcpa_w/d_(n)_cfm')
          }
        })
        .catch((err) => {
          console.log('point', err)
          // return Promise.reject(err);
        })
    },
    withdrawModifyDelete () {
      User.deleteUpiAndBank(this.upiBankInformation.id)
        //mobile, code, name, aid, pcode, referrer
        .then(res => {
          console.log(res)
          this.bankIFSCCode = ''
          this.bankConfirrnAccountNumber = ''
          this.bankAccountNumber = ''
          this.bankAccountHolderName = ''
          this.bankPhoneNumber = ''
          this.upiPhoneNumber = ''
          this.upiId = ''
          this.deleteFlag = true
          // var tempUpiBankList = [] ;
          this.tempUpiBankList = []
          User.queryUpiAndBank().then(data => {
            this.tempUpiBankList = data.data
            localStorage.removeItem('upiBankInformation')
            if (this.tempUpiBankList.length > 0) {
              this.$router.push({
                path: '/withdraw',
                query: this.tempUpiBankList[0]
              })
              localStorage.setItem('upiBankInformation', JSON.stringify(this.tempUpiBankList[0]))
              localStorage.setItem('isWithdrawAccessImg', this.tempUpiBankList[0].id)
            } else {
              this.$router.push({
                path: '/withdraw',
                query: {}
              })
            }
          })

          localStorage.setItem('cardUpi', this.cardUpi)
          localStorage.setItem('requires', this.requires)
        })
        .catch(() => {
        })
    },
    padding (num) {
      if (num < 10) {
        return `0${num}`
      }
      return `${num}`
    },
    timestampToTime (timestamp) {
      var date = new Date(timestamp) //
      var Y = this.padding(date.getFullYear())
      var M = this.padding(date.getMonth() + 1)
      var D = this.padding(date.getDate())
      var h = this.padding(date.getHours())
      var m = this.padding(date.getMinutes())
      var s = this.padding(date.getSeconds())
      return `${Y}-${M}-${D} ${h}:${m}:${s}`
    },
    saveUpiBank () {
      if (this.isOrange == true) {
        this.mobile = this.upiPhoneNumber
      } else {
        this.mobile = this.bankPhoneNumber
      }

      User.saveOrUpdateUpiAndBank(
        this.upiBankInformation.id,
        this.userId,
        this.upiId,
        this.bankAccountNumber,
        this.bankAccountHolderName,
        this.bankIFSCCode,
        this.mobile,
        this.timestampToTime(new Date().getTime())
      )
        //mobile, code, name, aid, pcode, referrer
        .then(res => {
          console.log(res)
          this.upiBankInformation.userId = this.userId
          this.upiBankInformation.upiAccountNumber = this.upiId
          this.upiBankInformation.bankAccountNumber = this.bankAccountNumber
          this.upiBankInformation.bankAccountHolderName = this.bankAccountHolderName
          this.upiBankInformation.accountIfscCode = this.bankIFSCCode
          this.upiBankInformation.mobile = this.mobile
          this.upiBankInformation.createTime = this.timestampToTime(new Date().getTime())

          // localStorage.setItem('upiBankInformation',JSON.stringify(this.upiBankInformation));
          console.log('---withdraw-modify upiBankInformation---' + JSON.stringify(this.upiBankInformation))
          localStorage.setItem('upiBankInformation', JSON.stringify(this.upiBankInformation))
          localStorage.setItem('isWithdrawAccessImg', res.data.id)
          this.$router.push({
            path: '/withdraw',
            query: this.upiBankInformation
          })
        })
        .catch(() => {
        })
    },
    correct () {
      this.isBankMask = false
      this.isUpiMask = false
      this.saveUpiBank()
    },
    modify () {
      this.isBankMask = false
      this.isUpiMask = false
    },
    closeDialog () {
      this.$router.push('/withdraw')
    },
    submit () {
      if (this.isOrange) {
        if (this.upiId.search('@') == -1 || this.upiId == '') {
          toast.show('Wrong UPI account, please check and input again.', 'fail')
          return false
        }
      } else {
        let m = /^[A-Z]{4}[0]{1}/
        // console.log(m.test('AZRE0R'));
        if (this.bankAccountNumber.length < 9 || this.bankAccountNumber == '') {
          toast.show('Wrong bank account, please check and input again.', 'fail')
          return false
        } else if (this.bankIFSCCode.length != 11 || this.bankIFSCCode == '' || !m.test(this.bankIFSCCode)) {
          toast.show('Wrong IFSC code, please check and input again.', 'fail')
          return false
        }
      }
      if (this.isOrange == true) {
        this.isUpiMask = true
      } else {
        this.isBankMask = true
      }
    },
    swithWithdrawalMethod () {
      if (this.isOrange == false) {
        this.isOrange = true
      } else {
        this.isOrange = false
      }
      console.log(this.isOrange)
    },
    exit () {
      if (this.hasClosePage) {
        Context.closePage()
      } else if (this.page == 'home' && this.isWebview) {
        Context.exitWebview()
      } else {
        this.$router.push('/withdraw')
      }
    },

    formatUpiMobile (e) {
      e.target.value = e.target.value.replace(/\D+/g, '')
      if (e.target.value > 9999999999 || e.target.value.length > 10) {
        e.target.value = this.upiPhoneNumber
      }
      this.upiPhoneNumber = e.target.value.toString().replace(/\s/g, '')
      let value = this.upiPhoneNumber.toString().replace(/(\d{4})(?=\d)/g, '$1 ')
      console.log(value, this.upiPhoneNumber)
      e.target.value = value
      if (this.isOrange && !this.upiPhoneNumber) {
        this.isSubmit = false
      }
    },
    formatBankMobile (e) {
      e.target.value = e.target.value.replace(/\D+/g, '')
      if (e.target.value > 9999999999) {
        e.target.value = this.bankPhoneNumber
      }
      this.bankPhoneNumber = e.target.value.toString().replace(/\s/g, '')
      let value = this.bankPhoneNumber.toString().replace(/(\d{4})(?=\d)/g, '$1 ')
      console.log(value, this.bankPhoneNumber)
      e.target.value = value
      if (!this.isOrange && !this.bankPhoneNumber) {
        this.isSubmit = false
      }
    },

    change () {
      if (this.isOrange == true) {
        if (this.upiPhoneNumber == '') {
          this.isSubmit = false
        } else if (this.upiId == '') {
          this.isSubmit = false
        } else {
          this.isSubmit = true
        }
      } else {
        if (this.bankIFSCCode == '') {
          this.isSubmit = false
        } else if (this.bankConfirrnAccountNumber == '') {
          this.isSubmit = false
        } else if (this.bankAccountNumber == '') {
          this.isSubmit = false
        } else if (this.bankAccountHolderName == '') {
          this.isSubmit = false
        } else if (this.bankPhoneNumber == '') {
          this.isSubmit = false
        } else {
          this.isSubmit = true
        }
      }

      console.log(this.upiPhoneNumber)
    },
    fun () {
      if (this.deleteFlag == true) {
        if (this.tempUpiBankList.length > 0) {
          // this.$router.push({path: '/withdraw', query: this.tempUpiBankList[0]});
        } else {
          // this.$router.push({path:'/withdraw', query: {}});
        }
      } else {
        // this.$router.push({path:'/withdraw', query:this.upiBankInformation});
      }
    }
  },
  mounted () {
    //'tcpa_w/d_Refer & Earn'
    // this.$log.info('tcpa_w/d_Refer & Earn')

    this.isSubmit = true
    // this.upiBankInformation = this.$route.query;
    this.upiBankInformation = JSON.parse(localStorage.getItem('modifyUpiBankInformation'))
    this.userId = this.upiBankInformation.userId
    if (this.upiBankInformation.upiAccountNumber !== null && this.upiBankInformation.upiAccountNumber !== undefined && this.upiBankInformation.upiAccountNumber !== '') {
      this.isOrange = true
      this.upiId = this.upiBankInformation.upiAccountNumber
      this.upiPhoneNumber = this.upiBankInformation.mobile
    } else {
      this.isOrange = false
      this.bankPhoneNumber = this.upiBankInformation.mobile
      this.bankAccountHolderName = this.upiBankInformation.bankAccountHolderName
      this.bankAccountNumber = this.upiBankInformation.bankAccountNumber
      this.bankConfirrnAccountNumber = this.upiBankInformation.bankAccountNumber
      this.bankIFSCCode = this.upiBankInformation.accountIfscCode
      this.rebpoint('tcpa_w/d_Refer & Earn', '')
    }
    console.log('---withdraw-modify params---' + JSON.stringify(this.upiBankInformation.id))

    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL)
      window.addEventListener('popstate', this.fun, false)
    }
  },

  destroyed () {
    window.removeEventListener('popstate', this.fun, false)
  }
}
</script>

<style scoped>


.page {
  position: relative;
  background-color: rgba(255, 255, 255, 0.8);
  height: 100vh;
  width: 100vw;
  font-family: 'PingFang SC';
}

.delete-contain {
  margin: 2rem;
  height: 9.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FF72011A;;
}

.delete-p {
  font-size: 3.2rem;
  color: #FF7201;
  border-radius: 0.6rem;
}


.dialog-title {
  color: #1B1B1B;
  font-size: 2.8rem;
  margin-left: 2rem;
  margin-top: 2rem;
  font-weight: bold;
}

.dialog-close {
  width: 3rem;
  margin-top: 2rem;
  height: 3rem;
  padding: 1.5rem;
  background-image: url("@/assets/withdraw/close.png");
  background-size: 100% 100%;
  margin-right: 2rem;
}

.dialog-top {
  width: 100rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.confirm {
  position: fixed;
  display: flex;
  bottom: 0.5rem;
  width: 96rem;
  margin: 1rem 2rem;
  height: 10rem;
  border-radius: 0.6rem;
  align-content: center;
  align-items: center;
  justify-items: center;
  justify-content: center;
  background: #367BE4;
}

.confirm-p {
  color: white;
  font-size: 2.8rem;
  font-weight: bolder;
}

.confirm:active {
  opacity: 0.6;
}

.flex {
  display: flex;
}

.flex-r {
  flex-direction: row;
}

.flex-c {
  flex-direction: column;
}

.flex-jc-sa {
  justify-content: space-around;
}

.flex-jc-sb {
  justify-content: space-between;
}

.flex-ac {
  align-items: center;
}

.head {
  background: #2a4a96;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.6rem;
  height: 10.0rem;
  text-align: center;

  .title {
    margin-left: 6.0rem;
    line-height: 6.0rem;
    height: 6.0rem;
    font-size: 3.6rem;
    text-align: center;
    display: flex;
    align-items: center;
    color: #fff;
    position: absolute;
    left: 2.4rem;
  }

  img {
    width: 4.2rem;
    height: 4.2rem;
  }
}

.content {
  position: relative;
  display: flex;
  justify-content: center;
}

.withdrawal-account-title {
  font-size: 2.4rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #9f9f9f;
  margin-left: 3.1rem;
  margin-top: 2.4rem;
}

.withdrawal-method {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-left: 2.0rem;
  padding-right: 2.0rem;
  margin-top: 2.0rem;
}

.withrawalBtnGray {
  width: 34.4rem;
  height: 7.4rem;
  background: #e5e5e5;
  box-shadow: 0px .3rem 0px 0px rgba(74, 5, 0, 0.02);
  border-radius: .6rem;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 3.2rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #939393;
}

.withdrawalBtnOrange {
  width: 34.4rem;
  height: 7.4rem;
  background: linear-gradient(90deg, #256dd0, #378ded);
  border-radius: .6rem;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 3.6rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffffff;
}

input {
  background: none;
  outline: none;
  border: 0px;
}

.upi-container {
  width: 75.0rem;
  height: 26.1rem;
  background: #ffffff;
  box-shadow: 0px .3rem 0px 0px rgba(74, 5, 0, 0.02);
  border-radius: .6rem;
  margin-top: 2.6rem;
}

.upi-container-number {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin: 2rem;
  height: 9.0rem;
  border: 0.15rem #C5C5C7 solid;
  border-radius: 0.6rem;

}

.withdraw-add-1 {
  width: 5.0rem;
  height: 5.0rem;
  margin-left: 2rem;
}

.withdraw-add-91 {
  font-size: 3.6rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #222222;
  margin-left: 4.4rem;
  margin-right: 6.3rem;
}

.upi-phone-number-input {
  width: 50.6rem;
  height: 13.0rem;

  font-size: 3.6rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000000;
}

.upi-phone-number-input::placeholder {
  font-size: 2.8rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #b3b3b3;
}

.withdraw-add-2 {
  width: 5.0rem;
  height: 5.0rem;
  margin-right: 4.5rem;
  margin-left: 2rem;
}

.upi-id-input {
  width: 62.5rem;
  height: 13.0rem;

  font-size: 3.6rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000000;
}

.upi-id-input::placeholder {
  font-size: 2.8rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #b3b3b3;
}

.upi-container-id {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin: 2rem;
  height: 9.0rem;
  border: 0.15rem #C5C5C7 solid;
  border-radius: 0.6rem;
}

.dialog-contain{
  display: flex;
  width: 100rem;
  height: 90vh;
  flex-direction: column;
}


.bank-container {
  width: 100rem;
  height: 90vh;
  background: #ffffff;
  box-shadow: 0px .3rem 0px 0px rgba(74, 5, 0, 0.02);
  border-radius: 0.6rem;
  overflow: scroll;
  top: 10vh;
  position: relative;
  padding-bottom: 12rem;
}

.exit-wrapper {
  position: absolute;
  top: 2.5rem;
  right: 2.3rem;
  z-index: 100;

  .textSubmit {
    width: 13.5rem;
    height: 5.5rem;
    line-height: 5.5rem;
    text-transform: capitalize;
    background: #2a4a96;
    border-radius: .6rem;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 3.3rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: #ffffff;
    opacity: 0.46;
  }

  .textSubmit2 {
    width: 13.5rem;
    height: 5.5rem;
    line-height: 5.5rem;
    text-transform: capitalize;
    background: #ffffff;
    box-shadow: .2rem .2rem .3rem 0px rgba(0, 0, 0, 0.21);
    border-radius: .6rem;

    font-size: 3.3rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: #ddcac4;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.bank-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
}

.upi-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
}

.bank-asure-container {
  width: 62.1rem;

  background: #ffffff;
  border-radius: .6rem;
}

.upi-asure-container {
  width: 62.1rem;

  background: #ffffff;
  border-radius: .6rem;
}

.upi-asure-header {
  height: 11.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 3.8rem;
  font-family: PingFang SC;
  font-weight: 800;
  color: #000000;
}

.upi-asure-line {
  width: 57.8rem;
  height: .2rem;
  background: #f4f4f4;
  margin: 0 auto;
}

.upi-asure-phone-number {
  margin-top: 4.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 4.6rem;
  padding-right: 4.6rem;
}

.upi-asure-phone-number-1 {
  font-size: 3.0rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #939393;
}

.upi-asure-phone-number-2 {
  font-size: 3.3rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #000000;
  max-width: 42.0rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.upi-asure-button {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 2.5rem;
  padding-left: 2.5rem;
  margin-top: 5.0rem;
  margin-bottom: 4.0rem;
}

.upi-asure-modify {
  width: 27.4rem;
  height: 7.4rem;
  border: .2rem solid #cccccc;
  border-radius: 1.0rem;

  font-size: 3.3rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #787878;

  display: flex;
  align-items: center;
  justify-content: center;
}

.upi-asure-correct {
  width: 27.2rem;
  height: 7.4rem;
  background: linear-gradient(90deg, #256dd0, #378ded);
  border-radius: 1.0rem;

  font-size: 3.3rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffffff;

  display: flex;
  align-items: center;
  justify-content: center;
}

.withdraw-modify-delete {
  position: absolute;
  bottom: 4.6rem;
  left: 3.5rem;
}

.withdraw-modify-delete > img {
  width: 68.1rem;
  height: 10.1rem;
}
</style>
