import message from 'ant-design-vue/lib/message'
import 'ant-design-vue/lib/message/style'

import request from '@/utils/request'

class Context {
  static isWebview () {
    return window.share != undefined
  }

  static hasClosePage () {
    console.log('henry: check sdk has closePage', !!window.closePage)
    return !!window.closePage
  }

  static closePage () {
    console.log('henry: close page by sdk')
    window.closePage.closePage()
  }

  static shortnameVungo = 'a'
  static shortnameAiwan = 'aw'

  static getShortname () {
    if (Context.isAiwan()) {
      return Context.shortnameAiwan
    }
    return Context.shortnameVungo
  }

  static shareDomains = ['hh1.pw', 'hh3.pw', 'hh7.pw', 'nn4.pw', 'nn5.pw']

  static randomDomain () {
    return Context.shareDomains[Math.floor(Math.random() * Context.shareDomains.length)]
  }

  static isNotAndroid11OrAbove () {
    var version = 0
    try {
      console.log('get ua：' + navigator.userAgent)
      var ua = navigator.userAgent.toLowerCase()
      if (ua.indexOf('android') > 0) {
        var reg = /android [\d._]+/gi
        var v_info = ua.match(reg)
        version = (v_info + '').replace(/[^0-9|_.]/ig, '').replace(/_/ig, '.')
        version = parseInt(version.split('.')[0])
      }
    } catch (e) {
      console.log(e)
    }
    // return version <= 100;
    return version <= 10
  }

  static callAndroidShareWithLangNew (platform, img, code = 'default', apks = [], title = '', link = '') {
    console.log('click share:' + platform, img, code, apks, title, link)
    let isNotAndroid11OrAbove = Context.isNotAndroid11OrAbove()
    console.log('isNotAndroid11OrAbove:' + isNotAndroid11OrAbove + ', share platform:' + platform)
    let shareApks = (isNotAndroid11OrAbove && platform == 'com.whatsapp' && apks != null && apks.length > 0) ? apks : []
    let shareTitle = 'Taurus'
    let lang = localStorage.getItem('language') || 'en'
    let url = link.replace('{lang}', lang)

    // console.log('exec share\nplatform::', platform, '\ncode::', code, '\nlang::', lang, '\nimg::', img, '\napks::', shareApks, '\nurl::', url);
    localStorage.setItem('cg_shared', 1)
    // alert(platform + "," + url + "," + shareTitle);

    console.log('test:' + (platform == 'com.whatsapp' && !isNotAndroid11OrAbove))

    if (!window.share) {
      if (platform == 'com.facebook.katana' || platform == 'com.facebook.orca') {
        console.log('share to facebook from website, url::', url)
        // let href = 'https://www.taurus.cash/share_fbm.html?link=' + encodeURIComponent(url);
        // window.open(href, "_blank");
        FB.ui({
          method: 'share',
          href: url
        }, function (response) {
          message.info({
            content: 'Sharing successful'
          })
          console.log(response)
        })
        return 0
      } else if (platform == 'com.whatsapp') {
        let href = 'https://api.whatsapp.com/send?text=' + encodeURIComponent(url)
        // let href = 'intent://send?text=' + encodeURIComponent(url) + '#Intent;scheme=whatsapp;package=com.whatsapp;end';
        // let href = 'whatsapp://send?text=' + encodeURIComponent(url);
        window.open(href, '_blank')
        message.info({
          content: 'Sharing successful'
        })
        return 0
      } else {
        if (window.navigator.share) {
          localStorage.setItem('share_ts', new Date().getTime())
          navigator.share({
            title: shareTitle,
            text: url,
            url: url,
          })
            .then(() => {
              console.log('Share completed successfuly')
              return 0
            })
            .catch((error) => {
              console.log(`share failed: ${error}`)
              return 1
            })
          return 0
        } else {
          console.log('no browser share')
          return 1
        }
      }
    }

    if (platform == 'default') {
      platform = ''
    }
    // skip facebbok images
    if (platform == 'com.facebook.katana' || platform == 'com.facebook.orca') {
      img = ''
    }
    // skip WhatsApp images when adroid version > 11
    if (platform == 'com.whatsapp' && !isNotAndroid11OrAbove) {
      img = ''
    }

    console.log('call share:, platform:' + platform + ', url: ' + url + ', img: ' + img + ', apks:' + JSON.stringify(shareApks))
    let ret = window.share.shareWeb(platform, url, img, shareTitle, JSON.stringify(shareApks))

    return ret
  }

  static callAndroidShareWithLang (platform, img, code = 'default', apks = [], title = '', link = '') {
    let domain = Context.randomDomain()
    let lang = localStorage.getItem('language') || 'en'
    let shortname = Context.getShortname()

    let shareUrlShort = `https://${domain}/${code}`
    let shareUrlFull = `https://www.taurus.cash/bizzclient/app/user/promotion/${code}`
    let hindiShareTextWA = `❤Install TeenPatti Plus to get 10Rs Free❤ \n👇👇👇👇 \n${shareUrlShort}?f=w&p=wa \nHi friend,great chance to win ₹1000+ at home now!!!\nInstall TeenPatti Plus get ₹10 free cash, play game to win ₹10000+ easily!\n\n❤10Rs Free पाने के लिए TeenPatti Plus install करें❤\n👇👇👇👇\n${shareUrlShort}?f=w&p=wa \nहेलो दोस्तो , अब घर बैठे ₹ 1000 जीतने का सुनहरा मौका !!!\nइंस्टॉल करें TeenPatti Plus और ₹10 मुफ्त में पाये ,गेम खेलकर आप  एक लाख रुपये तक जीत सकते है।`
    let hindiShareTextFB = `${shareUrlFull}?f=w&p=fb&l=hi`
    let hindiShareTextDefault = `💵Play Teen Patti and win Real Cash!💵\n🔥Install and got bonus + ₹10🔥\n💰Daily winnings up to ₹ 7 Lakhs💰\n😍Super fast cash withdraw😍\n👇👇👇\n${shareUrlShort}?f=w&l=en\n\n💵तीन पत्ती गेम खेले और नगद जीते।!💵\n🔥Install करे और ₹ 10 का बोनस पाएँ 🔥\n💰प्रतिदिन 7 लाख तक जीत सकते है💰\n😍आसानी से  नगद निकासी😍\n👇👇👇\n${shareUrlShort}?f=w&l=hi`
    let enShareTextWA = `❤Install TeenPatti Plus to get 10Rs Free❤ \n👇👇👇👇 \n${shareUrlShort}?f=w&p=wa \nHi friend,great chance to win ₹1000+ at home now!!!\nInstall TeenPatti Plus get ₹10 free cash, play game to win ₹10000+ easily!\n\n❤10Rs Free पाने के लिए TeenPatti Plus install करें❤\n👇👇👇👇\n${shareUrlShort}?f=w&p=wa \nहेलो दोस्तो , अब घर बैठे ₹ 1000 जीतने का सुनहरा मौका !!!\nइंस्टॉल करें TeenPatti Plus और ₹10 मुफ्त में पाये ,गेम खेलकर आप  एक लाख रुपये तक जीत सकते है।`
    let enShareTextFB = `${shareUrlFull}?f=w&p=fb&l=en`
    let enShareTextDefault = `💵Play Teen Patti and win Real Cash!💵\n🔥Install and got bonus + ₹10🔥\n💰Daily winnings up to ₹ 7 Lakhs💰\n😍Super fast cash withdraw😍\n👇👇👇\n${shareUrlShort}?f=w\n\n💵तीन पत्ती गेम खेले और नगद जीते।!💵\n🔥Install करे और ₹ 10 का बोनस पाएँ 🔥\n💰प्रतिदिन 7 लाख तक जीत सकते है💰\n😍आसानी से  नगद निकासी😍\n👇👇👇\n${shareUrlShort}?f=w`

    if (Context.isAiwan()) {
      shareUrlShort = `https://${domain}/${shortname}/${code}`
      shareUrlFull = `https://www.taurus.cash/bizzclient/app/user/promotion/${shortname}/${code}`
      hindiShareTextWA = `❤Install TeenPatti Gold to get 10Rs Free❤ \n👇👇👇👇 \n${shareUrlShort}?f=w&p=wa \nHi friend,great chance to win ₹1000+ at home now!!!\nInstall TeenPatti Gold get ₹10 free cash, play game to win ₹10000+ easily!\n\n❤10Rs Free पाने के लिए TeenPatti Gold install करें❤\n👇👇👇👇\n${shareUrlShort}?f=w&p=wa \nहेलो दोस्तो , अब घर बैठे ₹ 1000 जीतने का सुनहरा मौका !!!\nइंस्टॉल करें TeenPatti Plus और ₹10 मुफ्त में पाये ,गेम खेलकर आप  एक लाख रुपये तक जीत सकते है।`
      hindiShareTextFB = `${shareUrlFull}?f=w&p=fb&l=hi`
      hindiShareTextDefault = `💵Play Teen Patti and win Real Cash!💵\n🔥Install and got bonus + ₹10🔥\n💰Daily winnings up to ₹ 7 Lakhs💰\n😍Super fast cash withdraw😍\n👇👇👇\n${shareUrlShort}?f=w&l=en\n\n💵तीन पत्ती गेम खेले और नगद जीते।!💵\n🔥Install करे और ₹ 10 का बोनस पाएँ 🔥\n💰प्रतिदिन 7 लाख तक जीत सकते है💰\n😍आसानी से  नगद निकासी😍\n👇👇👇\n${shareUrlShort}?f=w&l=hi`
      enShareTextWA = `❤Install TeenPatti Gold to get 10Rs Free❤ \n👇👇👇👇 \n${shareUrlShort}?f=w&p=wa \nHi friend,great chance to win ₹1000+ at home now!!!\nInstall TeenPatti Gold get ₹10 free cash, play game to win ₹10000+ easily!\n\n❤10Rs Free पाने के लिए TeenPatti Plus install करें❤\n👇👇👇👇\n${shareUrlShort}?f=w&p=wa \nहेलो दोस्तो , अब घर बैठे ₹ 1000 जीतने का सुनहरा मौका !!!\nइंस्टॉल करें TeenPatti Plus और ₹10 मुफ्त में पाये ,गेम खेलकर आप  एक लाख रुपये तक जीत सकते है।`
      enShareTextFB = `${shareUrlFull}?f=w&p=fb&l=en`
      enShareTextDefault = `💵Play Teen Patti and win Real Cash!💵\n🔥Install and got bonus + ₹10🔥\n💰Daily winnings up to ₹ 7 Lakhs💰\n😍Super fast cash withdraw😍\n👇👇👇\n${shareUrlShort}?f=w\n\n💵तीन पत्ती गेम खेले और नगद जीते।!💵\n🔥Install करे और ₹ 10 का बोनस पाएँ 🔥\n💰प्रतिदिन 7 लाख तक जीत सकते है💰\n😍आसानी से  नगद निकासी😍\n👇👇👇\n${shareUrlShort}?f=w`
    }

    request(`/app/user/promotion/click/${shortname}/${code}?platform=${platform}`, {}).then(res => {
      if (res.data.code === 0) {
        console.log('report click share ')
      }
    })

    console.log('click share:' + platform, img, code, apks, title, link)
    let _t = new Date().getTime()
    let url = ''
    let shareApks = null

    if (lang == 'hi') {
      if (platform == 'com.whatsapp') {
        url = hindiShareTextWA
      } else if (platform == 'com.facebook.katana' || platform == 'com.facebook.orca') {
        url = hindiShareTextFB
      } else {
        url = hindiShareTextDefault
      }
    } else {
      if (platform == 'com.whatsapp') {
        url = enShareTextWA
      } else if (platform == 'com.facebook.katana' || platform == 'com.facebook.orca') {
        url = enShareTextFB
      } else {
        url = enShareTextDefault
      }

    }
    if (platform == 'com.whatsapp') {
      if (apks != null && apks.length > 0) {
        // shareApks = apks;
        shareApks = []
      }
    } else if (platform == 'com.facebook.katana' || platform == 'com.facebook.orca') {
      if (url.startsWith('http') && url.indexOf('?') > 0) {
        url += '&_=' + _t
      }
      img = ''
    } else {
      if (url.startsWith('http') && url.indexOf('?') > 0) {
        url += '&_=' + _t
      }
      img = ''
    }
    let shareTitle = 'Taurus'
    if (Context.isAiwan()) {
      shareTitle = 'Taurus Aiwan'
    }

    console.log('exec share\nplatform::', platform, '\ncode::', code, '\nlang::', lang, '\nimg::', img, '\napks::', shareApks, '\nurl::', url)
    localStorage.setItem('cg_shared', 1)
    // alert(platform + "," + url + "," + shareTitle);
    if (!window.share) {
      if (platform == 'com.facebook.katana') {
        console.log('share to whatsapp from website, url::', url)
        // let href = 'https://www.taurus.cash/share_fbm.html?link=' + encodeURIComponent(url);
        // window.open(href, "_blank");
        FB.ui({
          method: 'share',
          href: url
        }, function (response) {
          message.info({
            content: 'Sharing successful'
          })
          console.log(response)
        })
        return 0
      } else if (platform == 'com.facebook.orca') {
        console.log('share to whatsapp from website, url::', url)
        // let href = 'https://www.taurus.cash/share_fbm.html?link=' + encodeURIComponent(url);
        FB.ui({
          method: 'share',
          href: url
        }, function (response) {
          message.info({
            content: 'Sharing successful'
          })
          console.log(response)
        })
        // window.open(href, "_blank");
        return 0
      } else if (platform == 'com.whatsapp') {
        let href = 'https://api.whatsapp.com/send?text=' + encodeURIComponent(url)
        // let href = 'intent://send?text=' + encodeURIComponent(url) + '#Intent;scheme=whatsapp;package=com.whatsapp;end';
        // let href = 'whatsapp://send?text=' + encodeURIComponent(url);
        window.open(href, '_blank')
        message.info({
          content: 'Sharing successful'
        })
        return 0
      } else {
        if (window.navigator.share) {
          localStorage.setItem('share_ts', new Date().getTime())
          navigator.share({
            title: shareTitle,
            text: url,
            url: url,
          })
            .then(() => {
              console.log('Share completed successfuly')
              return 0
            })
            .catch((error) => {
              console.log(`share failed: ${error}`)
              return 1
            })
          return 0
        } else {
          console.log('no browser share')
          return 1
        }
      }
    }

    if (platform == 'default') {
      platform = ''
    }
    let ret = window.share.shareWeb(platform, url, img, shareTitle, JSON.stringify(shareApks))

    return ret
  }

  static notifyPreload (images) {
    let param = JSON.stringify(images)
    console.log('preload::' + param)
    let isNotAndroid11OrAbove = Context.isNotAndroid11OrAbove()
    if (window.share && isNotAndroid11OrAbove) {
      window.share.preLoad(param)
    }
  }

  static exitWebview () {
    console.log('exit from webview')
    if (window.share) {
      // window.share.exit()
    } else {
      this.$router.push('/home')
    }
  }

  static getAid () {
    if (window.share && window.share.getAppInfo) {
      let appInfo = JSON.parse(window.share.getAppInfo())
      let aid = appInfo.aid
      let packageName = appInfo.packageName
      console.log(packageName)
      localStorage.setItem('packageName', packageName)
      let channelid = appInfo.gc
      localStorage.setItem('channelId', channelid)
      return aid
    } else {
      localStorage.setItem('packageName', 'com.teenpatti.gold.awvun')
      localStorage.setItem('channelId', 'aw00001')
    }
    let cachedAid = localStorage.getItem('cg_aid')
    if (cachedAid) {
      return cachedAid
    }
    return ''
  }

  static isAiwan () {
    if (window.share && window.share.getAppInfo) {
      let appInfo = JSON.parse(window.share.getAppInfo())
      let packageName = appInfo.packageName
      console.log(packageName)
      localStorage.setItem('packageName', packageName)
      return 'com.teenpatti.gold.awvun' == packageName
    } else {
      return false
    }
  }

  static getLabel () {
    if (window.share && window.share.getAppInfo) {
      let label = localStorage.getItem('label')
      if (!label) {
        label = 'B'
        localStorage.setItem('label', label)
      }
      return label
    } else {
      localStorage.setItem('label', 'B')
      return 'B'
    }
  }
}

export default Context
