<script>
import toast from '@/utils/toast'
import request from '@/utils/request'
import bus from 'vue3-eventbus'

export default {
  methods: {
    shareWhatsapp () {
      this.$store.commit('shareTip', false)
      const whatsApp = this.getShareDataByPkg('com.whatsapp')
      if (!whatsApp) {
        return
      }
      this.reportShare('WA')

      setTimeout(() => {
        bus.emit('refresh', true)
      }, 2000)
      if (window.jskit) {
        window.jskit.share('com.whatsapp', whatsApp.link)
        return
      }
      if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.share) {
        window.webkit.messageHandlers.share.postMessage({
          platform: 'com.whatsapp',
          link: whatsApp.link
        })
        return
      }
      let _href = 'https://api.whatsapp.com/send?'
      const text = whatsApp.link
      const link = whatsApp.img
      _href += '&text=' + encodeURIComponent(text)
      _href += '&img=' + encodeURIComponent(link)
      window.open(_href)
    },
    sharaFB () {
      this.$store.commit('shareTip', false)
      const fb = this.getShareDataByPkg('com.facebook.katana')
      if (!fb) {
        return
      }
      this.reportShare('FB')
      if (window.jskit) {
        window.jskit.share('com.facebook.katana', fb.link)
        return
      }
      if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.share) {
        window.webkit.messageHandlers.share.postMessage({
          platform: 'com.facebook.katana',
          link: fb.link
        })
        return
      }
      console.log(fb.link)
      const _href = 'https://www.facebook.com/sharer/sharer.php?u=' + fb.link
      window.open(_href)
    },
    shareyoutube () {
      this.$store.commit('shareTip', false)
      const youtube = this.getShareDataByPkg('com.google.android.youtube')
      if (!youtube) {
        return
      }
      this.reportShare('Youtube')
      if (window.jskit) {
        window.jskit.share('com.google.android.youtube', youtube.link)
        return
      }
      if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.share) {
        window.webkit.messageHandlers.share.postMessage({
          platform: 'com.google.android.youtube',
          link: youtube.link
        })
        return
      }
      const _href = 'vnd.youtube://youtube.com'
      window.open(_href)
      console.log('shareyoutube')
    },
    shareTelegram () {
      this.$store.commit('shareTip', false)
      const shareData = this.getShareDataByPkg('org.telegram.messenger')
      if (!shareData) {
        return
      }
      this.reportShare('Tel')
      if (window.jskit) {
        window.jskit.share('org.telegram.messenger', shareData.link)
        return
      }
      if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.share) {
        window.webkit.messageHandlers.share.postMessage({
          platform: 'org.telegram.messenger',
          link: shareData.link
        })
        return
      }
      let _href = 'https://t.me/share/url?'
      const text = shareData.link
      const link = shareData.img
      _href += '&text=' + encodeURIComponent(text)
      _href += '&url=' + encodeURIComponent(link)
      window.open(_href)
    },
    shareSystem () {
      this.$store.commit('shareTip', false)
      const shareData = this.getShareDataByPkg('default')
      if (!shareData) {
        return
      }
      this.reportShare('other')
      if (window.jskit) {
        window.jskit.share('', shareData.link)
        return
      }
      if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.share) {
        window.webkit.messageHandlers.share.postMessage({
          platform: '',
          link: shareData.link
        })
        return
      }
      if (navigator.canShare) {
        navigator.share({
          title: 'Share',
          text: shareData.link
        })
      } else {
        toast.show('Your browser does not support sharing')
      }
    },
    getShareDataByPkg (pkg) {
      const shareData = this.$store.state.shareData
      for (const index in shareData) {
        const item = shareData[index]
        if (item.platform === pkg) {
          return item
        }
      }
    },
    copyTxt () {
      const value = this.$store.state.linkUrl
      if (navigator.clipboard !== undefined && navigator.clipboard && window.isSecureContext) {
        toast.show('copy success')
        navigator.clipboard.writeText(value)
      } else {
        this.copy2(value)
      }
    },
    reportShare (platform) {
      request.post(`/landscape/task/share?code=${this.$store.state.code}&pn=${this.$store.state.pn}&platform=${platform}`, {})
    },

    copy2 (value) {
      const textArea = document.createElement('textarea')
      textArea.value = value
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()
      /* eslint-disable no-new */
      new Promise(() => {
        toast.show('copy success')
        document.execCommand('copy')
        textArea.remove()
      })
    }
  }
}
</script>

<template>
  <div id="share">
    <div id="share_top">
      <img src="../assets/share_gift.png">
      <div>
        <span>My Link:</span>
        <span>{{ $store.state.linkUrl }}</span>
        <div id="btn_copy" @click="copyTxt"><p>copy</p></div>
      </div>
    </div>
    <div id="share_items">
      <img class="iv_whatsapp" src="@/assets/share_whatsapp.png" @click="shareWhatsapp">
      <img class="iv_fb" src="@/assets/share_fb.png" @click="sharaFB">
      <img class="iv_youtube" src="@/assets/share_telegram.png" @click="shareTelegram">
      <img class="iv_system" src="@/assets/share_more.png" @click="shareSystem">
      <div class="tips" v-show="$store.state.finish_task_count == 0">
        <div>₹ 5</div>
      </div>
    </div>
    <div class="share-outline"></div>
  </div>
</template>

<style scoped>

#share {
  width: 100%;
  height: 22.4rem;
  background-image: url("../assets/share_bg.png");
  background-position: center;
  margin-top: 1rem;
  padding: 0 1.6rem;
  justify-items: center;
  display: flex;
  position: relative;
  flex-direction: column;
  background-size: 100% 100%;
}

#share_top {
  height: 9.4rem;
  display: flex;
  align-items: center;
}

#share_top > img {
  height: 100%;
  width: 38.8rem;
}

#share_top > div {
  background: rgba(38, 37, 65, 0.2);
  height: 6rem;
  width: 100%;
  border-radius: 0.6rem;
  display: flex;
  align-items: center;
  padding: 0 1rem 0 2.4rem;
}

#share_top > div :first-child {
  color: rgba(255, 255, 255, 0.5);
  font-size: 2.8rem;
}

#share_top > div :nth-child(2) {
  color: rgb(255, 255, 255);
  padding-left: 1rem;
  font-size: 2.4rem;
  flex: 1;
}

#btn_copy {
  display: flex;
  align-content: center;
  justify-content: center;
  height: 4.6rem;
  width: 12rem;
  background: rgba(255, 103, 240, 0.2);
  border-radius: 0.6rem;
}

#btn_copy:active {
  opacity: 0.6;
}

#btn_copy > p {
  align-self: center;
  color: rgb(255, 255, 255);
  font-size: 2.8rem;
}

#share_items {
  width: 100%;
  height: 12rem;
  align-items: center;
  justify-content: space-around;
  display: flex;

}

.iv_whatsapp {
  width: 34rem;
  height: 10rem;
}

.iv_whatsapp:active {
  opacity: 0.6;
}

.iv_fb {
  width: 27rem;
  height: 10rem;
}

.iv_fb:active {
  opacity: 0.6;
}

.iv_youtube {
  width: 27rem;
  height: 10rem;
}

.tips {
  position: absolute;
  height: 6.5rem;
  width: 12rem;
  left: 23rem;
  bottom: 8rem;
  background: url("@/assets/share_red_tip.png");
  background-size: 100% 100%;
}

.tips div {
  position: absolute;
  font-size: 2.4rem;
  left: 4.6rem;
  color: #FBEB6E;
  font-weight: bolder;
  top: 1.1rem;
  text-shadow: 0.2rem 0.2rem 0 #77271C, 0.2rem 0.2rem 0 #77271C, 0.2rem 0.2rem 0 #77271C, 0.2rem 0.2rem 0 #77271C;

}

.iv_youtube:active {
  opacity: 0.6;
}

.iv_system {
  width: 9.7rem;
  height: 10rem;
}

.iv_system:active {
  opacity: 0.6;
}

.share-outline {
  width: 105%;
  height: 100%;
  background-image: url("@/assets/main_share_outline.png");
  background-size: 100% 100%;
  position: absolute;
  pointer-events: none;
  bottom: 0;
  left: -5%;
}
</style>
