function show (msg, duration) {
  duration = isNaN(duration) ? 3000 : duration
  const m = document.createElement('div')
  const p = document.createElement('p')
  p.innerHTML = msg
  p.style.cssText = 'color: white; font-size: 3rem;padding:0.2rem 1rem; background: rgba(0, 0, 0, 0.6);border-radius:0.1rem;  align-self: center;'
  m.style.cssText =
    'width:100%;  font-size: 0.2rem; display:flex; justify-content: center;align-items: center;  position:fixed; bottom:10%; z-index:999999;'
  m.appendChild(p)
  document.getElementById('app-main').appendChild(m)
  setTimeout(function () {
    const d = 0.5
    m.style.webkitTransition =
      '-webkit-transform ' + d + 's ease-in, opacity ' + d + 's ease-in'
    m.style.opacity = '0'
    setTimeout(function () {
      document.getElementById('app-main').removeChild(m)
    }, d * 1000)
  }, duration)
}

export default { show }
