<script>

import Share from '@/views/Share.vue'

export default {
  components: {
    Share
  },
  methods: {
    dismiss () {
      if (this.$route.path === '/main/earn_cash/0') {
        this.$store.commit('shareTip', false)
      } else {
        this.$router.push('/main/earn_cash/0')
      }
    }
  }
}
</script>

<template>
  <div class="tips-contain">
    <div class="contain" @click="dismiss">
      <div class="txt">
        <p>Click the button to invite your friends install</p>
      </div>
      <div class="share-tip-line"></div>
      <Share class="share"></Share>
    </div>
  </div>
</template>

<style scoped>
.tips-contain {
  display: flex;
  position: relative;
  align-items: end;
  margin-bottom: 6rem;
  margin-left: 16rem;
}

.contain {
  width: 108rem;

  display: flex;
  align-items: center;
  flex-direction: column;
}

.txt {
  width: 75.7rem;
  height: 11.8rem;
  background-image: url("@/assets/share_tip_txt.png");
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -2.6rem;

}

.txt > p {
  width: 71.0rem;
  height: 7rem;
  background: linear-gradient(90deg, #A540B7, #72329C);
  color: white;
  border-radius: 0.4rem;
  text-align: center;
  line-height: 7rem;
  font-size: 3.2rem;
  font-weight: 400;
}

.share-tip-line {
  width: 3rem;
  height: 12rem;
  background-image: url("@/assets/share_tip_line.png");
  background-size: 100% 100%;
}

.share {

}
</style>
